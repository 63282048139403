import InputCalcSpeed from 'component/componenetInput/InputCalcSpeed'
import LongButtonModel from 'component/componenetInput/LongButtonModel';
import { ModalCarContext } from 'component/searchCars/Context/ModalCarContext';
import { useContext, useState } from 'react'
import { Col } from 'react-bootstrap'
import { validateEmail } from 'utils/validateUtil';
import axios from 'axios';
import { baseURL } from 'config';
import { UserDataContext } from 'App';
import { apiUploadProfile } from 'component/searchCars/request/apiUploadProfile';

const YourAccount = () => {
    const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
    const [userData, setUserData] = useContext(UserDataContext);
    const [visibleInput, setVisibleInput] = useState({
        name: modalCar?.user_name ? false : true,
        email: modalCar?.user_email ? false : true,
        phone: modalCar?.user_phone ? false : true,
    });
    const [form, setForm] = useState({
        name: modalCar?.user_name || "",
        email: modalCar?.user_email || "",
        phone: modalCar?.user_phone || "",
        offer: modalCar?.user_offer || modalCar?.calc?.price || modalCar?.price || ""
    });
    const [invalidForm, setInvalidForm] = useState({
        name: "",
        email: "",
        phone: "",
        offer: ""
    });


    function handleFormChange(e) {
        setInvalidForm({
            ...invalidForm,
            [e.target.name]: ""
        });
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }

    function handleSubmitRequest() {
        if (form.offer === "" || form.name === "" || !validateEmail(form.email)) {
            let newInvalid = {}
            form.visit_date === "" && (newInvalid["visit_date"] = "Visit date is required!");
            form.name === "" && (newInvalid["name"] = "Name is required!");
            !validateEmail(form.email) && (newInvalid["email"] = "Invalid email format!");
            form.offer === "" && (newInvalid["offer"] = "Offer is required!");
            setInvalidForm({
                ...invalidForm,
                ...newInvalid
            });
            return;
        }
        if(isNaN(parseInt(form.offer))){
            setInvalidForm({
                ...invalidForm,
                offer: "Offer must be a number!"
            });
            return;
        }

        const partsNames = form.name.split(" ");
        const first_name = partsNames[0];
        const last_name = partsNames.slice(1).join(" ");


        apiUploadProfile({
            first_name,
            last_name,
            email: form.email,
            phone: form.phone,
            token: userData?.token,
            zip_code: modalCar?.user_zip_code
        }, (error, data) => {
            console.log("apiUploadProfile", error, data);
            if (error || data?.success === 0) {
                return;
            }

            setUserData((prev) => ({
                ...prev,
                first_name,
                last_name,
                email: form.email,
                phone: form.phone,
                zip_code: modalCar?.user_zip_code
                , lastSetterBy: "yourAccount"
            }));
        });

        apiRequestMakeOfferSoftLead({
            code: modalCar.code,
            name: form.name,
            email: form.email,
            phone: form.phone,
            offer: form.offer,
            token: userData?.token
        }, (error, data) => {
            if (error || data?.success === 0) {
                dispatchModalCar({
                    type: "update",
                    payload: {
                        msg: {
                            type: "error",
                            message: data?.error ?? "Something went wrong! Please try again later.",
                        }
                    }
                });
                return;
            }
            dispatchModalCar({
                type: "update",
                payload: {
                    user_name: form.name,
                    user_email: form.email,
                    user_phone: form.phone,
                    user_offer: form.offer,
                    msg: {
                        type: null,
                        message: "",
                    },
                    currentSubModal: "calculator",
                },
            });
            dispatchModalCar({
                type: "calc-update",
                payload: {
                    price: form?.offer
                }
            });

        });
    }


    function handleBackButton() {
        dispatchModalCar({
            type: 'set', payload: {
                currentModal: 'primaryModal'
            }
        });
    }

    return (
        <Col lg={6} xl={4} className="col-12 bordered-speed mt-4">
            <div className="header-speed text-center">Make an offer</div>
            {visibleInput.name && <InputCalcSpeed value={form.name} invalid={invalidForm.name} onChange={handleFormChange} name="name" label="Name" placeholder="Enter Name here..." />}
            {visibleInput.email && <InputCalcSpeed value={form.email} invalid={invalidForm.email} onChange={handleFormChange} name="email" label="Email Address" placeholder="Enter Email here..." />}
            {visibleInput.phone && <InputCalcSpeed value={form.phone} invalid={invalidForm.phone} format="phoneNumber" onChange={handleFormChange} name="phone" label="Phone" icon={<small className="text-muted">Optional</small>} placeholder="Enter Phone here..." />}
            <InputCalcSpeed value={form.offer} invalid={invalidForm.offer} format="Number" onChange={handleFormChange} name="offer" label="Offer Amount" placeholder="Enter Offer Amount here..." />

            <LongButtonModel disabled={Object.values(invalidForm).some((i) => i !== "")} onClick={handleSubmitRequest} className="mt-3" colors={["#fff", "#B62949"]}>Continue</LongButtonModel>
            <div className="d-flex justify-content-center"><div onClick={handleBackButton} className="back-btn-tri">Go Back</div></div>
        </Col>
    )
}

export default YourAccount;


export function apiRequestMakeOfferSoftLead({ code, name, email, phone, offer, token }, callback) {
    //   /v1/seller/inventory/:code/make-offer
    let params = new URLSearchParams();
    params.append("name", name);
    params.append("email", email);
    phone && params.append("phone", phone);
    params.append("offer", offer);

    axios({
        method: "POST",
        url: `${baseURL}/seller/inventory/${code}/make-offer`,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": token,
        },
        data: params
    }).then((response) => {
        callback(null, response.data);
    }).catch((error) => {
        callback(error, null);
    })
}
