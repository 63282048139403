import { Row, Col } from 'react-bootstrap'
import FormDetails from './componentDetails/FormDetails'
import ImageUploader from './componentDetails/ImageUploader'
import { useEffect } from 'react'
const IndexDetails = () => {
  return (
    <>
      <Row>
        <Col xxl="8">
          <ImageUploader minBoxLimit={8} type="details" />
        </Col>
        <Col xxl="4" style={{background:"#FFFFFF",borderRadius:"10px",display:"flex",flexDirection:"column",paddingBottom:"5px"}}>
          <FormDetails />
        </Col>
      </Row>
    </>
  )
}

export default IndexDetails