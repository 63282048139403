import { memo } from 'react';
import { HiLocationMarker } from '@react-icons/all-files/hi/HiLocationMarker';

let secondaryColor = '#90A3BF';
const CardCar = (props) => {
    let carDetails = { ...props?.car };
    let onClick= props?.onClick ?? (()=>{});
    return (
        <div onClick={onClick} className="card-search-oneCard">
            <div className="d-flex justify-content-between" style={{ color: "#B62949" }}>
                <div className="m-2"><HiLocationMarker size={25} />
                    <span style={{ color: "#90A3BF", marginLeft: "2px" }}>{carDetails.location}</span>
                </div>
            </div>
            <div className="card-search-img-container">
                <img src={carDetails.photo ? carDetails.photo : "/images/car_no_image.jpg"} alt="" />
            </div>


            <div className="card-search-title">{carDetails.title}</div>
            <div style={{ color: secondaryColor }} className="fw-bold">{carDetails.dealer_name}</div>


            <span style={{ textAlign: "right", color: "#90A3BF", fontSize: "14px" }} className=' w-50'>{carDetails.mileage.toLocaleString()} miles</span>
            <div className="d-flex justify-content-between align-items-center mt-1">
                <span className='price-card-search w-50'>{carDetails?.price === 0 ? "Contact Us" : "$" + carDetails?.price?.toLocaleString()}</span>
            </div>

        </div>
    );
};

export default memo(CardCar);

