import React from 'react'

const UploadIcon = (props) => {
    return (
        <svg  width="45" height="45" {...props}  viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.1053 65.8693C0.25144 68.853 3.2352 36.0317 27.1053 39.0154C18.154 6.19409 68.8779 6.19409 65.8941 30.0642C95.7317 21.1129 95.7317 68.853 68.8779 65.8693M33.0728 53.9342L47.9916 41.9992L62.9104 53.9342M47.9916 41.9992V86.7556" stroke="#B62A4A" strokeWidth="5.96752" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default UploadIcon