// @ts-nocheck
/* eslint-disable */
import { faAngleLeft, faArrowLeft, faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import ImageUploader from 'component/configDetails/componentDetails/ImageUploader';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, Row, Col, ButtonGroup } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { formatPhoneNumber, parseJSONWithDefault } from 'utils/generalUtil';
import { validateEmail, validateUSAPhoneNumber } from 'utils/validateUtil';
import { UserDataContext } from '../../../App';
import { baseURL, baseURLv2 } from '../../../config';
import RecentlySold from 'component/homeStar/section/RecentSold';
import ButtonBoder from '../../componenetInput/ButtonBoder';
import InputBotBoder from '../../componenetInput/InputBotBoder';
import RadioBoder from '../../componenetInput/RadioBoder';
import Loading from '../../Loading';
import { FcInfo } from '@react-icons/all-files/fc/FcInfo';
import InputCalcSpeed from 'component/componenetInput/InputCalcSpeed';
import MaskedInput from 'react-text-mask';



export default function VinRegister() {
    const setUserData = useContext(UserDataContext)[1];
    //const phoneRef = useRef(null);
    // [0,1,2,3,4];
    // 0-vin,1-current mileage,2-car condition, 3-zip-code
    const [step, setStep] = useState(parseJSONWithDefault(window.localStorage['step-vin-v3'], { step: 0, isLoading: false }));

    const navigator = useNavigate();
    const [form, setForm] = useState({
        vin: window.localStorage.getItem('vin') || '',
        mileage: window.localStorage.getItem('mileage') || '',
        condition_id: window.localStorage.getItem('condition_id') || '',
        zip_code: window.localStorage.getItem('zip_code') || '',
        //phone: window.localStorage.getItem('phone') || '',
        email: window.localStorage.getItem('email') || '',
        password: window.localStorage.getItem('password') || '',
        asking_price: window.localStorage.getItem('asking_price') || '',
        phone: window.localStorage.getItem('phone') || '',
    });
    // const [extraForm, setExtraForm] = useState(parseJSONWithDefault(window.localStorage['extraForm-vin'], {}));
    const extraFormRef = useRef(parseJSONWithDefault(window.localStorage['extraForm-vin'], {}));


    const [hasError, setHasError] = useState("");
    const [formError, setFormError] = useState({});

    useEffect(() => {
        window.localStorage.setItem('vin', form.vin);
        window.localStorage.setItem('mileage', form.mileage);
        window.localStorage.setItem('condition_id', form.condition_id);
        window.localStorage.setItem('zip_code', form.zip_code);
        //window.localStorage.setItem('phone', form.phone);
        window.localStorage.setItem('email', form.email);
        //window.localStorage.setItem('password', form.password); 
        window.localStorage.setItem('asking_price', form.asking_price);
        window.localStorage.setItem('phone', form.phone);
    }, [form]);

    // useEffect(() => {
    //     window.localStorage.setItem('extraForm-vin', JSON.stringify(extraForm));
    // }, [extraForm]);



    useEffect(() => {
        // if (phoneRef.current) {
        //     formatPhoneNumber(phoneRef.current);
        // }
        window.localStorage.setItem('step-vin-v3', JSON.stringify(step));
    }, [step.step]);

    function resetFormError() {
        setFormError({});
    }
    function setError(key, value) {
        setFormError((prev) => {
            return { ...prev, [key]: value };
        });
    }
    function hasFormError() {
        return Object.keys(formError).length > 0;
    }
    async function checkZipCodeValid(zip_code) {
        // https://api.autostoday.com/v1/tax
        try{
            const response = await axios.get(`${baseURL}/tax/${zip_code}`);
            return !!response.data.success;
        }catch(error){
            return false;
        }
    }

    async function submitNextEvent() {
        setHasError("");
        resetFormError();
        switch (step.step) {
            case 0:

                // milage
                let mileage = parseInt(form.mileage);
                if (isNaN(mileage)) {
                    //setHasError("Mileage should be number format!");
                    setError("mileage", "Mileage should be number format!");
                    return;
                }
                if (isNaN(parseInt(form.asking_price || 0))) {
                    setError("asking_price", "Asking price should be number format!");
                    return;
                }
                let zip_code = form.zip_code;
                if (zip_code === "") {
                    setError("zip_code", "Zip code is required to be filled in!");
                    return;
                }
                if(await checkZipCodeValid(zip_code) === false){
                    setError("zip_code", "Zip code is not valid!");
                    return;
                }
                let errorVin = vinSubmit(()=>{
                    finalSubmit();
                });
                if (errorVin === "EMPTY_VIN") {
                    // setHasError("Vin number is required to be filled in!");
                    setError("vin", "Vin number is required to be filled in!");
                    return;
                }
                break;
            case 1:
                setUserData((p) => {
                    window.localStorage.clear();
                    return { ...p, isOnline: true };
                });
                navigator("/");
                break;
            default:
                return "default"
        }
    }

    function finalSubmit() {
        setStep((state) => {
            return { ...state, isLoading: true }
        });

        let params = new URLSearchParams();
        let fusiedForm = { ...form,...extraFormRef.current};
        let allowedKeys = ["plate", "state", "condition_id", "mileage", "zip_code", "asking_price", "phone", "vin_id", "vin", "plate_state", "year", "make", "model", "trim"];
        console.log('fusiedForm', fusiedForm);
        for (var [key, value] of Object.entries(fusiedForm)) {
            if (allowedKeys.includes(key.toLowerCase())) {
                params.append(key, value);
            }
        }

        axios({
            method: "POST",
            url: baseURLv2 + "/seller/register-plate",
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === 1) {
                setUserData({
                    ...response.data,
                    // isOnline: true
                });
                setStep((prevState) => {
                    return { ...prevState, step: prevState.step + 1 }
                })
                //navigator("/");
            } else {
                setHasError(response.data.error);
            }
        }).catch((error) => {
            setHasError("Something went wrong or check network connection!");
            console.log("response from above", error);
        }).finally(() => {
            setStep((state) => {
                return { ...state, isLoading: false }
            });
        });
    }


    function vinSubmit(callbackSuccess) {
        if (form.vin === "") return "EMPTY_VIN";

        setStep((state) => {
            return { ...state, isLoading: true }
        });

        let parmas = new URLSearchParams();
        parmas.append("vin", form.vin);
        axios({
            method: "POST",
            url: baseURL + "/seller/register/vin",
            data: parmas,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            // console.log('response.data', response.data);
            // setExtraForm((state) => {
            //     return { ...state, ...response.data }
            // });
            extraFormRef.current = { ...response.data };
            // console.log('extraFormRef.current', extraFormRef.current);
            if (response.data.success === 1) {
                callbackSuccess?.();
            } else {
                //setHasError(response.data.message);
                setError('vin', response.data.error);
                setStep((state) => {
                    return { ...state, isLoading: false }
                });
            }
        }).catch((error) => {
            setHasError("Something went wrong!");
        })

    }
    function oneStepBack() {
        setStep((state) => {
            let result = state.step - 1;
            if (result < 0) {
                navigator("/");
                return { ...state, step: 0 };
            }
            return { ...state, step: result }
        });
    }
    return (
        <div className='container container-d-flex'>
            <div className='d-flex justify-content-center'>
                <div className={step.step !== 1 ? 'col-12 col-md-4' : 'col-12 col-md-9'}>

                    <div className='text-center box-register-logo' >
                        <img alt="autos today logo no transparent" className="my-2" src="/images/speed/autos-today-logo.png" />
                    </div>
                    <div className='box-register'>
                        {step.step < 1 &&
                            <>
                            <div className='navigator-container'>
                                <span onClick={oneStepBack} className='box-go-back'>
                                    <FontAwesomeIcon icon={faArrowLeftLong} />
                                </span>
                            </div>
                            <div className="textOrRegister small-text my-3">
                                Fill out this simple form and receive <b>offers in minutes</b>. We are <b>100% free</b> and anonymous platform and there is <b>no obligation</b> to sell your car.
                            </div>

                            <div className="text-center">
                                <ButtonGroup >
                                    <NavLink to="/register/plate" className={'button-choose-register btn btn-outline-danger' + (window.location.pathname === "/register/plate" ? " active" : "")}>PLATE</NavLink>
                                    <NavLink to="/register/vin" className={'button-choose-register btn btn-outline-danger' + ((window.location.pathname === "/register/vin" || window.location.pathname === "/register") ? " active" : "")}>VIN</NavLink>
                                    <NavLink to="/register/manual" className={'button-choose-register btn btn-outline-danger' + (window.location.pathname === "/register/manual" ? " active" : "")}>MANUAL</NavLink>
                                </ButtonGroup>
                            </div></>
                        }

                        {hasError !== "" && <div className="warning-error">{hasError}</div>}
                        {/* Step null for loading */}
                        {((typeof step.step === 'undefined' || step.isLoading === true) && <Loading />)}

                        {step.step === 0 && !step.isLoading && <>
                            <div className="my-3">
                                <InputCalcSpeed invalid={formError.vin} value={form.vin} onChange={(e) => setForm(i => { return { ...i, vin: e.target.value } })} label="VIN*" placeholder="Vin number" />
                                <div className="my-3">
                                    <InputCalcSpeed invalid={formError.mileage} type="number" value={form.mileage} onChange={(e) => setForm(i => { return { ...i, mileage: e.target.value } })} label="Mileage*" placeholder="Current mileage" />
                                </div>
                                <div className='my-3'>
                                    <InputCalcSpeed type="number" invalid={formError.zip_code} value={form.zip_code} onChange={(e) => { setForm(i => { return { ...i, zip_code: e.target.value } }) }} label="Zip Code*" placeholder="Zip code" />
                                </div>
                                <div className="my-3">
                                    <InputCalcSpeed invalid={formError.asking_price} value={form.asking_price} type="number" onChange={(e) => { setForm((i) => { return { ...i, asking_price: e.target.value } }) }} placeholder="What do you think your car is worth? (optional)" label="Asking Price" />
                                </div>
                            </div>
                            <ButtonBoder onClick={submitNextEvent} label="GET MY OFFER" />
                        </>}
                        {/* Step 0 */}
                        

                        {step.step === 1 && !step.isLoading && <>
                            <div className="my-3">
                                <ImageUploader type="register" />
                            </div>
                            <Row className='fixed-position-bottom'>
                                <Col>
                                    <ButtonBoder onClick={submitNextEvent} label="FINISH" />
                                </Col>
                                <Col>
                                    <ButtonBoder onClick={submitNextEvent} label="SKIP" />
                                </Col>
                            </Row>
                        </>}
                        {step.step === 1 && <div className='accept-terms-text'>
                            By signing up with our platform you agree to our <a target="_blank" href='https://autostoday.com/terms-of-use/'>Terms of Use</a>
                        </div>}
                    </div>


                </div>
            </div>
            <div className="flex-grow-1"></div>
            <div>
                <RecentlySold />
            </div>
        </div>
    )
}
