// @ts-nocheck
import axios from 'axios';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import React from 'react';
import { UserDataContext } from '../App';
import { baseURL } from '../config';
import Loading from './Loading';
import ButtonBoder from './componenetInput/ButtonBoder';




const LookingCar = React.forwardRef(({getProgress},ref)=>{
    const [show, setShow] = useState(false);
    const [isLoading,setIsLoading] = useState(true);
    const userData = useContext(UserDataContext)[0];
    const lookingSwitch = useRef(null);
    const descriptionLooking = useRef(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function submitLooking(){
        let params = new URLSearchParams();
        params.append("looking",lookingSwitch.current.checked ? 1 : 0);
        params.append("description",descriptionLooking.current.value);
        axios({
            method:"POST",
            url: baseURL+"/seller/looking",
            data:params,
            headers:{
                'Authorization': userData.token,
                'Content-Type':'application/x-www-form-urlencoded'
            }
        }).finally(()=>{
           getProgress();
        });
    }

    useEffect(()=>{
     if(!show){
        setIsLoading(true);
        return;
     }
       
      axios({
        method:"GET",
        url: baseURL+"/seller/looking",
        headers:{
            'Authorization': userData.token
        }
      }).then((response)=>{
        if(lookingSwitch)
         lookingSwitch.current.checked = !!response.data.looking_for_car
        if(descriptionLooking)
        descriptionLooking.current.value = response.data.car_description || "";
      }).catch((error)=>{
        console.log("errror:",error);
      }).finally(()=>{
        setIsLoading(false);
      })
    },[show]);

    return (
        <>
            <Button ref={ref} hidden variant="danger" onClick={handleShow}>
             Setup
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>What are you looking for?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading && <Loading>Feching Data</Loading>}
                    <Form hidden={isLoading}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Check ref={lookingSwitch}
                                type="switch"
                                id="custom-switch"
                                label="Are you looking for car?"
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Description</Form.Label>
                            <Form.Control ref={descriptionLooking} as="textarea" rows={3} />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <ButtonBoder onClick={()=>{
                        submitLooking();
                        handleClose();
                    }} label="Continue" />
                </Modal.Footer>
            </Modal>
        </>
    );
})


export default LookingCar;