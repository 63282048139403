

if(window.location.href.includes("autostoday.com")){
    // base api server, usage in api
    var baseURL = "https://api.autostoday.com/v1";
    // base api server version, usage when not all api are updated to newest version
    var baseURLv2 = "https://api.autostoday.com/v2"; // in case of v2 api uses same as baseURL v1 just update this to baseURLv2 = baseURL
    // socket io connection, usage in chat message
    var socketURL = 'https://api.autostoday.com/'; // socket io connection /// 'https://dev-api.autostoday.com/'
} else {
    // base api server, usage in api
    var baseURL = "https://dev-api.autostoday.com/v1";
    // base api server version, usage when not all api are updated to newest version
    var baseURLv2 = "https://dev-api.autostoday.com/v2"; // in case of v2 api uses same as baseURL v1 just update this to baseURLv2 = baseURL
    // socket io connection, usage in chat message
    var socketURL = 'https://dev-api.autostoday.com/'; // socket io connection /// 'https://dev-api.autostoday.com/'
}



export {
    baseURL,
    baseURLv2,
    socketURL,
}
/*
socket io connection, usage in chat message
https://dev-api.autostoday.com/ 
http://localhost:3333/
*/

// lower quality images
export const imageUrlThumb = 'https://thumbs.autostoday.com/';
// image load server, usage in car phots
export const imageURL = 'https://photos.caripa.com/';
// image uploaded server, usage in chat images upload
export const imageStorageUrl = 'https://caripa-messages.s3-us-west-2.amazonaws.com/';
// is project in production, usage for logs/debug
export const projectMode = "dev".toUpperCase(); // Developer or Production DEV or anystring for production