import React, { useEffect, useRef } from 'react'
import SideList from '../content/SideList'

const SideBarList = ({ initDefaultRef, setSelected, setNaviagtor }) => {
    const refSearchInput = useRef(null)

    useEffect(() => {
        refSearchInput.current.addEventListener("keyup", searchFromList);
        return () => {
            if (refSearchInput.current) {
                refSearchInput.current.removeEventListener("keyup", searchFromList);
            }
        }
    }, []);


    function searchFromList() {
        // query all row-container-op1 and hide them if not match with value of refSearchInput.current.value 
        const list = [...document.querySelectorAll(".row-container-op1")];
        list.forEach((parent) => {
            let item = parent.querySelector(".title");
            if (item.innerText.toLowerCase().indexOf(refSearchInput.current.value.toLowerCase()) === -1) {
                parent.style.display = "none";
            } else {
                parent.style.display = "";
            }
        });
    }


    return (
        <>
            <div className='list-chat-search-speed'>
                <div className="title">Deals</div>
                <div><input ref={refSearchInput} className="search-chat" placeholder="Search" type="text" /></div>
            </div>
            <div style={{ overflow: "auto", height: "500px", flexGrow: 1 }}>
                <SideList initDefaultRef={initDefaultRef} setSelected={setSelected} setNaviagtor={setNaviagtor} />
            </div>
        </>
    )
}

export default SideBarList