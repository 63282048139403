import { useEffect, useContext, useState, useRef, forwardRef } from 'react';
import ProgressCircle from '../parts/ProgressCircle'
import { fetchProgressVehicle } from 'component/homeWorld/request/fetchProgressVehicle';
import { UserDataContext } from 'App';
import { apiSaveVin } from 'component/homeWorld/MyVehicle/request/apiSaveVin';
import ModalPortal from 'component/componenetInput/ModalPortal';
import { Col, Modal } from 'react-bootstrap';
import ButtonBoder from 'component/componenetInput/ButtonBoder';
import ButtonOutlineBoder from 'component/componenetInput/ButtonOutlineBoder';
import InputBotBoder from 'component/componenetInput/InputBotBoder';
import { useNavigate } from 'react-router-dom';
import { apiSetLookingForCar } from '../request/apiSetLookingForCar';
import { useImperativeHandle } from 'react';
import Loading from 'component/Loading';
/*
{
    "title": "Initial Listing",
    "description": "Successfully listed a car.",
    "route": "/details",
    "completed": 1
}
*/

const ProgressContainerCircles = forwardRef(({initHomeStar},ref) => {
    const [userData, setUserData] = useContext(UserDataContext);
    const [progressList, setProgressList] = useState([]);

    const [showModal, setShowModal] = useState({
        show: false,
        contentType: null,
        errorMessage: "",
        loading: false
    });

    const handleClose = () => setShowModal({ show: false, contentType: null, errorMessage: "",loading:false });

    const vinRef = useRef(null);

    const lookingCheckBoxRef = useRef(null);
    const descriptionLookingRef = useRef(null);


    const navigate = useNavigate();

    useEffect(() => {
        initFetchProgress();
    }, []);
    function initFetchProgress() {
        fetchProgressVehicle(userData.token, (error, data) => {
            if (error) { throw error; }
            //console.log("fetchProgress:",data);
            let initialListing = data.details.find(x => x.title === 'Initial Listing');
            if (initialListing) {
                data.details.splice(data.details.indexOf(initialListing), 1);
            }

            setProgressList(data.details);
        });
    }

    function submitNewVin() {
        setShowModal({...showModal,loading:true});
        apiSaveVin(userData.token, vinRef.current.value, (error, data) => {
            if (error) throw error;
            if (data?.success === 0) {
                setShowModal({ show: true, contentType: "vin", errorMessage: data.error,loading:false });
            } else {
                handleClose();
                initHomeStar();
                initFetchProgress();
            }
        });
    }
    function submitLookingForCar() {
        setShowModal({...showModal,loading:true});
        apiSetLookingForCar(userData.token, lookingCheckBoxRef.current.checked, descriptionLookingRef.current.value, (error, data) => {
            if (error) throw error;
            if (data?.success === 0) {
                setShowModal({ show: true, contentType: "looking", errorMessage: data.error,loading:false });
            } else {
                handleClose();
                initFetchProgress();
            }
        });
    }

    useImperativeHandle(ref,()=>({
        showVinModal(){
            setShowModal({ show: true, contentType: "vin", errorMessage: "",loading:false });
        }
    }));


    return (
        <>
            <div className="container-circle-reds">
                
                {progressList.map((item, index) => {
                    let className = "";
                    var onClickDef = () => { console.log("clicked on circle") };
                    if (index === 0) {
                        onClickDef = () => { setShowModal({ show: true, contentType: "vin", errorMessage: "" }) };
                    } else if (index === 1) {
                        onClickDef = () => { navigate("/car-details") };
                    } else if (index === 2) {
                        onClickDef = () => { navigate("/profile") };
                    } else if (index === 3) {
                        onClickDef = () => { setShowModal({ show: true, contentType: "looking", errorMessage: "" }) };
                    }
                    return <Col key={item.title}> <ProgressCircle pathColor={index % 2 === 0 ? "#0176AC" : "#B62949"}  progress={item.completed} className={className} label={item.title} onclick={() => onClickDef()} /></Col>
                })}
            </div>


            <ModalPortal>
                <Modal show={showModal.show} onHide={() => handleClose()} centered>
                    {showModal.contentType === "vin" && <>
                        <Modal.Header closeButton>
                            <Modal.Title>Add VIN number</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {showModal.loading ? <Loading/> : <InputBotBoder invalid={showModal.errorMessage} ref={vinRef} label="Vin Number" placeholder="Enter Vin Number" />}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="row w-100">
                                <div className="col-6">
                                    <ButtonOutlineBoder onClick={() => handleClose()} className="py-2" label="Close" />
                                </div>
                                <div className="col-6">
                                    <ButtonBoder onClick={() => submitNewVin()} style={{ paddingTop: "9px", paddingBottom: "9px" }} label="Update" />
                                </div>
                            </div>
                        </Modal.Footer>
                    </>}
                    {showModal.contentType === "looking" && <>

                        <Modal.Header closeButton>
                            <Modal.Title>What are you looking for?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        {showModal.loading ? <Loading/> : <>
                        <div className="form-check form-switch">
                            <input ref={lookingCheckBoxRef} className="form-check-input" type="checkbox" role="switch" id="areYouLooking" />
                            <label className="form-check-label" htmlFor="areYouLooking">Are you looking for car?</label>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label">Description</label>
                            <textarea placeholder='Type ...' ref={descriptionLookingRef} className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                        </div></>}

                        </Modal.Body>
                        <Modal.Footer>
                        <div className="row w-100">
                                <div className="col-6">
                                    <ButtonOutlineBoder onClick={() => handleClose()} className="py-2" label="Close" />
                                </div>
                                <div className="col-6">
                                    <ButtonBoder onClick={() => submitLookingForCar()} style={{ paddingTop: "9px", paddingBottom: "9px" }} label="Update" />
                                </div>
                            </div>
                        </Modal.Footer>
                    </>}

                </Modal>
            </ModalPortal>
        </>
    )
});

export default ProgressContainerCircles