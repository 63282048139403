// @ts-nocheck
import { useState, useEffect, useRef } from 'react'
import { Row, Col } from 'react-bootstrap'
import APRcalculatorStar from './content/APRcalculatorStar';
import NegotiationChat from './content/NegotiationChat';
import SideList from './content/SideList';
import SideBarList from './parts/SideBarList';

var innerWidthLast = 0;
/// Component
const NegotiationsRoute = () => {
  const [selected, setSelected] = useState(null); // chat selected
  const initDefaultRef = useRef(true);

  const [navigatorMenu, setNaviagtor] = useState({
    current: "list", // list , chat , stats
    isMobile: null, // boolean 
  });

  useEffect(() => {
    innerWidthLast = 0;
    onResizeHandler();
    window.addEventListener("resize", onResizeHandler);
    return () => {
      window.removeEventListener("resize", onResizeHandler);
    }
  }, []);

  function onResizeHandler() {
    if(innerWidthLast===window.innerWidth) return;
    if (window.innerWidth < 1200) {
      setNaviagtor({ ...navigatorMenu, isMobile: true });
    } else {
      setNaviagtor({ ...navigatorMenu, isMobile: false });
    }
    innerWidthLast = window.innerWidth;
  }

  useEffect(() => {
    if (selected && selected.code) {
      window.history.replaceState({}, '', window.location.pathname + "?code=" + selected?.code);
    }
  }, [selected?.code]);




  return (
    <Row className="h-100 position-relative">

      {selected && navigatorMenu.isMobile && <div className="position-absolute d-xl-none d-flex" style={{ zIndex: 1, top: "-25px", display: "flex", justifyContent: "space-between" }}>
        {navigatorMenu.current === "chat" && <div className="btn-navigate-on-mobile-view" onClick={() => {
          initDefaultRef.current = false;
          setNaviagtor({ ...navigatorMenu, current: "list" }) }}>Back to list</div>}
        {navigatorMenu.current === "chat" && <div className="btn-navigate-on-mobile-view" onClick={() => setNaviagtor({ ...navigatorMenu, current: "stats" })}>View stats</div>}

        {navigatorMenu.current === "stats" && <div className="btn-navigate-on-mobile-view" onClick={() => setNaviagtor({ ...navigatorMenu, current: "chat" })}>Back to chat</div>}

        {navigatorMenu.current === "list" && <div></div>}
        {navigatorMenu.current === "list" && <div className="btn-navigate-on-mobile-view" onClick={() => setNaviagtor({ ...navigatorMenu, current: "chat" })}>View chat</div>}
      </div>}

      {(!navigatorMenu.isMobile || (navigatorMenu.isMobile && navigatorMenu.current === "list")) && <>
        <Col xl="3" className="flex-column d-xl-flex sidebar-border-coos px-0">
          <SideBarList initDefaultRef={initDefaultRef} setSelected={setSelected} setNaviagtor={setNaviagtor} />
        </Col>
      </>}

      <Col xl="9" className="d-flex flex-column pb-2 h-100">
        {selected ? <>
          <div className="row h-100">
             {(!navigatorMenu.isMobile || (navigatorMenu.isMobile && navigatorMenu.current === "chat")) && <div className="col">
              <NegotiationChat id="target-chat" selected={selected} setSelected={setSelected} />
            </div>}
            {(!navigatorMenu.isMobile || (navigatorMenu.isMobile && navigatorMenu.current === "stats")) && <div className="d-xl-flex flex-column middle-column-info pe-0 ps-1" >
               <APRcalculatorStar selected={selected} setSelected={setSelected} />
            </div>}
          </div>
        </> : <div className="d-flex justify-content-center align-items-center flex-grow-1">
          <img style={{ maxHeight: "250px", maxWidth: "100%" }} src="/images/icons/chats.svg" />
        </div>}

      </Col>
    </Row>
  )
}

export default NegotiationsRoute