// @ts-nocheck
import React from 'react'

const ButtonBoder = React.forwardRef((props,ref)=>{
    const {label,children,className,isactive,...attrProps } = props;
        return (
          <>
            <button ref={ref} {...attrProps} className={"button-boder "+(className || "")+" "+(isactive ? "active" : "")}>{label}</button>
            {children}
          </>
        )
})

export default ButtonBoder