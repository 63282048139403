// @ts-nocheck
import React from 'react'
import { imageStorageUrl } from '../../../config'

const ReciverMsgImage = ({ item }) => {
    function onLoadImageScroll(e){
        let parent = e.target.closest(".scroll-as-height-chat-body");
        if(parent){
            parent.scrollTop += e.target.getBoundingClientRect().height;
        }

    }
    return (
        <div className='reciver-msg'>

            <span time={(new Date(item.message_date)).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: "numeric", minute: "numeric" })} className='reciver-msg-text'>
                <img onLoad={onLoadImageScroll} style={
                    {
                        maxWidth: "100%",
                        objectFit: "contain",
                        maxHeight: "300px",
                        height: "100%",
                        cursor: "pointer"
                    } } 
                     src={imageStorageUrl+item.file_name} alt={item.file_name} />
            </span>
        </div>
    )
}
export default ReciverMsgImage