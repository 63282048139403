import { UserDataContext } from 'App';
import { memo, useContext, useReducer, useState } from 'react';
import apiUploadFileImage from '../request/apiUploadFileImage';
import { imageUrlThumb } from 'config';
import { IoMdArrowDropleft } from '@react-icons/all-files/io/IoMdArrowDropleft';
import { IoMdArrowDropright } from '@react-icons/all-files/io/IoMdArrowDropright';
import {FaTrash} from '@react-icons/all-files/fa/FaTrash';

const ImagesAndUpload = ({ photos, carState }) => {
  const userData = useContext(UserDataContext)[0];
  const [photosList, setPhotosList] = useState(photos.map(i => ({ imageUrl: imageUrlThumb + i })) ?? []);
  const [offsetImage, dispatchOffsetImage] = useReducer((state, action) => {
    switch (action) {
      case "next":
        return state + 1 > photosList.length - 3 ? photosList.length - 3 : state + 1;
      case "prev":
        return state - 1 < 0 ? 0 : state - 1;
      case "reset":
        return 0;
      default:
        return state;
    }
  }, 0);


  function handleFileChange(e) {
    let files = [...e.target.files];
    e.target.value = '';
    console.log("handleFileChange files:", files);
    handleDrop({
      preventDefault: () => { },
      stopPropagation: () => { },
      dataTransfer: {
        files: files
      }
    });
  }

  function handleDrop(e) {
    e?.preventDefault();
    e?.stopPropagation();
    let files = e.dataTransfer.files;
    console.log(" handleDrop files:", files);
    let list = [];
    for (let i = 0; i < files.length; i++) {
      if (!files[i].type.match('image.*')) {
        continue;
      }
      let local_id = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
      list.push({ File: files[i], progress: "", local_id: local_id, status: "waiting", imageUrl: URL.createObjectURL(files[i]) });
    }
    uploadFilesRequest(list);
  }

  function uploadFilesRequest(list) {
    dispatchOffsetImage("reset");
    setPhotosList([...list, ...photosList]);
    let token = userData.token;
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      console.log("token,car_id,file:", token, carState.trade_car_id, item.File)
      apiUploadFileImage({
        token: token,
        car_id: carState.trade_car_id,
        file: item.File,
        onProgress: (progress) => {
          console.log("progress download:", progress);
          setPhotosList((prev) => {
            let indexOfItem = prev.findIndex(i => i.local_id === item.local_id);
            if (indexOfItem === -1) return prev;
            prev[indexOfItem].progress = progress;
            prev[indexOfItem].status = "uploading";
            console.log("progress:",prev[indexOfItem]);
            return [...prev];
          })
        },
        onCompleted: (error, data) => {
          console.log("completed download:", error, data);
          setPhotosList((prev) => {
            let indexOfItem = prev.findIndex(i => i.local_id === item.local_id);
            if (indexOfItem === -1) return prev;
            prev[indexOfItem].progress = 100;
            prev[indexOfItem].status = "completed";
            return [...prev];
          });
        }
      });
    }
  }

  return (
    <>
      <div onDragOver={(e) => e.preventDefault()} onDrop={handleDrop} className='upload-container-drop-speed'>
        <label htmlFor='image-pusher' className="c-pointer">
          <input onChange={handleFileChange} id="image-pusher" type="file" name="file" className="inputfile" multiple hidden />
          <img className="mb-2" height="34px" src="/images/speed/upload.svg" alt="" />
          <div>Click or Drag image to <span style={{ color: "#0176AC" }}>upload</span> Photo</div>
        </label>
      </div>

      <div className="image-uploader-container flex-wrap">
        {photosList.length > 3 &&
          <div className="d-flex align-items-center ">
            <IoMdArrowDropleft className="c-pointer image-offset-changer left" onClick={() => dispatchOffsetImage("prev")} size="2em" />
          </div>
        }
        {photosList.slice(offsetImage, offsetImage + 3).map((item, index) => {
          return <ColImages key={item.imageUrl} item={item} />
        })}
        {photosList.length > 3 && <>
          <div className="d-flex align-items-center ">
            <IoMdArrowDropright className="c-pointer image-offset-changer right" onClick={() => dispatchOffsetImage("next")} size="2em" />
          </div></>
        }
      </div>
    </>
  )
}

export default ImagesAndUpload

function ColImagesRaw({ item }) {
  return <div key={item.imageUrl} style={{ width: "70px", height: "50px", position: "relative", cursor:"pointer" }}>
    <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={item.imageUrl} alt="" />
  </div>
}

const ColImages = memo(ColImagesRaw);