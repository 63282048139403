import React,{useState} from 'react'
import { BiSearchAlt } from '@react-icons/all-files/bi/BiSearchAlt';
import { useEffect } from 'react';
import {apiFetchInventorySearch} from 'component/homeStar/request/apiFetchInventorySearch';
import {imageUrlThumb} from 'config';
import { useRef } from 'react';
import {useNavigate} from 'react-router-dom';

const SearchInventory = () => {
    const [filter, setfilter] = useState("");
    const filterRef = useRef(null);
    const searchDropdownRef = useRef(null);
    const [listInventory, setlistInventory] = useState([]);
    const [maxLimit, setmaxLimit] = useState(10);
    const navigate = useNavigate();

    useEffect(()=>{
        filterRef?.current?.addEventListener("keyup", eventKeyUpFilter);
        document.addEventListener("click", eventClickOutsideSearchDropdown);
        apiFetchInventorySearch(responseInventorySearch);
        return ()=>{
            filterRef?.current?.removeEventListener("keyup", eventKeyUpFilter);
            document.removeEventListener("click", eventClickOutsideSearchDropdown);
        }
    },[]);

    function responseInventorySearch(error,data){
        if(error) throw error;
        setlistInventory(data ?? []);
    }
    function eventKeyUpFilter(e){
        setfilter(e.target.value);
    }
    function eventClickOutsideSearchDropdown(e){
        if(searchDropdownRef?.current?.contains(e.target)){
            searchDropdownRef.current.classList.add('show');
            return;
        }
        if(filterRef?.current?.contains(e.target)){
            searchDropdownRef.current.classList.add('show');
            return;
        }
        setfilter("");
        searchDropdownRef.current.classList.remove('show');
        setmaxLimit(10);
    }

    function navigateToCar(code){
        if(filterRef.current){
            filterRef.current.value = "";
        }
        navigate("/car-search?code="+code);
    }


    const filterList = listInventory.filter((item) => {
        return item.title.toLowerCase().includes(filter.toLowerCase());
    });

    const filterListWithLimits = filterList.slice(0,maxLimit);
    
    return (
        <div className="navbar-search-container-container">
            {/* <BiSearchAlt color="#b72949" size="30" /> */}
            <img src="/images/speed/search.svg" className="navbar-search-icon" alt="search"/>
            <input ref={filterRef} placeholder="Search for your new vehicle here..." className="navbar-search-container" type="text" />
            <div ref={searchDropdownRef} className="navbar-search-dropdown-container">
                {filterListWithLimits && filterListWithLimits.map((item,index)=>{
                    return <div onClick={()=>{navigateToCar(item.code)}} className="row-found-search-nv" key={index} style={{display:"flex",padding:"5px 10px",justifyContent:"space-between"}}>
                        <span style={{width:"75%",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}><img className="me-2" height="30px" width="40px" src={item.photo}/> {item.title}</span> 
                        <span style={{color:"#29B74A",fontWeight:"bold"}}>
                            {(item.price === 0 ? "Contact Us" : "$"+item.price.toLocaleString())}
                        </span>
                    </div>
                })}
                {filterListWithLimits.length === 0 && <div className="row-found-search-nv" style={{display:"flex",padding:"5px 10px"}}>No results found</div>}
                {filterListWithLimits.length < filterList.length && <div onClick={()=>setmaxLimit(maxLimit+10)} className="row-found-search-nv" style={{padding:"5px 10px",color:"rgb(183 41 73)",textAlign:"center"}}>Show more results</div>}
            </div>
        </div>
    )
}

export default SearchInventory