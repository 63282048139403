// @ts-nocheck
import React from 'react'
import { Col, Modal } from 'react-bootstrap'
import ButtonBoder from './componenetInput/ButtonBoder'
import ModalPortal from './componenetInput/ModalPortal'
import {UserDataContext} from '../App'
import { baseURL } from 'config'
import axios from 'axios'

const LogoutConfirm = ({setShowLogoutConfirm,showLogoutConfirm}) => {
   const [userData,setUserData] = React.useContext(UserDataContext);
    React.useEffect(() => {
        if(userData.email !== ""){
            logout();
        }
    }, []);

    function unListCar(callback){
        if(userData.email !== ""){
            logout();
            return ;
        }
        axios({
            method: "POST",
            url: baseURL + "/seller/unlist",
            headers: {
                'Authorization': userData.token
            }
        }).then((request) => {
            if(callback){
                callback();
            }
        }
        ).catch(error => {
            console.error("Error:", error);
        })
    }

    function logout(){
        setUserData({});
        setShowLogoutConfirm(false);
    }
    
    return (
        <ModalPortal>
            <Modal centered show={showLogoutConfirm} onHide={()=>{setShowLogoutConfirm(false)}}>
                <Modal.Header>
                    <Modal.Title style={{color:"red"}}>IMPORTANT!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This action will delete all information of your vehicle from this platform. All photos, bids and messages will no longer be seen by you or any bidders.
                    <br/>
                    <br/>
                    Setting up a profile in the menu tab, will allow you to come back to your auction and information at any time.
                </Modal.Body>
                <Modal.Footer className="d-flex">
                   <Col>
                   <ButtonBoder style={{background: '#878787'}} label="Cancel" onClick={()=>{setShowLogoutConfirm(false)}} />
                   </Col>
                   <Col>
                   <ButtonBoder label="Yes, Sign off" onClick={()=>{
                    unListCar(()=>{
                        logout();
                    });
                   }} />
                   </Col>
                </Modal.Footer>
            </Modal>
        </ModalPortal>
    )
}

export default LogoutConfirm