import React,{useState,useContext} from 'react'
import ButtonBoder from '../../componenetInput/ButtonBoder';
import InputBotBoder from '../../componenetInput/InputBotBoder';
import { ContextForm } from '../contexts/FormContext';
import {fetchMoreDataFromVin} from '../request/PathVinRequest';

const PathVin = () => {
  const {formRegister,appendFormRegister,editFormRegister} = useContext(ContextForm);
  const [form, setForm] = useState({
    vin: formRegister.vin ?? "",
  });
  const [formError, setFormError] = useState({});

  function validateForm() {
    let error = {};
    if (form.vin === "") error.vin = "Vin is required";
    setFormError(error);
    if (Object.keys(error).length > 0) return false;
    return true;
  }

  function handleSubmit() {
    if (!validateForm()) return;
   
    fetchMoreDataFromVin(form.vin,(error,data)=>{
      if(error){
        setFormError({vin:"Unable to obtain data from the vin, please check your network and try again."});
        throw error;
      }
      const {success,...restOfData} = data;
      if(success === 0){
        setFormError({vin:data.error});
      }else{
        appendFormRegister({...form,...restOfData});
        editFormRegister("stepType","detail");
      }
    });
  }

  return (
    <>
      <InputBotBoder invalid={formError.vin} value={form.vin} name="vin" onChange={(e) => setForm(i => { return { ...i, [e.target.name]: e.target.value } })} label="VIN" placeholder="Vin number" />
      <ButtonBoder onClick={handleSubmit} label="Next" />
    </>
  )
}

export default PathVin