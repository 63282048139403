// @ts-nocheck
import React, { useRef, useEffect } from 'react'

const ReciverMsg = ({ item }) => {
  // create function that finds how many seconds , minutes , hours , days , months , years ago the message was sent
  const intervalRef = useRef(null);
  const timeRefElement = useRef(null);


  useEffect(() => {
    // if the message was send less than 10 min ago , update the time every 10 seconds


    intervalRef.current = setInterval(() => {
      const now = new Date();
      const messageDate = new Date(item.message_date);
      const diff = now - messageDate;
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      if (minutes > 10){
        clearIntervalRef();
        return;
      }

      timeRefElement.current.setAttribute('time', formatTimeAgo(item.message_date));
    }, 10 * 1000);

    return () => {
      clearIntervalRef();
    }
  }, []);
  const clearIntervalRef = () => {
    if (intervalRef?.current) {
      clearInterval(intervalRef.current);
    }
  }


  const formatTimeAgo = (date) => {
    const now = new Date();
    const messageDate = new Date(date);
    const diff = now - messageDate;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    if (seconds < 29) {
      return "a few seconds ago";
    } else if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    }
    return messageDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: "numeric", minute: "numeric" });
  }


  var msg = '';

  if (item.message.includes('http')) {
    msg = item.message.replace(/(https?:\/\/[^\s]+)/g, '<a target="_blank" href="$1">$1</a>');
  }else{
    msg = item.message;
  }


  // time={(new Date(item.message_date)).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: "numeric", minute: "numeric" })
  return (
    <div className='reciver-msg'>
      <span ref={timeRefElement} time={formatTimeAgo(item.message_date)} dangerouslySetInnerHTML={{ __html: msg }} className='reciver-msg-text'>
      </span>
    </div>
  )
}

export default ReciverMsg