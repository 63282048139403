import axios from 'axios';
import { useContext } from 'react'
import { UserDataContext } from '../../../App';
import { baseURL } from '../../../config';

import {AiOutlinePaperClip} from 'react-icons/ai';


const UploadMsgFile = ({setChatData,fetchMessage, roomData, progressBarRef }) => {
    const userData = useContext(UserDataContext)[0];

    function uploadFileEvent(e) {
        if (e.target.files.length === 0) return;

        console.log("e.target.files[0]:", e.target.files[0]);

        let fd = new FormData();
        fd.append("room_id", roomData.room_id);
        fd.append("dealer_id", "0");
        fd.append("file", e.target.files[0]);

        axios({
            method: "POST",
            url: baseURL + "/seller/message/upload",
            data: fd,
            headers: {
                'Authorization': userData.token
            },
            onUploadProgress: (progressEvent) => {
                const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                if (totalLength === null) return;
                let progressData = Math.round((progressEvent.loaded * 100) / totalLength);
                if (progressBarRef && progressBarRef.current && progressBarRef.current.children[0]) {
                    progressBarRef.current.parentNode.style.display = '';
                    progressBarRef.current.children[0].style.width = progressData+"%";
                    progressBarRef.current.children[0].innerHTML = progressData+"%";
                    if(progressData === 100){
                        progressBarRef.current.children[0].innerHTML = 'UPLOADED';
                        setTimeout(()=>{
                            if (progressBarRef && progressBarRef.current && progressBarRef.current.children[0])
                                progressBarRef.current.parentNode.style.display = 'none';
                        },3000);
                    }
                }
                // progressBarRef
            }
        }).then((response) => {
            if(setChatData)
            setChatData((aaa)=>{
                return {
                    ...aaa,
                    chatMessages:[...aaa.chatMessages,{
                        message_id: Math.floor(Math.random()*Number.MAX_SAFE_INTEGER),
                        message_date: new Date().toISOString(),
                        file_name: response.data.file_name,
                        you: 1
                    }]
                }
            });
            if(fetchMessage)
            fetchMessage();
            // chatMessages
        }).catch((error) => {
            console.error("error:", error);
        }).finally(()=>{
            e.target.value = '';
        })
    }

    return (
        <>
            <input accept="image/*" onChange={uploadFileEvent} type="file" id="uploadMsgFile" hidden />
            <label htmlFor='uploadMsgFile' className='px-2 me-1 c-pointer' style={{borderRight:"1px solid #949495"}}>
                <AiOutlinePaperClip size={25} color="#B62949" />
            </label>
        </>
    )
}

export default UploadMsgFile