import React from 'react'
import { useEffect, useContext, useState } from 'react'
import { UserDataContext } from 'App';
import { fetchAllUsers } from 'component/negotiations/request/fetchallUsers';
import { Row, Col } from 'react-bootstrap';
import { apiFetchInventorySearch } from 'component/homeStar/request/apiFetchInventorySearch';
//import { HiOutlineLocationMarker } from '@react-icons/all-files/hi/HiOutlineLocationMarker'
import { fetchRecentlySold } from 'component/homeStar/request/apiFetchRecentlySoldCar';
import {useNavigate} from 'react-router-dom';
import { BsFillChatLeftFill } from 'react-icons/bs';
import { HiLocationMarker } from 'react-icons/hi';

const NegotationsStar = () => {
    const [userData, setUserData] = useContext(UserDataContext);
    const [negotiationsList, setNegotiationsList] = useState([]);
    const [inventoryList, setInventoryList] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        //console.log("DEVLOG NegotationsStar has been inited!");
        fetchAllUsers(userData.token, responseFetchAllUsers);
        apiFetchInventorySearch(responseFetchInventorySearch);
    }, []);
    function responseFetchAllUsers(error, data) {
        if (error) { throw error; }
        setNegotiationsList(data);
    }
    function responseFetchInventorySearch(error, data) {
        if (error) { throw error; }
        setInventoryList(data ?? []);
    }
    

    return (
        <div className='d-flex flex-wrap flex-column'>
            <div className="mb-2 d-flex align-items-center justify-content-between">
                <div className='header-speed-negotiations'>Negotiations</div>
                <div onClick={()=>navigate("/negotiations")} className='subheader-speed-negotiations red c-pointer'>View all</div>
            </div>

            <div className="d-flex justify-content-between p-2 my-3" style={{border:"1px solid #e7eef6",borderRadius:"10px"}}>
                <div className='subheader-speed-negotiations d-flex align-items-center ps-3'>Active Negotiations</div>

                <div onClick={()=>navigate("/negotiations")} className="d-flex align-items-center c-pointer" >
                    <div className="px-3 py-2 me-1" style={{color:"white",background:"#B62949",borderRadius:"0.2rem"}}>
                        {negotiationsList.length}
                    </div>
                    <div className='px-3 py-2' style={{color:"#B62949", background:"#B6294920",borderRadius:"0.2rem"}}>
                        <BsFillChatLeftFill />
                    </div>
                </div>
            </div>


            <div className="overflow-auto">
                {negotiationsList.slice(0,7).map((item, index) => {
                    return <RowNegotiation key={index} {...item} />
                })}
                {/* <RowNegotiation/> */}
                

            </div>
        </div>
    )
}

export default NegotationsStar


function RowNegotiation(item){
    const navigate = useNavigate();
    let { photo, code,location_short,price,dealer_name,title } = item;
    // http://localhost:3000/negotiations?code=9cfb0bebfb890811a45f57ad6cab7b9f
    return <div onClick={()=>navigate("/negotiations?code="+code)} className="row-container-negotiation-speed border-bottom">
    <div><img style={{height:"70px",width:"100px",objectFit:"contain"}} onError={(e)=>e.src = 'https://i.imgur.com/ETCyBEt.png'} src={photo} alt=""/></div>
    <div className="ps-2 d-flex justify-content-between align-items-center flex-grow-1">
      <div className="d-flex flex-column justify-content-center">
        <div className='subheader-speed-negotiations'>{title}</div>
        <div className="location"><HiLocationMarker size={25} color="#B62949"/> {location_short}</div>
      </div>
      <div className="d-flex flex-column justify-content-center text-right">
        <div className='small-title-speed'>{dealer_name}</div>
        <div className="price">${price?.toLocaleString()}</div>
      </div>
    </div>
  </div>
}