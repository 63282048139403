import { useContext,useEffect } from 'react'
import { Row, Col } from 'react-bootstrap';
import CarouselAutoCheck from '../compontentSearch/CarouselAutoCheck';
import { ModalCarContext } from '../Context/ModalCarContext';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import TestDrive from './SubSecondary/TestDrive';
import MoreInfo from './SubSecondary/MoreInfo';
import YourAccount from './SubSecondary/YourAccount';
import Calculator from './SubSecondary/Calculator';
import TradeByType from './SubTrade/TradeByType';
import TradeVinNumber from './SubTrade/TradeVinNumber';
import TradeDetails from './SubTrade/TradeDetails';
import TradeImages from './SubTrade/TradeImages';
import TradeLicensePlate from './SubTrade/TradeLicensePlate';
import TradeManual from './SubTrade/TradeManual';

const SecondaryModal = () => {
  const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
  function handleBackButton(){
    dispatchModalCar({
      type: "update",
      payload: {
        currentModal: "primaryModal" 
      }
    });
  }


  return (
    <>
      <Row>
        <Col xl={3}>
          {modalCar.photos && <CarouselAutoCheck carDetails={modalCar} removelimits={false} />}
        </Col>
        <Col xl={9} className="p-md-2 pe-md-4">
          <div className="d-flex">
            <div className="col-12 justify-content-between d-flex bordered-speed align-items-center">
              <div className='header-title-speed-modal'>
                <BsFillArrowLeftCircleFill onClick={modalCar?.actions?.backButton ?? handleBackButton} className="back-icon-speed" />
                {modalCar.title}</div>
              <div className="flex-grow-1 flex-shrink-0">
                <div className='asking-price'>Asking price:</div>
                <div className='header-title-speed-price'>
                {(modalCar?.price == 0 ? "Contact Us" : "$"+modalCar?.price?.toLocaleString())}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <span className='row-header-speed d-flex align-items-center'>Dealership Name</span>
            <span className='row-info-speed'>{modalCar.dealer_name}</span>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <span className='row-header-speed d-flex align-items-center'>Location</span>
            <span className='row-info-speed'>{modalCar.location}</span>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <span className='row-header-speed d-flex align-items-center'>Dealer Phone Number</span>
            <a href={`tel:${modalCar.dealer_phone}`} className='anchor-no-decoration row-info-speed color-theme'>
              {modalCar.dealer_phone}
              <img className="ms-2" src="./images/icons/phoneCircle.svg" alt="" />
            </a>
          </div>
        </Col>
        <Col xl={12} className="justify-content-center d-flex flex-wrap flex-column justify-content-center align-items-center">
          <div className="d-flex justify-content-center my-2">
            {modalCar?.msg?.type === "success" && <span className="msg-success-speed" role="alert">
              {modalCar.msg.message}
             </span>}

            {modalCar?.msg?.type === "error" && <span className="msg-error-speed" role="alert">
              {modalCar.msg.message}
            </span>}
          </div>
          {modalCar?.currentSubModal === "testDrive" && <TestDrive />}
          {modalCar?.currentSubModal === "moreInfo" && <MoreInfo />}
          {modalCar?.currentSubModal === "yourAccount" && <YourAccount />}
          {modalCar?.currentSubModal === "calculator" && <Calculator/>}

          {/* start line trade in */}
          {modalCar?.currentSubModal === "tradeByType" && <TradeByType />}

          {modalCar?.currentSubModal === "tradeByVin" && <TradeVinNumber />}
          {modalCar?.currentSubModal === "tradeByLicensePlate" && <TradeLicensePlate />}
          {modalCar?.currentSubModal === "tradeByManual" && <TradeManual/>}

          {modalCar?.currentSubModal === "tradeDetails" && <TradeDetails />}
          {modalCar?.currentSubModal === "tradeImages" && <TradeImages />}
          {/* end line trade in */}
        </Col>
      </Row>
    </>
  )
}

export default SecondaryModal