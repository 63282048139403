import { SocketDataContext, UserDataContext } from 'App';
import { useContext, useEffect, useReducer, useState, useRef } from 'react'
import { updateOfferAPI } from '../request/updateOfferAPI';
import { fetchSelectedDetails } from '../request/fetchSelectedDetails';
import Loading from 'component/Loading';
import CarInformation from './CarInformation';
import TradeInModal from './TradeInModal';

function monthly_paymentCalc(loanAmount, apr, loanTerm) {
    apr = apr / 100;
    if (apr === 0) {
        return loanAmount / loanTerm;
    }
    var monthlyPayment = (loanAmount * (apr / 12)) / (1 - Math.pow(1 + (apr / 12), -loanTerm));
    return monthlyPayment;
}


const CalculatorReducer = (state = {}, action) => {
    var targetUpdate = {}; // used to update target action
    switch (action.type) {
        case 'update_trade_photos':
            {
                let trade_photo = action.payload;
                let list_trade_photos = state?.trade_photos?.split(",") || [];
                list_trade_photos.push(trade_photo);
                let trade_photos = list_trade_photos.join(",");
                return { ...state, trade_photos: trade_photos };
            }
        case 'update_state':
            var state = action.payload;
            targetUpdate = { showButtonUpdate: false };
        case 'update_price':
            Object.keys(targetUpdate).length === 0 && (targetUpdate = { price: action.payload });
        case 'update_down_payment':
            Object.keys(targetUpdate).length === 0 && (targetUpdate = { down_payment: action.payload });
        case 'update_trade_loan_balance':
            Object.keys(targetUpdate).length === 0 && (targetUpdate = { trade_loan_balance: action.payload });
        case 'update_trade_in':
            Object.keys(targetUpdate).length === 0 && (targetUpdate = { trade_in: action.payload });
            {
                let showButtonUpdate = targetUpdate['showButtonUpdate'] ?? true;

                let price = targetUpdate['price'] ?? state?.price ?? 0;
                let down_payment = targetUpdate['down_payment'] ?? state?.down_payment ?? 0;
                let trade_in = targetUpdate['trade_in'] ?? state?.trade_in ?? 0;
                let trade_loan_balance = targetUpdate['trade_loan_balance'] ?? state?.trade_loan_balance ?? 0;
                let tax_rate = state?.tax_rate ?? 0;
                let apr = state?.apr ?? 0;
                let loan_term = state?.loan_term ?? 0;
                let gap = state?.gap ?? 0;
                let warranty = state?.warranty ?? 0;
                let accessories = state?.accessories ?? 0;
                let fees = state?.fees ?? 0;



                var priceWithExtras = warranty + gap + accessories + fees;
                var tax = (price + priceWithExtras - trade_in) * (tax_rate / 100);
                tax = tax < 0 ? 0 : tax;
                var loan_amount = (price + priceWithExtras + tax - down_payment) - (trade_in - trade_loan_balance);
                loan_amount = loan_amount < 0 ? 0 : loan_amount;


                let monthly_payment = monthly_paymentCalc(loan_amount, apr, loan_term);
                return { ...state, fees, warranty, accessories, gap, price, loan_term, original_price: state.price, down_payment, trade_in, trade_loan_balance, tax_rate, apr, loan_amount, monthly_payment, showButtonUpdate: showButtonUpdate, taxValue: tax };
            }
        case 'update_btn':
            return { ...state, showButtonUpdate: false };
        default:
            return state
    }
}

const APRcalculatorStar = ({ selected, setSelected }) => {
    const [loading, setLoading] = useState(true);
    const userData = useContext(UserDataContext)[0];
    const { socketData } = useContext(SocketDataContext);
    const refModalTradeIn = useRef(null);

    const [state, dispatch] = useReducer(CalculatorReducer, selected);
    const [statusRequestUpdate, setStatusRequestUpdate] = useState({
        type: "",
        message: "",
    });
    useEffect(() => {
        if (statusRequestUpdate?.type === "") return;
        setTimeout(() => {
            setStatusRequestUpdate({
                type: "",
                message: "",
            })
        }, 3000);
    }, [statusRequestUpdate?.type]);


    useEffect(() => {
        setLoading(true);
        setStatusRequestUpdate({
            type: "",
            message: "",
        });
        initChanges();
    }, [selected?.code]);

    useEffect(() => {
        // socketData
        const socket = socketData?.socket;
        const selectedCode = selected?.code;
        if (!socket || !selectedCode) return; // if not socket or selected code return nothing
        socket.on('new-numbers', onNewNumber);
        return () => {
            socket.off('new-numbers', onNewNumber);
        }
    }, [socketData?.socket]);


    function initChanges() {
        fetchSelectedDetails(userData.token, selected.code, (error, data, codeVal) => {
            if (error) throw error;
            if (selected.code !== codeVal) return;
            const { code, ...restOfData } = data;

            setSelected((pState) => {
                return { ...pState, ...restOfData }
            });
            dispatch({ type: 'update_state', payload: { ...selected, ...restOfData } });
            setLoading(false);
        });
    }


    function onNewNumber(msg) {
        const split = msg?.split("|") ?? []; // code|dealer_name
        if (split.length < 2) return; // if not valid
        const code = split[0];
        if (selected.code === code) {
            initChanges();
        }
    }

    function handlePriceChange(e) {
        var valuePrice = e.target.value;
        valuePrice = valuePrice.replace(/[^0-9]/g, '');
        valuePrice = valuePrice === "" ? 0 : parseInt(valuePrice);
        if (valuePrice > 500000) {
            valuePrice = 500000;
        }
        e.target.value = `$` + valuePrice.toLocaleString();
        dispatch({ type: 'update_price', payload: valuePrice });
    }
    function handleDownPaymentChange(e) {
        var valueDownPayment = e.target.value;
        valueDownPayment = valueDownPayment.replace(/[^0-9]/g, '');
        valueDownPayment = valueDownPayment === "" ? 0 : parseInt(valueDownPayment);
        if (valueDownPayment > 500000) {
            valueDownPayment = 500000;
        }
        e.target.value = `$` + valueDownPayment.toLocaleString();
        dispatch({ type: 'update_down_payment', payload: valueDownPayment });
    }
    function handleTradeInChange(e) {
        var valueTradeIn = e.target.value;
        valueTradeIn = valueTradeIn.replace(/[^0-9]/g, '');
        valueTradeIn = valueTradeIn === "" ? 0 : parseInt(valueTradeIn);
        if (valueTradeIn > 500000) {
            valueTradeIn = 500000;
        }
        e.target.value = `$` + valueTradeIn.toLocaleString();
        dispatch({ type: 'update_trade_in', payload: valueTradeIn });
    }

    function handleTradeLoanBalanceChange(e) {
        var valueTradeLoanBalance = e.target.value;
        valueTradeLoanBalance = valueTradeLoanBalance.replace(/[^0-9]/g, '');
        valueTradeLoanBalance = valueTradeLoanBalance === "" ? 0 : parseInt(valueTradeLoanBalance);
        if (valueTradeLoanBalance > 500000) {
            valueTradeLoanBalance = 500000;
        }
        e.target.value = `$` + valueTradeLoanBalance.toLocaleString();
        dispatch({ type: 'update_trade_loan_balance', payload: valueTradeLoanBalance });
    }

    function handleUpdateOffer() {
        updateOfferAPI({
            code: selected.code,
            price: state.price,
            down_payment: state.down_payment,
            trade_in: state.trade_in,
            trade_loan_balance: state.trade_loan_balance,
        }, userData.token, (err, data) => {
            if (err || data?.success === 0) {
                setStatusRequestUpdate({
                    type: "error",
                    message: err.message
                });
                return;
            }
            setStatusRequestUpdate({
                type: "success",
                message: "Update offer success"
            });
            fetchSelectedDetails(userData.token, selected.code, (error, data, codeVal) => {
                if (error) throw error;
                if (selected.code !== codeVal) return;
                const { code, ...restOfData } = data;
                setSelected((pState) => {
                    return { ...pState, ...restOfData }
                });
            });
            dispatch({ type: 'update_btn' });
        });
    }




    if (loading) {
        return <Loading />
    }
    return (<>
        <div className="card-info-about">
            {statusRequestUpdate.type === "error" && <div className="alert alert-danger" role="alert"> {statusRequestUpdate.message} </div>}
            {statusRequestUpdate.type === "success" && <div className="alert alert-success" role="alert"> {statusRequestUpdate.message} </div>}

            <div className="card-row-info-about">
                <div><small>{state.financing === 1 ? "Your Offer" : "Cash Offer"}</small></div>
                <div style={{ width: "95px" }}>
                    <input onChange={handlePriceChange} onFocus={(e) => e.target.select()} value={"$" + state.price.toLocaleString()} type="text" className="press-input-transit" />
                </div>
            </div>

            <div className="card-row-info-about">
                <div><small>Dealer Offer</small></div>
                <div style={{ width: "95px" }}>${Math.round(selected?.dealer_price ?? 0).toLocaleString()}</div>
            </div>

            {state.financing === 1 && <>
                <div className="card-row-info-about">
                    <div><small>Down Payment</small></div>
                    <div style={{ width: "95px" }}>
                        <input onChange={handleDownPaymentChange} onFocus={(e) => e.target.select()} value={"$" + state.down_payment.toLocaleString()} type="text" className="press-input-transit" />
                    </div>
                </div>
            </>}

            <div className="card-row-info-about">
                <div><small>Warranty</small></div>
                <div style={{ width: "95px" }}>{((selected?.warranty == 0 || selected?.warranty == null) ? "N/A" : "$" + selected?.warranty.toLocaleString())}</div>
            </div>

            <div className="card-row-info-about">
                <div><small>Gap Insurance</small></div>
                <div style={{ width: "95px" }}>{((selected?.gap == 0 || selected?.gap == null) ? "N/A" : "$" + selected?.gap.toLocaleString())}</div>
            </div>

            <div className='card-row-info-about'>
                <div><small>Accessories</small></div>
                <div style={{ width: "95px" }}>{(selected?.accessories === 0 || selected?.accessories === null ? "N/A" : "$" + selected?.accessories.toLocaleString())}</div>
            </div>

            <div className="card-row-info-about">
                <div><small>Fees</small></div>
                <div style={{ width: "95px" }}>+${(selected?.fee ?? selected?.fees ?? 0)}</div>
            </div>

            <div className="card-row-info-about">
                <div><small>Sales tax ({state.tax_rate}%)</small></div>

                <div style={{ width: "95px" }}>${Math.round(state?.taxValue || 0).toLocaleString()}</div>
            </div>

            {state.financing === 1 ? <>
                <div className="card-row-info-about">
                    <div><small>Loan Amount</small></div>
                    <div style={{ width: "95px" }}>${Math.round(state.loan_amount ?? 0).toLocaleString()}</div>
                </div>


                <div className="card-row-info-about">
                    <div><small>Loan Term</small></div>
                    <div style={{ width: "95px" }}>{(state?.loan_term)} Months</div>
                </div>

                <div className="card-row-info-about">
                    <div><small>APR</small></div>
                    <div style={{ width: "95px" }}>{selected?.apr}%</div>
                </div>

                <div className="card-row-info-about">
                    <div><small>Monthly Payment</small></div>
                    <div style={{ "color": "#b72949", fontSize: "18px", width: "95px", fontWeight: "bold" }} >${Math.round(state.monthly_payment).toLocaleString()}<small></small></div>
                </div>
            </> : <>
                <div className="card-row-info-about">
                    <div><small>Total Due</small></div>
                    <div style={{ "color": "#b72949", fontSize: "18px", width: "95px", fontWeight: "bold" }} >${Math.round(state.loan_amount).toLocaleString()}<small></small></div>
                </div>
            </>}
        </div>

        <CarInformation reload={initChanges} refModalTradeIn={refModalTradeIn} state={state} setSelected={setSelected} dispatchState={dispatch} selected={selected} handleTradeLoanBalanceChange={handleTradeLoanBalanceChange} handleUpdateOffer={handleUpdateOffer} handleTradeInChange={handleTradeInChange} trade_in={state?.trade_in ?? 0} />
        <TradeInModal reload={initChanges} state={state} ref={refModalTradeIn} />
    </>
    )
}

export default APRcalculatorStar