import ModalPortal from 'component/componenetInput/ModalPortal'
import { baseURL } from 'config';
import React,{useState,useEffect}  from 'react';
//import {useState} from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import ButtonOutlineBoder from 'component/componenetInput/ButtonOutlineBoder';
import {fetchProgressVehicle} from 'component/homeWorld/request/fetchProgressVehicle';
import { useContext } from 'react';
import {UserDataContext} from 'App';

const HearRefferal = ({onComplete=()=>{},onCancel=()=>{},display = false}) => {
    const [userData,setUserData] = useContext(UserDataContext);
    const [show, setShow] = useState(null);
    const [hasCompletedRefferal,setHasCompletedRefferal] = useState(null);

    const otherOptionRef = React.createRef();
    const [otherOptionSelected,setOtherOptionSelected] = useState(false);
    const [refferal,setRefferal] = useState({
        title:"",
        options:[],
        loaded:false,
    });
    useEffect(()=>{
        if(onCancel  && typeof onCancel === 'function'&& show === false){
            setHasCompletedRefferal(null);
            onCancel();
        }
    },[show]);

    useEffect(() => {
        if(!display) return;

        fetchProgressVehicle(userData.token,(error,data) => {
            //console.log("DEVLOG data?.auction?.referral_source:",data?.auction?.referral_source);
            if(error) throw error;
           
            if(!userData?.car){
                setShow(false);
                return;
            }
            setHasCompletedRefferal(!!data?.auction?.referral_source);
        });

    }, [display]);

    useEffect(() => {
        if(hasCompletedRefferal === null) return;

        if(hasCompletedRefferal){
            onComplete();
            setShow(false);
        }else {
            setShow(true);
        }

        fetchRefferalOptions();
    }, [hasCompletedRefferal]);


    function fetchRefferalOptions(){
        axios({
            method: 'get',
            url: baseURL + '/seller/settings',
        }).then((response) => {
            if(response?.data?.referral){
                // const otherOption = response.data.referral.options.find((option)=>option.value === 'Other');
                // const otherOptionIndex = response.data.referral.options.indexOf(otherOption);
                // response.data.referral.options.splice(otherOptionIndex,1);
                // response.data.referral.options.push(otherOption);

                const otherOptionIndex = response.data.referral.options.findIndex((option)=>option === 'Other');
                if(otherOptionIndex !== -1){
                    response.data.referral.options.splice(otherOptionIndex,1);
                    response.data.referral.options.push('Other');
                }

                setRefferal({
                    title:response.data.referral.title,
                    options:response.data.referral.options,
                    loaded:true,
                });
            }
        }).catch((error) => {
            console.log(error);
        });
    }


    function onSelectRefferal(value){
        const parmas = new URLSearchParams();
        parmas.append("source",value);
        axios({
            method: 'post',
            url: baseURL + '/seller/source',
            data: parmas,
            headers: {
                'Authorization': userData.token,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if(response?.data?.success){
                setShow(false);
                if(onComplete && typeof onComplete === "function"){
                    onComplete();
                }
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setShow(false);
        });

    }


        return (
            <ModalPortal >
                <Modal centered show={!!show} onHide={()=>{setShow(false)}} size="sm" backdrop="static" keyboard={false}>
                    <Modal.Header closeButton >
                        {refferal.title}
                    </Modal.Header>
                    <Modal.Body>
                        {!otherOptionSelected && refferal.options.map((option,index)=>{
                            if(option === 'Other'){
                                return <ButtonOutlineBoder key={index} onClick={()=>setOtherOptionSelected(true)} key={option} className="py-2 mb-2"  label={option}/>
                            }
                           return <ButtonOutlineBoder key={index} onClick={()=>{onSelectRefferal(option);setOtherOptionSelected(false)}} key={option} className="py-2 mb-2"  label={option}/>
                        })}
                        {otherOptionSelected && <>
                            <input type="text" ref={otherOptionRef} className="form-control" placeholder="Your refferal source"/>
                            <ButtonOutlineBoder onClick={()=>{
                                if(otherOptionRef.current.value !== ""){
                                    onSelectRefferal(otherOptionRef?.current?.value ?? "Other");
                                }
                            }} className="py-2 mt-2"  label="Submit"/>
                        </>}
                    </Modal.Body>
                </Modal>
            </ModalPortal>
          )
    
}




export default HearRefferal