import axios from 'axios';
import { baseURL } from 'config';


/*
    "first_name": "",
    "last_name": "",
    "email": "",
    "phone": "",
    "zip_code": "",
*/


export function apiUploadProfile({token,first_name,last_name,
    email,phone,zip_code},callback=()=>{}){
    if(!token) throw new Error('token is required');
    let params = new URLSearchParams();
    params.append('first_name',first_name);
    params.append('last_name',last_name);
    params.append('email',email);
    phone && params.append('phone',phone);
    params.append('zip_code',zip_code);

        // log each param
    for (var pair of params.entries()) {
        console.log(pair[0]+ ', ' + pair[1]);
    }

    axios({
        method: "POST",
        url: baseURL + "/seller/profile",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': token
        },
        data: params
    }).then((request) => {
        callback(null,request.data);
    }).catch((error) => {
        callback(error,null);
    });
}