// @ts-nocheck
import { UserDataContext } from 'App';
import axios from 'axios';
import Loading from 'component/Loading';
import { baseURL } from 'config';
import React, { useContext, useEffect, useRef, useState, useCallback } from 'react'
import { Row, Col } from 'react-bootstrap'
import { OneImageBox } from './OneImageBox'
import { useDropzone } from "react-dropzone";



function  scriptFunction(){
    if(!window.ssrInitd){
        window.ssrInitd = true;
        console.log("ssaUrlInitd startline");
        var ssaUrl = 'https://' + 'clickserv.sitescout.com/conv/f632fa2cb7cb6d19';
            new Image().src = ssaUrl;
            (function(d) {
            var syncUrl = 'https://' + 'pixel.sitescout.com/dmp/asyncPixelSync';
            var iframe = d.createElement('iframe');
            (iframe.frameElement || iframe).style.cssText = "width: 0; height: 0; border: 0;";
            iframe.src = "javascript:false";
            d.body.appendChild(iframe);
            var doc = iframe.contentWindow.document;
            doc.open().write('<body onload="window.location.href=\''+syncUrl+'\'">');
            doc.close();
            })(document); 
        console.log("ssaUrlInitd endline");
    }

}

const ImageUploader = ({type,minBoxLimit}) => {
    const [imagesList, setImagesList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useContext(UserDataContext);
    const refUploadInput = useRef(null);
    const refContainerImagesList = useRef(null);
    const [minLimit,setMinLimit] = useState(minBoxLimit);


    useEffect(() => {
        fetchImages();
        if(window.innerWidth <= 768 && minLimit > 2){
            setMinLimit(2);
        }
    }, []);




    const onDrop = useCallback((acceptedFiles) => {
        // Do something with the files
        for (let i = 0; i < acceptedFiles.length; i++) {
            uploadOneImageAtTime(acceptedFiles[i]);
        }
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });


    function fetchImages() {
        if(!userData?.car) return ; // stop no car is set
        axios({
            method: "GET",
            url: baseURL + "/car/photos/" + userData.car.id,
            headers: {
                'Authorization': userData.token
            }
        }).then((response) => {
            let images = response.data;

            let imagesExtraStuff = images.map(i => { return { ...i, localId: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER) } });

            // switch cover_photo to first element
            let cover_photo = imagesExtraStuff.find(i => i.cover_photo === 1);

            if (cover_photo) {
                imagesExtraStuff.splice(imagesExtraStuff.indexOf(cover_photo), 1);
                imagesExtraStuff.unshift(cover_photo);
            }

            setImagesList(imagesExtraStuff);
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }
    function uploadeImage(e) {
        e.preventDefault();
        refContainerImagesList.scrollTop = 0; // scroll to top
        let listofFiles = e.target.files;
        for (let i = 0; i < listofFiles.length; i++) {
            uploadOneImageAtTime(listofFiles[i]);
        }
        e.target.value = '';
    }

    // upload image with axios and then update imagesList
    function uploadOneImageAtTime(file) {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("car_id", userData?.car?.id);
        // generate object url from file uploaded 
        let objectURL = URL.createObjectURL(file);
        let randomLocalId = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
        let obj = {
            localURL: objectURL,
            localId: randomLocalId,
            name: null,
            id: null,
            cover_photo: 0,
            progress: 0
        }

        setImagesList((p) => {
            return [obj, ...p];
        });

        axios({
            method: "POST",
            url: baseURL + "/seller/upload",
            headers: {
                'Authorization': userData.token
            },
            data: formData,
            onUploadProgress: progressEvent => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                //update progress of image
                setImagesList((p) => {
                    let index = p.findIndex(x => x.localId === randomLocalId);
                    p[index].progress = percentCompleted;
                    //if progress is 100% then after 1s update progress to -1
                    if (percentCompleted === 100) {
                        setTimeout(() => {
                            setImagesList((p) => {
                                let index = p.findIndex(x => x.localId === randomLocalId);
                                p[index].progress = -1;
                                return [...p];
                            });
                        }, 1000);
                    }

                    return [...p];
                });

            }
        }).then((response) => {
            //update imagesList
            setImagesList((p) => {

                let index = p.findIndex(x => x.localId === randomLocalId);
                p[index].name = response.data.photo_name;
                p[index].id = response.data.id;


                let coverImage = p.findIndex(x => x.cover_photo === 1);
                if (coverImage === -1) {
                    let findProgress100 = p.findIndex(x => x.progress === 100);
                    if (findProgress100 !== -1) {
                        p[findProgress100].cover_photo = 1;
                    }
                }

                return [...p];
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    // if is loading true show loading component
    if (isLoading) {
        return <Loading>
            Fetching images...
        </Loading>
    }
    //  condition that filles listimages minimum of minLimit images with empty images
    var imagesListShadow = [];
    if (imagesList.length < minLimit) {
        let emptyImages = [];
        for (let i = 0; i < minLimit - imagesList.length; i++) {
            emptyImages.push({ localId: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER) });
        }
        imagesListShadow = [...imagesList, ...emptyImages];
    } else {
        var imagesListShadow = [...imagesList];
    }

    return (
        <>
            <div className='file-uploader-container'>
                {type === "register" && <>
                    <div className='container'>
                            <h3>Congratulations!</h3>
                            <p className="text-muted">You have successfully posted your car. Please submit a few photographs using the buttons below to draw in higher-quality offers..</p>
                    </div>
                </>}
                <Row className="h-100">
                <div className="header-text-details-speed ms-4">Upload Photo</div>
                {type === "register" && <>
                    <img src="" onLoad={scriptFunction()} />
                    
                </>}
                
                    <div className='d-flex flex-column flex-wrap w-100 align-items-center'>
                        <input hidden {...getInputProps()} />
                        <input ref={refUploadInput} onChange={uploadeImage} id="image-uploader" hidden type='file' className='file-uploader-input' multiple />
                        <label {...getRootProps()} className='label-file-uploader'>
                            {isDragActive ? 'Drop the files here ...' : <>
                            <div>
                                <div><img src="/images/speed/upload.svg"/></div>
                                <div>Click or Drag image to <span style={{color:"#0176ac"}}>upload</span> Photo</div>
                            </div>
                            </>}
                        </label>
                    </div>
                    <Col  style={{ paddingBlock: "45px" }} className="col-12">
                        <div ref={refContainerImagesList} className="d-flex flex-wrap image-container-list scroll-as-height">
                            {imagesListShadow.map((item, index) => {
                                return <OneImageBox key={index} item={item} imagesList={imagesList} setImagesList={setImagesList} />
                            })}
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ImageUploader