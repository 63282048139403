import axios from "axios";
import { baseURL } from "config";


export function fetchMoreDataFromVin(vinValue,callback=()=>{}){
    let parmas = new URLSearchParams();
    parmas.append("vin", vinValue);
    axios({
        method: "POST",
        url: baseURL + "/seller/register/vin",
        data: parmas,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then((response) => {
        /*
            "success": 1,
    "vin_id": 913,
    "vin": "2B3KA53H67H604566",
    "year": "2007",
    "make": "Dodge",
    "model": "Charger",
    "trim": "R/T"
    {
        "success": 0,
        "error": "Invalid VIN, please verify it and try again",
        "message": "Invalid VIN"
    }
        */
        callback(null,response.data);
    }).catch((error) => {
        callback(error,null);
    });
}
