// @ts-nocheck

import Loading from '../../component/Loading';
import React, { useState, useEffect, useRef } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import '../../style/home_o2.css';
import TimerCountDown from './homecomponent/TimerCountDown';
import axios from 'axios';
import { useContext } from 'react';
import { UserDataContext } from '../../App';
import { baseURL, imageURL } from '../../config';
import HighestOffers from './homecomponent/HighestOffers';
import { useNavigate } from 'react-router-dom';
import ImageCarusel from './homecomponent/ImageCarusel';
import ButtonBoder from 'component/componenetInput/ButtonBoder';
import { ToastContainer, toast } from "react-toastify";
import InputBotBoder from 'component/componenetInput/InputBotBoder';
import LookingCar from 'component/LookingCar';
import ProgressCircle from './homecomponent/ProgressCircle';




const Home = () => {
    const [userData, setUserData] = useContext(UserDataContext);
    const lookingCarRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [auctionData, setAuctionData] = useState({
        autocheck_accidents: null,
        autocheck_owners: null,
        location: null,
        mileage: -1,
        auctionFetched: false,
        end_date: (new Date()).toISOString(),
    });
    const navigate = useNavigate();

    const [progressData, setProgressData] = useState([]);
    const [listImages, setListImages] = useState([]);
    const [modalVin, setModalVin] = useState(false);
    const useRefVin = useRef(null);
    const [isImagesLoading, setIsImagesLoading] = useState(true);

    useEffect(() => {
        getProgress();
        fetchDetails();
    }, []);

    function fetchCoverImage() {
        if(!userData?.car) return; // stop no car is set
        setIsImagesLoading(true);
        axios({
            method: "GET",
            url: baseURL + "/car/photos/" + userData.car.id,
            headers: {
                'Authorization': userData.token
            }
        }).then((response) => {
            setListImages(response.data);
            setIsImagesLoading(false);
        }).catch((error) => {
            console.log(error);
        });
    }

    const [titleCar, setTitleCar] = useState(null);
    function fetchDetails() {
        if(!userData?.car) return; // stop no car is set
        axios({
            method: "GET",
            url: baseURL + "/car/details/" + userData.car.id,
            headers: {
                'Authorization': userData.token
            }
        }).then((response) => {
            setTitleCar(response.data.title);
        });
    }
    function getProgress() {
        axios({
            method: "GET",
            url: baseURL + "/seller/progress",
            headers: {
                "Authorization": userData.token
            }
        }).then((response) => {
            setAuctionData(() => {
                fetchCoverImage();
                return {
                    ...auctionData,
                    ...response.data.auction,
                    auctionFetched: true,
                }
            });
            let progress = response.data.details;
            progress.shift();
            setProgressData(progress);
        }).catch((error) => {
            console.log("error:", error);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function saveVin() {
        let params = new URLSearchParams();;
        params.append("vin", useRefVin.current.value);
        axios({
            method: "POST",
            url: baseURL + "/seller/vin-kbb",
            headers: {
                "Authorization": userData.token,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: params
        }).then((response) => {
            console.log("response:", response.data);
            if (response.data.success === 1) {
                setModalVin(false);
                getProgress();
                fetchDetails();
                toast.success("Vin saved successfully", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });

            } else {
                toast.error("VIN not found!", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch((error) => {
            toast.error("VIN not saved, network error!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }


    if (isLoading)
        return <>
            <Loading />
        </>
    function autoCheckOwners() {
        let ownersCount = auctionData.autocheck_owners;
        if (ownersCount === null) {
            return "N/A";
        }
        return ownersCount;
    }
    function autoCheckAccidents() {
        let accidentsIssuesCount = auctionData.autocheck_accidents;
        let colorText = "no-color";
        if (accidentsIssuesCount === null) {
            return { count: "N/A", color: colorText }
        }

        if (accidentsIssuesCount === 0) {
            colorText = 'green';
            return { count: "No", color: colorText }
        }
        colorText = 'red';
        return { count: accidentsIssuesCount, color: colorText }
    }
    function autoCheckTitle() {
        let title = auctionData?.autocheck_assured;
        if (title === null) {
            return {
                count: "N/A",
                color: "no-color"
            };
        }
        if (title === "Y") {
            return {
                count: "No Issues",
                color: "green"
            };
        } else {
            return {
                count: "Issues",
                color: "red"
            };
        }
    }

    let autoCheckAccCount = autoCheckAccidents(); // return {count:number or "N/A", color: ["red","green","no-color"]}
    let autoCheckTitleCount = autoCheckTitle(); // return {count:number or "N/A", color: ["red","green","no-color"]}
    let allForProgress = { progressData, setModalVin, navigate, lookingCarRef, CircularProgressbar, buildStyles, LookingCar, getProgress };
    




    return (
        <>
            <Container fluid >
                <Row>
                    <Col xxl="9" >
                        <Row className='justify-content-end'>
                            <Col xxl="10">
                                <div className="car-location-text px-3">
                                    <div style={{ width: "fit-content" }}>
                                        <img src='./images/icons/location-01.svg' /> {auctionData.location ? auctionData.location : "N/A"}
                                    </div>
                                    <div style={{ width: "fit-content" }}>
                                        <span className="info-desk-column-text" >{auctionData.mileage === -1 ? "N/A" : auctionData.mileage.toLocaleString()}</span> miles
                                    </div>
                                </div>
                                <div className='car-image-cover'>
                                    <ImageCarusel listImages={listImages} isLoading={isImagesLoading} />
                                </div>
                                <Row>
                                    <Col>  <h5 className="car-header-text">{titleCar ? titleCar : "N/A"}</h5></Col>
                                </Row>
                                <hr />
                                <Row className='py-4 justify-content-center'>
                                    {auctionData.vin ? <>
                                        <div className='col-lg col-md-12 text-center'>
                                            <img src='./images/logo-autocheck.png' style={{ maxWidth: "min(90%, 280px)", maxHeight: "50px" }} />
                                        </div>
                                        <div className='col-4 col-sm-2'>
                                            <h5 className='info-header-column-text'>Owners:</h5>
                                            <span className="info-desk-column-text" >{autoCheckOwners()}</span>
                                        </div>
                                        <div className='col-4 col-sm-2'>
                                            <h5 className='info-header-column-text'>Accidents:</h5>
                                            <span className="info-desk-column-text" data-count={autoCheckAccCount.count} color={autoCheckAccCount.color}>{autoCheckAccCount.count > 0 ? "" : "No"} Issues</span>
                                        </div>
                                        <div className='col-4 col-sm-2'>
                                            <h5 className='info-header-column-text'>Title</h5>
                                            <span className="info-desk-column-text" color={autoCheckTitleCount.color}>{autoCheckTitleCount.count}</span>
                                        </div>

                                        <div className='col-lg col-md-12 text-center d-flex justify-content-center'>
                                            <div onClick={() => {
                                                window.open("https://www.autocheck.com/vehiclehistory/?siteID=8960&vin=" + auctionData.vin, "_blank");
                                            }} className='order-full-report-dw-con'>Order Full Report</div>

                                        </div>

                                    </> : <>
                                        <Row className='justify-content-center text-center'>
                                            <Col lg="4" md="12">
                                                <h5 className='info-header-column-text'>Not Available - Missing VIN</h5>
                                                <ButtonBoder onClick={() => { setModalVin(true) }} label="Add VIN - Get Free Report" />
                                            </Col>
                                        </Row>
                                    </>}
                                    <hr className='mt-4' />
                                </Row>
                            </Col>
                            <Col xxl="2" >
                                <ProgressCircle {...allForProgress} />
                            </Col>

                        </Row>
                    </Col>
                    <Col xxl="3" className="px-4" >
                        <TimerCountDown auction={auctionData} vin={auctionData.vin ? auctionData.vin : ''} />
                    </Col>
                </Row>
                <HighestOffers getProgress={getProgress} fetchDetails={fetchDetails} />
            </Container>

            <Modal show={modalVin} onHide={() => { setModalVin(false) }} centered>
                <Modal.Header closeButton>
                    <h5>Add VIN</h5>
                </Modal.Header>
                <Modal.Body>
                    <InputBotBoder ref={useRefVin} defaultValue={auctionData.vin ? auctionData.vin : ''} className='mb-4' label="VIN number" placeholder="VIN number" />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonBoder label="Continue" onClick={() => { saveVin() }} />
                </Modal.Footer>
            </Modal>


        </>
    )
}
export default Home