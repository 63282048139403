// @ts-nocheck
import React, { useContext, useEffect, useRef, useState } from 'react';
import Loading from '../Loading';
import ReciverMsg from './messageTypes/ReciverMsg';
import SenderMsg from './messageTypes/SenderMsg';
import ReciverMsgImage from './messageTypes/ReciverMsgImage';
import SenderMsgImage from './messageTypes/SenderMsgImage';
import UploadMsgFile from './messageFunctions/UploadMsgFile';
import axios from 'axios';
import { ProgressBar } from 'react-bootstrap';
import { baseURL, projectMode } from '../../config';
import { SocketDataContext, UserDataContext } from '../../App';
import ModalImage from 'component/componenetInput/ModalImage';
import { ImPhone } from '@react-icons/all-files/im/ImPhone';
import { BiDotsVerticalRounded } from '@react-icons/all-files/bi/BiDotsVerticalRounded';



const ChatMessageRaw = ({ loading, chatMessages, roomData, setChatData, selected }) => {
    const {socketData} = useContext(SocketDataContext);
    const [modalImageShow, setModalImageShow] = useState({
        show: false,
        image: ""
    }); // onclick image fullscreen
    const userData = useContext(UserDataContext)[0]; // userData 
    const textAreaRef = useRef(null); // textArea for sending message
    const bodyChat = useRef(null); // body to view chat messages
    const progressBarRef = useRef(null); // progressBar for imagew upload

    useEffect(()=>{
        const socket = socketData?.socket;
        if(!socket) return;
        socket.on("new-message",onNewMessage);
        return ()=>{
            socket.off("new-message",onNewMessage);
        }
    },[socketData?.socket,selected?.room_id]);


    function onNewMessage(data){
        const [name, message, message_room_id] = data.split("|");
        if(selected?.room_id !== parseInt(message_room_id)) return;
        setChatData((prevState) => {
            return {
                ...prevState,
                chatMessages: [...prevState.chatMessages, {
                    message_id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
                    generated_local: true,
                    message: message,
                    message_date: new Date().toISOString(),
                    file_name: null,
                    you: 0
                }]
            }
        });
    }

    // send button function
    function sendMessage() {
        if (textAreaRef.current.value === '') return;
        // get message 
        let messageToSend = textAreaRef.current.value;
        // clean up duty
        textAreaRef.current.value = '';
        // set params 
        let params = new URLSearchParams();
        params.append("room_id", roomData.room_id);
        params.append("message", messageToSend);
        // send request
        axios({
            method: "POST",
            url: baseURL + "/seller/message",
            data: params,
            headers: {
                'Authorization': userData.token,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if(projectMode === "DEV")
            console.log("Response from chat message:",response);
            if(setChatData)
            setChatData((prevState) => {
                return {
                    ...prevState,
                    chatMessages: [...prevState.chatMessages, {
                        message_id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
                        generated_local: true,
                        message: messageToSend,
                        message_date: new Date().toISOString(),
                        file_name: null,
                        you: 1
                    }]
                }
            });
        }).catch((error) => {
            console.log("response error:", error);
        });
    }

    function textAreaKeyDown(e) {
        if (e.key === "Enter") {
            e.preventDefault();
            sendMessage();
        }
    }


    useEffect(() => {
        //scoll chat to the bottom
        if (bodyChat && bodyChat.current)
            bodyChat.current.scrollTop = Number.MAX_SAFE_INTEGER;
    }, [chatMessages]);

    if (loading === true) {
        return <div className="d-flex align-items-center h-100 justify-content-center">
            <Loading>
                Loading chat messages...
            </Loading>
        </div>
    }

    if (typeof loading === 'undefined' || loading === null) {
        return <></> // void 
    }

    return (
        <>
            <div className='chat-header'>
                <div className='d-flex align-items-center w-100'>
                    <div className='img-no-image'> </div>
                    <div className='ms-3 d-flex justify-content-between align-items-center w-100'>
                        <div>
                            <div className="name-text-header">{selected?.dealer_name ?? ""}</div>
                            <div style={{color:"#9a9aa9"}}>{selected?.location ?? ""}</div>
                        </div>
                        <div>
                            <a className="anchor-no-decoration d-flex align-items-center" href={`tel:${selected?.dealer_phone ?? selected?.phone_number ?? selected?.phone ?? selected?.number ?? ""}`}>
                                <div>
                                    <div className="call-dealer-header">Call dealer:</div>
                                    <div className="phone-number">{selected?.dealer_phone ?? selected?.phone_number ?? selected?.phone ?? selected?.number ?? "N/A"}</div>
                                </div>
                                <div className='circle-icon me-3'><ImPhone/></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div ref={bodyChat} className='scroll-as-height pt-1'>
                {chatMessages.map((item) => {

                    if (item.file_name !== null && item.you === 0)
                        return <ReciverMsgImage modal={[modalImageShow,setModalImageShow]} key={item.message_id} item={item} />
                    else if (item.file_name !== null && item.you === 1)
                        return <SenderMsgImage modal={[modalImageShow,setModalImageShow]} key={item.message_id} item={item} />

                    if (item.you === 0)
                        return <ReciverMsg key={item.message_id} item={item} />
                    else
                        return <SenderMsg key={item.message_id} item={item} />

                })}
            </div>
            <div className="emoji-list-r" >

                <ModalImage setModalImageShow={setModalImageShow} image={modalImageShow.image} show={modalImageShow.show} onHide={() => { setModalImageShow((i) => { return { ...i, show: false } }) }} />

                <div className="progressUploadFile mb-1" style={{ display: "none" }}>
                    <ProgressBar striped variant="warning" ref={progressBarRef} />
                </div>
                <div className='chat-footer'>
                    <div className="d-flex align-items-center chat-input-container">
                        {/* File upload */}
                        <UploadMsgFile setChatData={setChatData}
                            // @ts-ignore
                            chatMessages={chatMessages} roomData={roomData} progressBarRef={progressBarRef} />

                            
                        <textarea maxLength={200} ref={textAreaRef} onKeyDown={textAreaKeyDown} className="form-control chat-text-input" placeholder="Write your message..."></textarea>


                        {/* Send Message */}
                        <div className='mx-2 send-msg-button-chat' onClick={sendMessage}>
                            <img alt='' src="./images/speed/send.svg" />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}


export const ChatMessage = React.memo(ChatMessageRaw)