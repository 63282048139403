



function ExitIcon(props) {
    return <>
    <svg width="33" height="33" {...props} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.688 11.6877L20.739 20.7387" stroke="#F04461" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.688 20.7386L20.739 11.6877" stroke="#F04461" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
</>
}

export default ExitIcon;