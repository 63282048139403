import { AiFillInfoCircle } from "@react-icons/all-files/ai/AiFillInfoCircle";







export function BreakDownPartCalculator({data}) {
    let salesTax = data?.calc?.taxRate * data?.calc?.price;
    return <div className="parent-sydnic-breakdown">
    <div className="sydnic-breakdown-hover">Breakdown
    <AiFillInfoCircle size={20}/>
    </div>
                    <div className="sydnic-breakdown-content">
        
      <div className="d-flex justify-content-between">
        <span>Car Price</span> 
        <span>${data?.calc?.price.toLocaleString()}</span>
    </div>

    {data?.calc?.needFinancing && <div className="d-flex justify-content-between" >
        <span>Down Payment</span>
        <span>${data?.calc?.downPayment.toLocaleString()}</span>
    </div>}

    {data?.calc?.hasTradeIn && <div className="d-flex justify-content-between">
        <span>Trade in value</span>
        <span>${data?.calc?.tradeInValue.toLocaleString()}</span>
    </div>}


    <div className="d-flex justify-content-between">
        <span>Estimated sales tax 
          <span>({(data?.calc?.taxRate*100).toFixed(2)} %)</span>
        </span>
        <span>+${Math.floor(salesTax).toLocaleString()}</span>
    </div>


    <div className="d-flex justify-content-between">
      <span>Other fees*</span><span>Not included</span>
    </div>

    <hr style={{margin:"5px 0px"}}/>
   {data?.calc?.needFinancing ? <>
    {typeof data?.calc?.totalDue !== 'undefined' &&  <div className="d-flex justify-content-between">
      <b>Loan Amount</b>
      <b>${Math.round(data?.calc?.totalDue).toLocaleString()}</b>
    </div>}
    {typeof data?.calc?.monthlyPayment !== 'undefined' && <div className="d-flex justify-content-between">
      <b>Monthly payment</b>
      <b>${Math.round(data?.calc?.monthlyPayment).toLocaleString()}</b>
    </div>}
    </> :  <div className="d-flex justify-content-between">
        <b>Total Due</b>
        <b>${Math.round(data?.calc?.totalDue).toLocaleString()}</b>
    </div>}
    </div>
    </div>
}

