
import ModalPortal from 'component/componenetInput/ModalPortal'
import Loading from 'component/Loading'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { ModalCarContext } from '../Context/ModalCarContext'
import { apiFetchCarDetails } from '../request/apiFetchCarDetails'
import PrimaryModal from './PrimaryModal'
import ExitIcon from 'component/componenetIcons/ExitIcon'
import SecondaryModal from './SecondaryModal'
import { sellerPersonalDetailsHandler } from 'component/WindowPostMessage.js';
import { UserDataContext } from 'App';
import { apiFetchTaxRateFromZipCode } from '../request/apiFetchTaxRateFromZipCode';

const ModalCar = (props) => {
  const { setCarSelected, carSelected } = props;
  const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
  const userData = useContext(UserDataContext)[0];
  useEffect(() => {
    dispatchModalCar({
      type: 'set', payload: {
        ...carSelected, details_loading: true,
        setCarSelected: setCarSelected,
        handleCloseModal: handleCloseModal,
        currentModal: "primaryModal"
      }
    });

    apiFetchCarDetails(carSelected.code, (error, data) => {
      if (error) throw error;
      dispatchModalCar({ type: 'set', payload: { ...data, details_loading: false } });
    });

    sellerPersonalDetailsHandler(userData.token, (error, data) => {
      console.log("data, error", data, error);
      if (error) throw error;
      let user_name = "";
      data?.first_name && (user_name += data?.first_name);
      data?.last_name && (user_name += " " + data?.last_name);
      dispatchModalCar({
        type: 'set', payload: {
          user_first_name: data?.first_name || "",
          user_last_name: data?.last_name || "",
          user_name: user_name,
          user_phone: data?.phone || "",
          user_email: data?.email || "",
          user_zip_code: data?.zip_code || ""
        }
      });

      if(data?.zip_code){
        apiFetchTaxRateFromZipCode(data?.zip_code, (error, data) => {
          if (error || data?.success === 0)  return;
          dispatchModalCar({ type: 'calc-update', payload: { 
            taxRate: data?.rate || 0 
          }});
        });
      }
    });
    

  }, []);

  function handleCloseModal() {
    setCarSelected(null);
  }
  return (
    <ModalPortal>
      <Modal show={setCarSelected} onHide={handleCloseModal} centered size="xl">
        {modalCar?.details_loading ? <Loading /> : <Modal.Body>

          <div className="position-relative">
            <span onClick={modalCar.handleCloseModal} style={{ 
              position: "absolute", top: "-15px", 
              right: "-15px", cursor: "pointer", 
              zIndex: "5",display:"flex",justifyContent:"center",alignItems:"center",
              boxShadow:"rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",borderRadius:"50%",
              background:"white" }}>
              <ExitIcon height="35px" width="35px" />
            </span>
          </div>

          {modalCar?.currentModal === "primaryModal" && <PrimaryModal />}
          {modalCar?.currentModal === "secondaryModal" && <SecondaryModal />}

        </Modal.Body>}
      </Modal>
    </ModalPortal>
  )
}

export default ModalCar