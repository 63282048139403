// import React from 'react'
import InputCalcSpeed from 'component/componenetInput/InputCalcSpeed';
import LongButtonModel from 'component/componenetInput/LongButtonModel'
import RadioBoder from 'component/componenetInput/RadioBoder';
import { ModalCarContext } from 'component/searchCars/Context/ModalCarContext';
import { useEffect, useState, useContext } from 'react';
import { Col } from 'react-bootstrap'

const TradeDetails = () => {
  const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
  const [form, setForm] = useState({
    carCondition: modalCar?.trade?.carCondition || "",
    currentMileage: modalCar?.trade?.currentMileage || "",
    tradeInValue: modalCar?.trade?.tradeInValue || "",
    tradeInLoanBalance: modalCar?.trade?.tradeInLoanBalance || ""
  });
  const [formError, setFormError] = useState({
    carCondition: "",
    currentMileage: "",
    tradeInValue: "",
    tradeInLoanBalance: ""
  });
  useEffect(() => {
    dispatchModalCar({
      type: "set-action", payload: {
        backButton: function () {
          dispatchModalCar({
            type: "update", payload: {
              currentSubModal: "tradeByType"
            }
          })
        }
      }
    });

    return () => {
      dispatchModalCar({
        type: "set-action", payload: {
          backButton: null
        }
      });
    }
  }, []);

  function handleChangeForm(e) {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
    setFormError({
      ...formError,
      [name]: ""
    });
  }


  function handleContinue() {
    let error = {};
    if (form.carCondition === "") {
      error.carCondition = "Please select car condition";
    }
    if (form.currentMileage === "") {
      error.currentMileage = "Please enter current mileage";
    }
    if (form.tradeInValue === "") {
      error.tradeInValue = "Please enter car worth";
    }
    if (form.tradeInLoanBalance === "") {
      error.tradeInLoanBalance = "Please enter car pay loan balance";
    }
    setFormError(error);
    if (Object.keys(error).length > 0) {
      return;
    }
    const { tradeInValue, tradeInLoanBalance,...restTradeInDetails } = form;
    dispatchModalCar({
      type: "trade-update", payload: {
        ...restTradeInDetails
      }
    });
    dispatchModalCar({
      type:"update", payload: {
        currentSubModal: "tradeImages",
      }
    });
    dispatchModalCar({
      type: "calc-update", payload: {
        tradeInValue: tradeInValue,
        tradeInLoanBalance: tradeInLoanBalance
      }
    })
  }

 



  return (
    <Col lg={6} xl={4} className="col-12 bordered-speed mt-4 d-flex justify-content-center flex-column">
      <div className="header-speed text-center">
        Trade-in Car Details
      </div>

      <InputCalcSpeed value={form.currentMileage} onChange={handleChangeForm} format="Number" invalid={formError.currentMileage} label="Current Mileage" name="currentMileage" placeholder="Enter current mileage" />

      <div className='input-calc-speed-label my-1'>Car condition</div>

      <RadioBoder name="carCondition" onChange={handleChangeForm} checked={form.carCondition === "1"} value="1" label="Excellent" description="Like new condition, no issues" />
      <RadioBoder name="carCondition" onChange={handleChangeForm} checked={form.carCondition === "2"} value="2" label="Good" description="Few minor defects,no major issues" />
      <RadioBoder name="carCondition" onChange={handleChangeForm} checked={form.carCondition === "3"} value="3" label="Fair" description="Possible major issues" />
      {formError.carCondition && <div>
        <div className='italic-info-error ps-1'>
          {formError.carCondition}
        </div>
      </div>}

      <InputCalcSpeed value={form.tradeInValue} onChange={handleChangeForm} format="Number" invalid={formError.tradeInValue} label="Car Worth" name="tradeInValue" placeholder="What do you think your car is worth?" />

      <InputCalcSpeed value={form.tradeInLoanBalance} onChange={handleChangeForm} format="Number" invalid={formError.tradeInLoanBalance} label="Approximate Loan Balance" name="tradeInLoanBalance" placeholder="Enter car loan balance" />

      <LongButtonModel colors={["#FFFFFF", "#B62949"]} className="mt-4"
        onClick={handleContinue} >Continue</LongButtonModel>
      <div className="d-flex justify-content-center">
        <div onClick={modalCar?.actions?.backButton} className="back-btn-tri">Go Back</div>
      </div>

    </Col>
  )
}

export default TradeDetails