import { UserDataContext } from 'App';
import axios from 'axios';
import UploadIcon from 'component/componenetIcons/UploadIcon';
import ButtonBoder from 'component/componenetInput/ButtonBoder';
import { ModalCarContext } from 'component/searchCars/Context/ModalCarContext';
import { baseURL } from 'config';
import { useContext, useState, useEffect, useRef } from 'react'
import { Col } from 'react-bootstrap'

const TradeImages = () => {
    const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
    const [userData, setUserData] = useContext(UserDataContext);
    const [files, setFiles] = useState([]);

    const fileRef = useRef(null);
    const refDropzone = useRef(null);

    const handleUpload = () => {
        fileRef.current.click();
    }

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        var listFiles = [];
        for (let i = 0; i < files.length; i++) {
            if (files[i].type.startsWith("image/")) {
                listFiles.push(files[i]);
            }
        }
        appendToState(listFiles);
    }

    function appendToState(listFiles){
        setFiles((prev) => {
            return [...prev, ...listFiles.map((item) => {
                let newImagesObj =  {
                    File: item,
                    local_id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
                    progress: 0,
                    status: "pending",
                };
                return newImagesObj
            })];
        });
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = 'copy';
    }

    const handleFileChange = (e) => {
        const files = e.target.files;
        var listFiles = [];
        for (let i = 0; i < files.length; i++) {
            if (files[i].type.startsWith("image/")) {
                listFiles.push(files[i]);
            }
        }
        e.target.value = '';
        appendToState(listFiles);   
    }



    const removeFile = (localIdSelected) => {
        setFiles((prev) => {
            return prev.filter((item) => item.local_id !== localIdSelected);
        });
    }


    const handleContinue = () => {
        dispatchModalCar({
            type: "update", payload: {
                currentSubModal: "calculator",
            }
        });
        dispatchModalCar({
            type: "calc-update", payload: {
                hasTradeIn: true,
            }
        });
        dispatchModalCar({
            type: "trade-update", payload: {
                images: files
            }
        });
    }


    return (
        <Col lg={12} xl={6} className="bordered-speed mt-4 d-flex justify-content-center flex-column">
            <div className="header-speed text-center">
                Trade-in Images
            </div>
            <div>
                <input onChange={handleFileChange} multiple ref={fileRef} type="file" hidden />
                <div style={{ color: "#535353", textAlign: "left", marginTop: "25px" }}>Upload photos (optional)</div>
                <div onDragOver={handleDragOver} onDrop={handleDrop} ref={refDropzone} onClick={handleUpload} className='upload-photo-tri-dropzone c-pointer'>
                    <UploadIcon width="96" height="96" />
                </div>
                <div className="d-flex justify-content-center">
                    <div className="c-pointer" style={{ color: "#A9A9A9", marginTop: "10px" }} onClick={handleUpload}>Drag & drop files or <b style={{ textDecoration: "underline", color: "#B72949" }}>Browse</b></div>
                </div>
                {files.length > 0 && <div className="my-3 d-flex flex-wrap justify-content-center">
                    {files.map((item, index) => {
                        return (
                            <div className="image-control-tri m-2" key={item.local_id}>
                                <span onClick={() => removeFile(item.local_id)} className="remove">x</span>
                                <img src={URL.createObjectURL(item.File)} alt="" />
                            </div>
                        );
                    })}
                </div>}
                <div style={{ minWidth: "35%", marginTop: "25px" }}>
                    <ButtonBoder onClick={handleContinue} label="Continue" className="py-2" style={{ background: "#B72949" }} />
                </div>
                <div className="d-flex justify-content-center">
                    <div onClick={() => { }} className="back-btn-tri">Go Back</div>
                </div>
            </div>
        </Col>

    )
}

export default TradeImages





// function apiUploadImage({
//     file, car_id, token,controller
// }, {
//     progressCallback = () => { },
//     doneCallback = () => { }
// }) {
//     const formData = new FormData();
//     formData.append("file", file);
//     formData.append("car_id", car_id);
//     axios({
//         method: "POST",
//         url: baseURL + "/seller/upload",
//         headers: {
//             'Authorization': token
//         },
//         data: formData,
//         signal: controller.signal,
//         onUploadProgress: function (progressEvent) {
//             let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
//             progressCallback(percentCompleted);
//         }
//     }).then((response) => {
//         doneCallback(null, response.data);
//     }).catch((error) => {
//         doneCallback(error);
//     });
// }


// function apiDeleteRequestImage({id,token},callback){
//     axios({
//         method: "DELETE",
//         url: baseURL + "/seller/car/photo/"+id,
//         headers: {
//             'Authorization': token
//         },
//     }).then((response)=>{
//         callback(null,response.data);
//     }).catch((error)=>{
//         callback(error);
//     });
// }