import { forwardRef, useState } from "react";



const formatter = {
    "phoneNumber": function (event) {
        let val = event.target.value.replace(/\D/g, "");
        const start =  event.target.selectionStart;
        const end =  event.target.selectionEnd;

        let formattedVal = "";
        if (val.length > 0) {
          formattedVal = `(${val.substring(0, 3)}`;
        }
        if (val.length > 3) {
          formattedVal += `) ${val.substring(3, 6)}`;
        }
        if (val.length > 6) {
          formattedVal += `-${val.substring(6, 10)}`;
        }
        event.target.setSelectionRange(start, end);
        return formattedVal;
    },
    "Number": function (event) {
        let val = event.target.value.replace(/\D/g, "");
        return val;
    }

}

export default forwardRef((props, ref) => {
    let { value,format,containerClassName, onChange, placeholder, className, invalid, pattern, patternError, label, icon, ...otherProps } = props;
    function handleChanges(e) {
        if(format){
            if(formatter[format]){
                e.target.value = formatter[format](e);
            }else {
                throw new Error("Format not found");
            }
        }
        onChange && onChange(e);
    }

    return <>
        <div className={containerClassName}>
            {label && <div className='input-calc-speed-label my-1'>{label}</div>}
            <div className="input-calc-speed">
                <input {...otherProps} ref={ref} pattern="" value={value} onChange={handleChanges} placeholder={placeholder} className={"input-no-decoration " + className} />
                {icon && <span className="px-2">{icon}</span>}
            </div>
            {invalid && <div>
                <div className='italic-info-error ps-1'>
                    {invalid}
                </div>
            </div>}
        </div>
    </>
});