/* eslint-disable */
export function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}
export function parseJSONWithDefault(jsonString, defaultValue) {
 
    try {
        return JSON.parse(jsonString);
    } catch (e) {
        return defaultValue;
    }
}

export function formatPhoneNumber(element) {
    const maskInput = (evt) => {
      const input = evt.target;
      const pattern = input.dataset.pattern;
      const prefix = input.dataset.prefix;
      let reg;
      let value;
      let startIndex = 0;
      let count = 0;
      let formatedValue = "";
  
      if (prefix) {
        startIndex = prefix.length;
        formatedValue += prefix;
        if (input.value.length < prefix.length) {
          input.value = prefix;
        }
      }
      if (prefix && prefix === input.value.slice(0, prefix.length)) {
        value = input.value.replace(prefix, "").replace(/[^\d]/g, "");
      } else {
        value = input.value.replace(/[^\d]/g, "");
      }
  
      for (let i = startIndex; i < pattern.length; i++) {
        if (value[count]) {
          if (pattern[i] != "*") {
            formatedValue += pattern[i];
          } else {
            formatedValue += value[count];
            count++;
          }
        }
      }
  
      input.value = formatedValue;
    };
  
    const onMaskedInputBlur = (evt) => {
      const input = evt.target;
      const prefix = input.dataset.prefix;
      if (prefix && input.value.length <= prefix.length) {
        input.value = "";
      }
    };
  
    function setMaskedInputListener(elem) {
      elem.addEventListener("input", maskInput);
    }
  
    setMaskedInputListener(element);
  }
  


  export function isInViewportVertical(element) {
    if(!element) return false;
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.bottom <= ((window.innerHeight + rect.height) || document.documentElement.clientHeight)
    );
}


// startline dualrangeSlider \\
export default class dualRangeSlider {
  constructor(rangeElement, { onChange, format }) {
    this.onChange = onChange;
    if (typeof format !== "function") format = (value) => value;
    this.format = format;
    this.range = rangeElement;
    this.min = Number(rangeElement.dataset.min);
    this.max = Number(rangeElement.dataset.max);
    this.handles = [...this.range.querySelectorAll(".handle")];
    this.startPos = 0;
    this.activeHandle;

    this.handles.forEach((handle) => {
      handle.addEventListener("mousedown", this.startMove.bind(this));
      handle.addEventListener("touchstart", this.startMoveTouch.bind(this));
    });

    window.addEventListener("mouseup", this.stopMove.bind(this));
    window.addEventListener("touchend", this.stopMove.bind(this));
    window.addEventListener("touchcancel", this.stopMove.bind(this));
    window.addEventListener("touchleave", this.stopMove.bind(this));

    const rangeRect = this.range.getBoundingClientRect();
    const handleRect = this.handles[0].getBoundingClientRect();

    this.range.style.setProperty("--x-1", "0px");
    this.range.style.setProperty("--x-2", "100%");

    this.handles[0].dataset.value = this.range.dataset.min;
    this.handles[1].dataset.value = this.range.dataset.max;
    this.formatValueOutput();
  }
  resetDefaultSlider() {
    this.range.style.setProperty("--x-1", "0px");
    this.range.style.setProperty("--x-2", "100%");
    this.handles[0].dataset.value = this.range.dataset.min;
    this.handles[1].dataset.value = this.range.dataset.max;
    this.onChangeFunction();
    this.formatValueOutput();
  }

  startMoveTouch(e) {
    const handleRect = e.target.getBoundingClientRect();
    this.startPos = e.touches[0].clientX - handleRect.x;
    this.activeHandle = e.target;
    this.moveTouchListener = this.moveTouch.bind(this);
    window.addEventListener("touchmove", this.moveTouchListener);
  }

  startMove(e) {
    this.startPos = e.offsetX;
    this.activeHandle = e.target;
    this.moveListener = this.move.bind(this);
    window.addEventListener("mousemove", this.moveListener);
  }

  moveTouch(e) {
    this.move({ clientX: e.touches[0].clientX });
  }

  move(e) {
    const isLeft = this.activeHandle.classList.contains("left");
    const property = isLeft ? "--x-1" : "--x-2";
    const parentRect = this.range.getBoundingClientRect();
    const handleRect = this.activeHandle.getBoundingClientRect();
    let newX = e.clientX - parentRect.x - this.startPos;
    if (isLeft) {
      const otherX = parseInt(
        this.range.style.getPropertyValue("--x-2") === "100%"
          ? this.range.offsetWidth
          : this.range.style.getPropertyValue("--x-2")
      );
      newX = Math.min(newX, otherX - handleRect.width);
      newX = Math.max(newX, 0 - handleRect.width / 2);
    } else {
      const otherX = parseInt(this.range.style.getPropertyValue("--x-1"));
      newX = Math.max(newX, otherX + handleRect.width);
      newX = Math.min(newX, parentRect.width - handleRect.width / 2);
    }
    this.activeHandle.dataset.value = this.calcHandleValue(
      (newX + handleRect.width / 2) / parentRect.width
    );
    this.range.style.setProperty(property, newX + "px");
    if (typeof this.onChange === "function") {
      this.onChangeFunction();
    }
    this.formatValueOutput();
  }
  onChangeFunction() {
    let startValue = parseInt(this.range.querySelector(".left").dataset.value);
    let endValue = parseInt(this.range.querySelector(".right").dataset.value);
    this.onChange(startValue, endValue);
  }
  formatValueOutput() {
    this.range.querySelector(".left").dataset.formatedValue = this.format(
      this.range.querySelector(".left").dataset.value,
      "left"
    );
    this.range.querySelector(".right").dataset.formatedValue = this.format(
      this.range.querySelector(".right").dataset.value,
      "right"
    );
  }

  calcHandleValue(percentage) {
    return Math.round(percentage * (this.max - this.min) + this.min);
  }

  stopMove() {
    window.removeEventListener("mousemove", this.moveListener);
    window.removeEventListener("touchmove", this.moveTouchListener);
  }
}
// endline dualrangeSlider \\
