import axios from "axios";
import { baseURL } from "config";

export function fetchMakerList(form,setListMaker) {
    let year = form.year;
    if (year === "") return;
    axios({
        method: "GET",
        url: baseURL + "/makes/" + year,
    }).then((response) => {
        let arrayModels = response.data;
        setListMaker(arrayModels);
    }).catch((error) => {
        console.log("error:", error);
        setListMaker([]);
    });
}
export function fetchModelMaker(form,setListModel) {
    let year = form.year;
    let maker = form.make;
    if (year === "" || maker === "") return;
    axios({
        method: "GET",
        url: baseURL + "/models/" + year + "," + maker,
    }).then((response) => {
        let arrayModels = response.data;
        setListModel(arrayModels);
    }).catch((error) => {
        console.log("error:", error);
        setListModel([]);
    });
}
export function fetchTrimsMakerList(form,setListTrim) {
    let year = form.year;
    let maker = form.make;
    let model = form.model;
    if (year === "" || maker === "" || model === "") return;
    axios({
        method: "GET",
        url: baseURL + "/trims/" + year + "," + maker + "," + model,
    }).then((response) => {
        let arrayModels = response.data;

        setListTrim(arrayModels);
    }).catch((error) => {
        console.log("error:", error);
        setListTrim([]);
    });
}