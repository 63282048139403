import { UserDataContext } from 'App';
import ButtonBoder from 'component/componenetInput/ButtonBoder';
import Loading from 'component/Loading';
import { imageURL } from 'config';
import React,{useEffect,useContext,useState} from 'react'
import { Carousel } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseURL } from 'config';

const ImageCarusel = () => {
    const [isLoading, setIsLoading] = useState(true);
    var [listImages, setListImages] = useState([]);
    const [isError, setIsError] = useState('');
    const [userData, setUserData] = useContext(UserDataContext);
    useEffect(() => {
        fetchCoverImage();
    },[]);
    function fetchCoverImage() {
        setIsError('');
        if(!userData?.car) return; // stop no car is set
        axios({
            method: "GET",
            url: baseURL + "/car/photos/" + userData.car.id,
            headers: {
                'Authorization': userData.token
            }
        }).then((response) => {
            setListImages(response.data);
        }).catch((error) => {
            console.log(error);
            setIsError("Retrying fetching images...");
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const navigate = useNavigate();
   


    let newBaseImage = '';
    let neededInsertButton = false;
    if (listImages.length === 0) {
        listImages = [{ name: "car_no_image.jpg" }];
        newBaseImage = './images/';
        neededInsertButton = true;
    } else {
        newBaseImage = imageURL;
    }

    if (isLoading) {
        return <Loading/>
    }


    let cover_photo = listImages.find(x => x.cover_photo === 1);
    if (cover_photo) {
        listImages.splice(listImages.indexOf(cover_photo), 1);
        listImages.unshift(cover_photo);
    }

    /*
    height: 100%;
    display: flex;
    align-items: center;
    background:"linear-gradient(90deg, rgb(0 0 0 / 15%) 0%,rgba(9,9,121,0) 25%, rgba(9,9,121,0) 75%, rgba(0,0,0,0.15) 100%)",
    */
    return (
        <>
            <Carousel indicators={false} style={{height:"100%",display:"flex",alignItems:"center"}}>
                {listImages.map((item, index) => {
                    return <Carousel.Item key={index} interval={6000}>
                        <img className="d-block w-100"
                            src={newBaseImage + listImages[index].name}
                            alt=''
                            style={{maxHeight: "400px",objectFit:"cover"}}
                        />
                        {neededInsertButton && isError === "" && <Carousel.Caption>
                            <ButtonBoder onClick={()=>{navigate("/car-details");}} className="w-50 button-background-maker" label="Upload Images" />
                        </Carousel.Caption>}
                        {isError !== "" &&   <Carousel.Caption style={{marginBottom:"50px",color:"#f20000",background:"#ffffff60",borderRadius:"10px"}}>{isError}</Carousel.Caption>}
                    </Carousel.Item>
                })}
            </Carousel>
        </>
    )
}

export default ImageCarusel
/*
    background: linear-gradient(90deg, rgb(0 0 0 / 15%) 0%,rgba(9,9,121,0) 25%, rgba(9,9,121,0) 75%, rgba(0,0,0,0.15) 100%);
*/