import ModalPortal from 'component/componenetInput/ModalPortal';
import { Modal } from 'react-bootstrap';
import React, {useState,useContext} from 'react'
import {GoAlert} from '@react-icons/all-files/go/GoAlert';
import ButtonBoder from 'component/componenetInput/ButtonBoder';
import ButtonOutlineBoder from 'component/componenetInput/ButtonOutlineBoder';
import axios from 'axios';
import { baseURL } from 'config.js';
import { UserDataContext } from 'App';

const ModalConfirm = ({configModal,state ,reload}) => {
    const userData = useContext(UserDataContext)[0];
    const [loading,setLoading] = useState(false);
    const [modalStatus,setModalStatus] = useState({
        show: false,
        ...configModal
    });

    function handleCloseModal() {
        setModalStatus({
            ...modalStatus,
            show: false
        })
    }

    function handleOpenModal(){
        setModalStatus({
            ...modalStatus,
            show: true
        })
    }


    function requestRemoveTradeIn(){
        setLoading(true);
        axios({
            method: 'DELETE',
            url: `${baseURL}/seller/negotiation/${state.code}/trade`,
            headers:{
                'Authorization': userData.token
            }
        }).then((res) => {
            /// console.log("res.data removed:",res.data);
            /// TODO
            reload();
            // handleCloseModal();
        }).finally(() => {
            setLoading(false);
        });
   
    }

    return (
        <>
            <div style={{color:"#b50000"}} onClick={handleOpenModal}>Remove Trade In</div>
            <ModalPortal>
                <Modal show={modalStatus.show} onHide={handleCloseModal} centered>
                    <Modal.Body>
                        <div className="d-flex justify-content-center mt-3"><GoAlert size={60} color="#B72949"/></div>
                        <div className='text-center my-3'>
                            Are you sure you want to remove this trade in?
                        </div>
                        <div className="d-flex g-3">
                                <ButtonBoder onClick={requestRemoveTradeIn} className="py-2 mx-2" label="Remove"/>
                                <ButtonOutlineBoder className="py-2 mx-2" label="Cancel" onClick={handleCloseModal} />
                        </div>
                    </Modal.Body>
                </Modal>
            </ModalPortal>
        </>
    )
}





export default ModalConfirm