import axios from 'axios';
import ButtonBoder from 'component/componenetInput/ButtonBoder';
import SelectBotBoder from 'component/componenetInput/SelectBotBoder';
import { baseURL } from 'config';
import React, { useEffect, useState } from 'react'
import { fetchTrimsMakerList, fetchModelMaker, fetchMakerList } from '../request/PathManualRequest';
import ExtendCarDeatils from './ExtendCarDeatils';
import { ContextForm } from 'component/NoCar/contexts/FormContext';
const PathManual = () => {
    const {formRegister,appendFormRegister,editFormRegister} = React.useContext(ContextForm);
    const [form, setForm] = useState({
        year: formRegister.year ?? "",
        make: formRegister.make ?? "",
        model: formRegister.model ?? "",
        trim: formRegister.trim ?? "",
    });

    const [listMaker, setListMaker] = useState([]);
    const [listModel, setListModel] = useState([]);
    const [listTrim, setListTrim] = useState([]);
    const [formError, setFormError] = useState({});

    useEffect(() => {
        if (form.year === "") return;
        fetchMakerList(form, setListMaker);
        if (form.make === "") return;
        fetchModelMaker(form, setListModel);
        if (form.model === "") return;
        fetchTrimsMakerList(form, setListTrim);
    }, [form]);

    function validateForm() {
        let error = {};
        if (form.year === "") error.year = "Year is required";
        if (form.make === "") error.make = "Make is required";
        setFormError(error);
        if (Object.keys(error).length > 0) return false;
        return true;
    }

    function handleSubmit() {
        if (!validateForm()) return;
        appendFormRegister(form);
        editFormRegister("stepType","detail");
    }

    let arrayYearList = Array.from({ length: ((new Date()).getFullYear() + 1) - 1990 }).map((item, index) => 1990 + index);
    arrayYearList.reverse();
    return (
        <>
            <div className="my-3">
                <SelectBotBoder invalid={formError.year} defaultValue={form.year} onChange={(e) => { setForm((i) => { return { ...i, year: e.target.value } }) }} label="Select Year" placeholder="Select Year" labelOptions={arrayYearList} valueOptions={arrayYearList} />
            </div>
            <div className="my-3">
                <SelectBotBoder invalid={formError.make} defaultValue={form.make} disabled={form.year === ""} onChange={(e) => { setForm((i) => { return { ...i, make: e.target.value } }) }} label="Select Make" placeholder="Select Make" labelOptions={listMaker} valueOptions={listMaker} />
            </div>
            <div className="my-3">
                <SelectBotBoder invalid={formError.model} defaultValue={form.model} disabled={form.make === ""} onChange={(e) => { setForm((i) => { return { ...i, model: e.target.value } }) }} label="Select Model" placeholder="Select Model" labelOptions={listModel} valueOptions={listModel} />
            </div>
            <div className="my-3">
                <SelectBotBoder invalid={formError.trim} defaultValue={form.trim} disabled={form.model === ""} onChange={(e) => { setForm((i) => { return { ...i, trim: e.target.value } }) }} label="Select Trim" placeholder="Select Trim" labelOptions={listTrim} valueOptions={listTrim} />
            </div>
            <ButtonBoder onClick={handleSubmit} label="Next" className="mt-3" />
        </>
    )
}

export default PathManual