import React from 'react'
import { useTransition } from 'react';
import { useEffect, useState } from 'react';

const SenderMsg = ({ item, animate = false }) => {
  const [pending, startTransition] = useTransition();
  const [fadeIn, setFadeIn] = useState({
    transition: `all 0.1s ease-in-out`,
    transform: `translateX(-10px)`,
    opacity: 0
  });

  useEffect(() => {
    startTransition(() => {
        setFadeIn((pState) => {
          return { ...pState, opacity: 1, transform: `translateX(0px)` };
        });
    });
  }, []);
  var msg = '';

  if (item.message.includes('http')) {
    msg = item.message.replace(/(https?:\/\/[^\s]+)/g, '<a target="_blank" href="$1">$1</a>');
  }else{
    msg = item.message;
  }


  return (
    <div style={fadeIn} className='sender-msg'>
      <span dangerouslySetInnerHTML={{ __html: msg }} time={(new Date(item.message_date)).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: "numeric", minute: "numeric" })} className='sender-msg-text'>
      </span>
    </div>
  )
}

export default SenderMsg