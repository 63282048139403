import LongButtonModel from 'component/componenetInput/LongButtonModel';
import { Col } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { ModalCarContext } from 'component/searchCars/Context/ModalCarContext';
import { baseURL } from 'config';
import InputCalcSpeed from 'component/componenetInput/InputCalcSpeed';
import { apiFetchVinData } from 'component/searchCars/request/apiFetchCheckVin';




const TradeByType = () => {
    const { modalCar, dispatchModalCar } = useContext(ModalCarContext);

    useEffect(() => {
        dispatchModalCar({
            type: "set-action", payload: {
                backButton: function () {
                    dispatchModalCar({
                        type: "update", payload: {
                            currentModal: "secondaryModal",
                            currentSubModal: "calculator"
                        }
                    });
                }
            }
        });
        return () => {
            dispatchModalCar({
                type: "set-action", payload: {
                    backButton: null
                }
            });
        }
    }, []);

    function handleVinNumberSelected(){
        dispatchModalCar({
            type: "update", payload: {
                currentSubModal: "tradeByVin"
            }
        });
    }

    function handleLicensePlateSelected(){
        dispatchModalCar({
            type: "update", payload: {
                currentSubModal: "tradeByLicensePlate"
            }
        });
    }
    function handleManualSelected(){
        dispatchModalCar({
            type: "update", payload: {
                currentSubModal: "tradeByManual"
            }
        });
    }

    // return <SubModalVinNumberSetter/>
    return (
        <Col lg={6} xl={4} className="col-12 bordered-speed mt-4 d-flex justify-content-center flex-column">
            <div className="header-speed text-center">
                Trade-in by
            </div>
            <p className='text-center'>Add your trade-in by using</p>
            <div className='d-flex flex-column justify-content-center align-content-center'>
                <LongButtonModel onClick={handleVinNumberSelected} colors={["#FFFFFF", "#B72949"]} className="my-2 border-theme">VIN Number</LongButtonModel>
                <LongButtonModel onClick={handleLicensePlateSelected} colors={["#FFFFFF", "#B72949"]} className="my-2 border-theme">License Plate</LongButtonModel>
                <label className="my-2 text-center">Don't have a license plate or a VIN?</label>
                <div onClick={handleManualSelected} className="btn-tri-text-underline text-center">List car manually</div>
                <div className="d-flex justify-content-center">
                    <div onClick={modalCar?.actions?.backButton} className="back-btn-tri">Go Back</div></div>
            </div>
        </Col>
    )
}

export default TradeByType


