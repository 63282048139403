import { useContext, useEffect, useState } from 'react'
import { AiOutlineLine } from "@react-icons/all-files/ai/AiOutlineLine";
import { NavLink, useLocation } from 'react-router-dom';
import { UserDataContext } from 'App';
import axios from 'axios';
import { baseURL } from 'config';

const SideBarStar = () => {
    const userData = useContext(UserDataContext)[0];
    const [showBuyingMenu, setShowBuyingMenu] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if(!showBuyingMenu){
            ApiRequestHasNegotiations({token:userData.token},(responseHasNegotiations)=>{
                setShowBuyingMenu(responseHasNegotiations);
            });
        }
    }, [location]);

    return (
        <div className='sidebar-star-container'>
            <div className="side-bar-star">

                <NavLink to="/home" className={({ isActive }) => `navlink-star ${(isActive ? 'active' : 'inactive')}`}>
                    <img src="/images/speed/home.svg" />
                    <div className='nav-link-menu-text'>Home</div>
                </NavLink>

                {userData?.car && <>
                    <NavLink to="/messages" className={({ isActive }) => `navlink-star ${(isActive ? 'active' : 'inactive')}`}>
                        <img src="/images/speed/chat.svg" />
                        <div className='nav-link-menu-text'>Messages</div>
                    </NavLink>

                    <NavLink to="/car-details" className={({ isActive }) => `navlink-star ${(isActive ? 'active' : 'inactive')}`}>
                        <img src="/images/speed/car.svg" />
                        <div className='nav-link-menu-text'>Selling</div>
                    </NavLink>
                </>}

                <div>
                    <AiOutlineLine size="40" color="#aaa" />
                </div>
                <NavLink to="/car-search" className={({ isActive }) => `navlink-star ${(isActive ? 'active' : 'inactive')}`}>
                    <img src="/images/speed/key.svg" />
                    <div className='nav-link-menu-text'>Buy a Car</div>
                </NavLink>
                {showBuyingMenu && <NavLink to="/negotiations" className={({ isActive }) => `navlink-star ${(isActive ? 'active' : 'inactive')}`}>
                    <img src="/images/speed/shield.svg" />
                    <div className='nav-link-menu-text'>Buying</div>
                </NavLink>}
            </div>
        </div>
    )
}



function ApiRequestHasNegotiations({token},cb){
    axios({
        method: "GET",
        url: baseURL+"/seller/negotiations",
        headers: {
            Authorization: token
        }
    }).then((res)=>{
        if(res?.data?.length > 0){
            cb(true);
        }else{
            cb(false);
        }
    }).catch((err)=>{
        console.log(err)
    });
}


export default SideBarStar