import { baseURL } from "config";
import axios from "axios";


 export function fetchMakerList(year,cb) {
    axios({
        method: "GET",
        url: baseURL + "/makes/" + year,
    }).then((response) => {
        cb(null,response.data);
    }).catch((error) => {
        cb(error,null);
    });
}
export function fetchModelMaker(year,maker,cb) {
    axios({
        method: "GET",
        url: baseURL + "/models/" + year + "," + maker,
    }).then((response) => {
        cb(null,response.data);
    }).catch((error) => {
        cb(error,null);
    });
}
export function fetchTrimsMakerList(year,maker,model,cb) {
    axios({
        method: "GET",
        url: baseURL + "/trims/" + year + "," + maker + "," + model,
    }).then((response) => {
        cb(null,response.data);
    }).catch((error) => {
        cb(error,null);
    });
}
