import React from 'react'

const LongButtonModel = (props) => {
 let {disabled,primary,background,className,secondary,colors,children,style} = props;
 [primary,secondary] = colors || [primary,secondary]; 
 primary ??= "#000000";
 secondary ??= background ?? "#959595";
 style ??= {};

 let { onClick } = props;

  return (
    <div disabled={disabled} onClick={onClick} role="button" className={"long-btn-speed "+className} style={{"--color":primary,"--background-color":secondary,...style}}>
        {children}
    </div>
  )
}

export default LongButtonModel