import React,{useReducer,createContext} from 'react'
import { useEffect } from 'react';


export const FilterMainContext = createContext();


const initFilterValues = {
    search_keywords: "",
    price_min: 0,
    price_max: 500_000,
    mileage_min: 0,
    mileage_max: 200_000,
    year_min: 1980,
    year_max: (new Date().getFullYear())+1,
    make:[],
    color:[],
    model:[],
    dealersNames: [],
}

const filterReducerStater = (state, newState) =>{
    switch (newState) {
        case "resetDefault":
            return {...initFilterValues};
        default:
            return { ...state, ...newState }
    }
};

const FilterContext = ({children}) => {
    const [filterReducer, setFilterReducer] = useReducer(filterReducerStater,initFilterValues);
     
    function isDefault(key){
        return JSON.stringify(filterReducer[key]) === JSON.stringify(initFilterValues[key]);
    }
    function getChangedKeysFromDefault(){
        let changedKeys = [];
        for (const key of Object.keys(filterReducer)){
            if(!isDefault(key)){
                changedKeys.push(key);
            }
        }
        return changedKeys;
    }
    
  return (
    <FilterMainContext.Provider value={{filterReducer,setFilterReducer,getChangedKeysFromDefault,isDefault}}>
        {children}
    </FilterMainContext.Provider>
  )
}

export default FilterContext