// @ts-nocheck
import {forwardRef} from 'react'

const TextAreaBoder = forwardRef((props, ref) => {
        var { label, placeholder,children, ...otherAttributes } = props;
        return (
            <>
                {label  && <label className="input-bot-label">{label}</label>}
                <textarea placeholder={placeholder} ref={ref} {...otherAttributes} className='textarea-boder-input'></textarea>
                {children}
            </>
        )
});


    

export default TextAreaBoder