import {useContext, useEffect} from 'react'
import { ModalCarContext } from '../Context/ModalCarContext';
import { AiFillInfoCircle } from '@react-icons/all-files/ai/AiFillInfoCircle';
import LongButtonModel from 'component/componenetInput/LongButtonModel'
import CarouselAutoCheck from '../compontentSearch/CarouselAutoCheck';
import { Col, Row } from 'react-bootstrap';


const PrimaryModal = () => {
    const {modalCar, dispatchModalCar} = useContext(ModalCarContext);
    function handleMakeAnOffer(){
        dispatchModalCar({type: 'set', payload: {
            currentModal: 'secondaryModal',
            currentSubModal: "yourAccount"
        }});
    }
    function handleSubTestDrive(){
        dispatchModalCar({type: 'set', payload: {
            currentModal: "secondaryModal",
            currentSubModal: "testDrive"
        }});
    }
    function handleSubMoreInfo(){
        dispatchModalCar({type: 'set', payload: {
            currentModal: "secondaryModal",
            currentSubModal: "moreInfo"
        }});
    }

  return (
    <>
          <div>
            <Row>
              <Col xl={6}>
                {modalCar.photos && <CarouselAutoCheck carDetails={modalCar} removelimits={false} />}
              </Col>
              <Col xl={6} className="p-md-2 pe-md-4">
                <div className="d-flex">
                  <div className="col-12 justify-content-between d-flex">
                    <div className='header-title-speed-modal'>{modalCar.title}</div>
                    <div className="flex-grow-1 flex-shrink-0">
                      <div className='asking-price'>Asking price:</div>
                      <div className='header-title-speed-price'>
                        {(modalCar?.price == 0 ? "Contact Us" : "$"+modalCar?.price?.toLocaleString())}
                      </div>
                    </div>
                  </div>
                </div>


                <div>
                  <div className="d-flex justify-content-between mt-3">
                    <span className='row-header-speed d-flex align-items-center'>Dealership Name</span>
                    <span className='row-info-speed'>{modalCar.dealer_name}</span>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <span className='row-header-speed d-flex align-items-center'>Location</span>
                    <span className='row-info-speed'>{modalCar.location}</span>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <span className='row-header-speed d-flex align-items-center'>Dealer Phone Number</span>
                    <a href={`tel:${modalCar.dealer_phone}`} className='anchor-no-decoration row-info-speed color-theme'>
                      {modalCar.dealer_phone}
                      <img className="ms-2" src="./images/icons/phoneCircle.svg" alt="" />
                    </a>
                  </div>
                 <div className='text-center'>
                 {modalCar?.msg?.type === "success" && <span className="msg-success-speed" role="alert">
                    {modalCar.msg.message}
                  </span>}
                 </div>
                  <LongButtonModel onClick={handleSubMoreInfo} className="mt-3" colors={["#B62949", "#F4DFE4",]}>
                    <AiFillInfoCircle size={25} className="me-1" /> Request More Info
                  </LongButtonModel>

                  <LongButtonModel onClick={handleSubTestDrive} className="mt-2" colors={["#fff", "#0176AC",]}>
                    <img src="./images/icons/calendarIcon.svg" alt="" className="me-1" />
                    Schedule Test Drive
                  </LongButtonModel>

                  <LongButtonModel onClick={handleMakeAnOffer} className="mt-2" colors={["#fff", "#B62949",]}>
                    <img src="./images/icons/handShake.svg" alt="" className="me-1" />
                    Make an Offer
                  </LongButtonModel>
                </div>
              </Col>


              <Col md={12}>
                <Row className="px-xl-3">
                  <Col xl={4} >
                    <div className="d-flex justify-content-between my-4">
                      <span className='row-header-speed d-flex align-items-center'>Mileage</span>
                      <span className='row-info-speed'>{modalCar?.mileage}</span>
                    </div>
                    <div className="d-flex justify-content-between my-4">
                      <span className='row-header-speed d-flex align-items-center'>Vin</span>
                      <span className='row-info-speed'>{modalCar?.vin || "Not Provided"}</span>
                    </div>
                  </Col>
                  <Col xl={4}>
                    <div className="d-flex justify-content-between my-4">
                      <span className='row-header-speed d-flex align-items-center'>Stock #</span>
                      <span className='row-info-speed'>{modalCar?.stock_number || "Not Provided"}</span>
                    </div>
                    <div className="d-flex justify-content-between my-4">
                      <span className='row-header-speed d-flex align-items-center'>Make</span>
                      <span className='row-info-speed'>{modalCar?.make || "Not Provided"}</span>
                    </div>
                  </Col>
                  <Col xl={4} >
                    <div className="d-flex justify-content-between my-4">
                      <span className='row-header-speed d-flex align-items-center'>Model</span>
                      <span className='row-info-speed'>{modalCar?.model_name || "Not Provided"}</span>
                    </div>
                    <div className="d-flex justify-content-between my-4">
                      <span className='row-header-speed d-flex align-items-center'>Trim</span>
                      <span className='row-info-speed'>{modalCar?.trim_name || "Not Provided"}</span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className='px-xl-3'>
                    <div className="main-info-speed">
                      <img className="m-3" src="./images/icons/EngineIcon.svg" alt="" />
                      <div className="main-info-text">
                        <div>Engine</div>
                        <div>{modalCar?.engine || "N/A"}</div>
                      </div>
                    </div>
                  </Col>
                  <Col className='px-xl-3'>
                    <div className='main-info-speed'>
                      <img className="m-3" src="./images/icons/DriveTypeIcon.svg" alt="" />
                      <div className="main-info-text"> 
                        <div>Drive Type</div>
                        <div>{modalCar?.driven_wheels || "N/A"}</div>
                      </div>
                    </div>
                  </Col>
                  <Col className='px-xl-3'>
                    <div className='main-info-speed'>
                      <img className="m-3" src="./images/icons/VehicleTypeIcon.svg" alt="" />
                      <div className="main-info-text">
                        <div>Vehicle Type</div>
                        <div>{modalCar?.vehicle_type || "N/A"}</div>
                      </div>
                    </div>
                  </Col>
                  <Col className='px-xl-3'>
                  <div className='main-info-speed'>
                      <img className="m-3" src="./images/icons/TransmissionIcon.svg" alt="" />
                      <div className="main-info-text">
                        <div>Transmission</div>
                        <div>{modalCar?.transmission_type || "N/A"}</div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>


            </Row>
          </div>
          </>
  )
}

export default PrimaryModal