// @ts-nocheck
import axios from 'axios';
import { baseURL } from 'config';
import React, { useRef, useState } from 'react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import ButtonBoder from './componenetInput/ButtonBoder';
import InputBotBoder from './componenetInput/InputBotBoder';
import InputCalcSpeed from './componenetInput/InputCalcSpeed';

function OneTimeCode({ setHasSuccess, setHasError }) {
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');

    function submitWithResetCode(e) {
        e.preventDefault();
        let params = new URLSearchParams();
        if (code === "") {
            setHasError("Please enter code");
            return;
        } else if (password === "") {
            setHasError("Please enter password");
            return;
        }

        params.append("code", code);
        params.append("password", password);
        setHasError("");
        setHasSuccess("");
        axios({
            method: "POST",
            url: baseURL + "/password/reset/confirm",
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            let data = response.data;

            if (data.success === 1) {
                setHasSuccess("Password has been reset");
            } else {
                setHasError("Invalid code!");
            }
        }).catch(() => {
            setHasError("Something went wrong, or check network!");
        });
    }
    return <>
        <div className="my-3">
            <InputBotBoder value={code} onChange={(e) => { setCode(e.target.value) }} label="Code" placeholder="Code" />
        </div>
        <div className="my-3">
            <InputBotBoder value={password} onChange={(e) => { setPassword(e.target.value) }} type="password" label="Password" placeholder="New Password" />
        </div>
        <div className='text-nodecorate'>
            <Link to="/"> Back to Log In? </Link>
        </div>
        <div>
            <ButtonBoder onClick={submitWithResetCode} label="SUBMIT" />
        </div>
    </>
}

export default function ForgetPassword({ includedCode }) {
    const [view, setView] = useState("ForgetPassword"); // ForgetPassword, OneTimeCode
    const [hasError, setHasError] = useState("");
    const [hasSuccess, setHasSuccess] = useState(false);
    var refEmail = useRef(null);

    function submitResetCode(e) {
        e.preventDefault();
        if (!refEmail.current.value || refEmail.current.value == "") return;
        const params = new URLSearchParams()
        params.append("email", refEmail.current.value);
        setHasError("");
        setHasSuccess("");
        axios({
            method: "post",
            url: baseURL + "/seller/password/reset",
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((request) => {
            if (request.data.success === 0) {
                setHasError("Something went wrong!");
            } else {
                setHasSuccess("Code sent, please check your email inbox or spam folder!");
            }
        }).catch((error) => {
            setHasError("Something went wrong!");
        }).finally(() => {
            refEmail.current.value = '';
        });
    }


    return (
        <div className='container mt-5'>
            <div className="mt-5">
                <Form className='d-flex justify-content-center'>
                    <div className='col-12 col-md-4'>
                        <div className='text-center mt-5' >
                            <img alt="autos today logo no transparent" className="my-2" src="/images/speed/autos-today-logo.png" />
                        </div>



                        <div className="box-register pt-5">
                            {hasError !== "" && <div className="error-message">{hasError}</div>}
                            {hasError === "" && hasSuccess && <div className='bg-color-success rounded text-center text-white py-2'>{hasSuccess}</div>}
                            {view === "ForgetPassword" && <>

                                {/* <InputBotBoder ref={refEmail} label="Email" label="Email" /> */}
                                <InputCalcSpeed  ref={refEmail} label="Email" label="Email" placeholder="Enter Your Address Email..." />

                                <div className='text-nodecorate mb-0'>
                                    <Link to="/"> Back to Log In? </Link>
                                </div>
                                <button type="submit" onClick={submitResetCode} className='danger-button mt-3'>GET CODE</button>
                                <div className='text-nodecorate'>
                                    <a onClick={(e) => {
                                        e.preventDefault();
                                        setView("OneTimeCode");
                                    }} href=''>Have a code?</a>
                                </div>
                            </>}
                            {view === "OneTimeCode" && <OneTimeCode setHasError={setHasError} setHasSuccess={setHasSuccess} />}
                        </div>

                    </div>
                </Form>
            </div>
        </div>
    )
}
