import LongButtonModel from 'component/componenetInput/LongButtonModel';
import { Col } from 'react-bootstrap';
import { useContext,  useEffect,  useState } from 'react';
import { ModalCarContext } from 'component/searchCars/Context/ModalCarContext';
import { baseURL } from 'config';
import InputCalcSpeed from 'component/componenetInput/InputCalcSpeed';
import { apiFetchVinData } from 'component/searchCars/request/apiFetchCheckVin';

export default function TradeVinNumber() {
    const { modalCar, dispatchModalCar } = useContext(ModalCarContext);

    let contextTrade = modalCar?.trade || {};
    contextTrade = contextTrade?.tradeType == "vin" ? contextTrade : {};
    const [vinData, setVinData] = useState({...contextTrade});
    const [error, setError] = useState({
        visible: false,
        message: ""
    });
    useEffect(() => {
        dispatchModalCar({
            type: "set-action", payload: {
                backButton: function () {
                    dispatchModalCar({
                        type: "update", payload: {
                            currentModal: "secondaryModal",
                            currentSubModal: "tradeByType"
                        }
                    });
                }
            }
        });
        return () => {
            dispatchModalCar({
                type: "set-action", payload: {
                    backButton: null
                }
            });
        }
    }, []);
    function handleOnChange(e) {
        const vin = e.target.value;
        setVinData({ vin });
        setError({
            visible: false,
            message: "Please wait while we check your vin number"
        });
        dispatchModalCar({type: "update", payload: {msg:{type: null,message: ""}}});

        apiFetchVinData(vin, (err, data) => {
            setError({visible:false,message: ""});
            if(err || data?.success == 0){
                setError({
                    visible: false,
                    message: data?.error ?? "Vin number is not valid, please try again"
                });
                return;
            }
            const { success , ...restData } = data;
            setVinData({tradeType:"vin",vin, ...restData});
        });
    }

    function handleContinueButton(){
        if(!vinData?.vin){
            dispatchModalCar({
                type: "update", payload: {
                    msg:{
                        type: "error",
                        message: "Please enter a valid vin number"
                    }
                }
            });
            return;
        }
        if(error.message !== ""){
            dispatchModalCar({
                type: "update", payload: {
                    msg:{
                        type: "error",
                        message: error.message
                    }
                }
            });
            return;
        }

        dispatchModalCar({
            type: "trade-set", payload: {
                tradeType:"vin",
                ...vinData
            }
        });
        dispatchModalCar({
            type: "update", payload: {
                currentSubModal: "tradeDetails"
        }});
    }

    return <>
        <Col lg={6} xl={4} className="col-12 bordered-speed mt-4 d-flex justify-content-center flex-column">
            <div className="header-speed text-center">
                Trade-in by VIN
            </div>

            <div>
                <InputCalcSpeed value={vinData?.vin || ""} onChange={handleOnChange} style={{ minWidth: "300px" }} label="What is the VIN on your vehicle?" placeholder="Enter VIN" />
            </div>
            <div>
                <table className="w-100">
                    <tbody>
                        {vinData?.make && <tr className='row-tri-info'><td><b>Make</b></td><td>{vinData.make}</td></tr>}
                        {vinData?.year && <tr className='row-tri-info'><td><b>Year</b></td><td>{vinData.year}</td></tr>}
                        {vinData?.model && <tr className='row-tri-info'><td><b>Model</b></td><td>{vinData.model}</td></tr>}
                        {vinData?.trim && <tr className='row-tri-info'><td><b>Trim</b></td><td>{vinData.trim}</td></tr>}
                        {vinData?.engine && <tr className='row-tri-info'><td><b>Engine</b></td><td>{vinData.engine}</td></tr>}
                        {vinData?.type && <tr className='row-tri-info'><td><b>Type</b></td><td>{vinData.type}</td></tr>}
                    </tbody>
                </table>
            </div>

            <div style={{ minWidth: "35%", marginTop: "25px" }}>
                {/* <ButtonBoder onClick={handleContinue} label="Continue" className="py-2" style={{ background: "#B72949" }} /> */}
                <LongButtonModel onClick={handleContinueButton} colors={["#FFFFFF", "#B72949"]} className="my-2">Continue</LongButtonModel>
            </div>
            <div className="d-flex text-center justify-content-center">
                <div onClick={modalCar?.actions?.backButton} className="back-btn-tri">Go Back</div>
            </div>
        </Col>

    </>
}