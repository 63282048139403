
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
function Register() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/register/vin', { replace: true });
    }, []);

    return (<></>);
}


// const Register = () => {

//     return <></>

//     // return <VinRegister/>


//     // const navigator = useNavigate();
//     // const [selected, setSelected] = useState(null);
//     // useEffect(() => {
//     //     navigator("/register/vin");
//     // }, [])

//     // function theWayRegisterSubmit() {
//     //     // switch case of the way register selected
//     //     switch (selected) {
//     //         case 0:
//     //             //VIN
//     //             navigator("vin");
//     //             return;
//     //         case 1:
//     //             //Plate
//     //             navigator("plate");
//     //             return;
//     //         case 2:
//     //             //Manual
//     //             navigator("manual");
//     //             return;
//     //     }
//     // }
//     // useEffect(() => {
//     //     if (selected !== null)
//     //         theWayRegisterSubmit();
//     // }, [selected])

//     // return <>
//     // </>
//     // return (
//     //     <div className='container container-d-flex'>
//     //         <div className='d-flex justify-content-center'>

//     //             <div className='col-12 col-md-4'>
//     //                 <div className='text-center box-register-logo' >
//     //                     <img alt="autos today logo no transparent" className="my-2" src="/images/speed/autos-today-logo.png" />
//     //                 </div>

//     //                 <div className='box-register'>
//     //                     <div className='navigator-container'>
//     //                         <span onClick={() => {
//     //                             navigator("/");
//     //                         }} className='box-go-back'>
//     //                             <FontAwesomeIcon icon={faArrowLeftLong} />
//     //                         </span>
//     //                     </div>

//     //                     <div className="textOrRegister my-3">
//     //                         The easiest way to list your car is with your license plate or a VIN number.
//     //                     </div>

//     //                     <div className='my-2'>
//     //                         {/* <ButtonOutlineBoder isactive={(selected === 1)} onClick={() => setSelected(1)} label="PLATE" /> */}
//     //                         <LongButtonModel className="py-3" colors={["#B62949","#f1f1f1"]}  onClick={(e) => setSelected(1)}> PLATE </LongButtonModel>
//     //                     </div>

//     //                     <div className='my-2'>
//     //                         {/* <ButtonOutlineBoder isactive={(selected === 0)} onClick={() => setSelected(0)} label="VIN" /> */}
//     //                         <LongButtonModel className="py-3" colors={["#B62949","#f1f1f1"]}  onClick={(e) => setSelected(0)}> VIN </LongButtonModel>
//     //                     </div>

//     //                     <p className='text-center mt-4' style={{fontSize:"13px"}}>Don't have a license plate or a VIN?</p>
//     //                     <div style={{ textAlign: "center" }}>
//     //                         <a href={''} style={{ color: "black", fontWeight: "bold" }} onClick={(e) => {
//     //                             e.preventDefault();
//     //                             setSelected(2);
//     //                         }}>List car manually</a>
//     //                     </div>

//     //                 </div>

//     //             </div>
//     //         </div>
//     //         <div className="flex-grow-1"></div>
//     //         <div>
//     //                 <RecentSold/>
//     //         </div>
//     //     </div>
//     //)
// }

export default Register