// @ts-nocheck
import ModalPortal from 'component/componenetInput/ModalPortal';
import React, { Component } from 'react'
import { Carousel, Modal } from 'react-bootstrap'
import { AiFillCloseCircle } from 'react-icons/ai';

export default class CarouselAutoCheck extends Component {
    constructor(props) {
        super(props)
        this.state = {
            carDetails: {
                ...props.carDetails,
                photosArray: props.carDetails.photos.split(",") ? props.carDetails.photos.split(",") : ["/images/car_no_image.jpg"],
            },
            maxHeight: props?.maxHeight ?? "500px",
            showFullScreenModal: false
        }
    }


    switchModalScreen(option){
        this.setState({showFullScreenModal: option});
    }

    render() {
        let variantDark = this.props.removelimits ? { variant: "dark" } : {};
        return (
            <>
                <ModalPortal>
                    <Modal onHide={()=>this.switchModalScreen(false)} show={this.state.showFullScreenModal} fullscreen={true}>
                        <div onClick={()=>this.switchModalScreen(false)} className="c-pointer"
                         style={{position:"absolute",top:"0px",right:"0px",zIndex:2,background:"white",borderRadius:"50%"}}>
                            <AiFillCloseCircle color="#b72949" size="2rem" />
                        </div>
                        <Carousel {...variantDark} >
                            {this.state.carDetails.photosArray.map((item, index) => {
                                return <Carousel.Item key={index}>
                                    <img onError={(e) => e.target.src = '/images/car_no_image.jpg'}
                                        className="d-block w-100 h-100 c-pointer"
                                        style={{ maxHeight: "100vh", objectFit: "contain" }}
                                        src={item}
                                        alt=""
                                    />
                                </Carousel.Item>
                            })}
                        </Carousel>
                    </Modal>
                </ModalPortal>
                <div style={{ overflow: "hidden", borderRadius: "10px", background: "linear-gradient(90deg, #e3e3e3, transparent 20%,transparent 80%,#e3e3e3 )" }}>
                    <Carousel {...variantDark} >
                        {this.state.carDetails.photosArray.map((item, index) => {
                            return <Carousel.Item key={index}>
                                <img onClick={()=>this.switchModalScreen(true)} onError={(e) => e.target.src = '/images/car_no_image.jpg'}
                                    className="d-block w-100 c-pointer"
                                    style={{ maxHeight: this.props.removelimits ? "95vh" : this.state.maxHeight, objectFit: "contain" }}
                                    src={item}
                                    alt=""
                                />
                            </Carousel.Item>
                        })}
                    </Carousel>
                </div>
            </>
        )
    }
}
