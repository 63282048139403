import React from 'react'
import { Form } from 'react-bootstrap'

const RadioBoder = React.forwardRef((props,ref) => {
    const {label,description,...attrProps} = props;
    return (
        <>
            <Form.Check type="radio">
                <Form.Check.Input ref={ref} {...attrProps} type={"radio"} isValid  />
                <Form.Check.Label>{label}</Form.Check.Label>
                <Form.Control.Feedback type="valid">{description}</Form.Control.Feedback>
            </Form.Check>
        </>
    )
})

export default RadioBoder