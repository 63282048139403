import InputCalcSpeed from 'component/componenetInput/InputCalcSpeed'
import LongButtonModel from 'component/componenetInput/LongButtonModel';
import { ModalCarContext } from 'component/searchCars/Context/ModalCarContext';
import { useContext, useState } from 'react'
import { Col } from 'react-bootstrap'
import {validateEmail} from 'utils/validateUtil';
import axios from 'axios';
import { baseURL } from 'config';
import { UserDataContext } from 'App';
import ReactDatePicker from 'react-datepicker';
import { BsFillCalendarFill } from 'react-icons/bs';
import "react-datepicker/dist/react-datepicker.css";


const TestDrive = () => {
    const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
    const userData = useContext(UserDataContext)[0];

    const [form, setForm] = useState({
        name: modalCar?.user_name || "",
        email: modalCar?.user_email || "",
        phone: modalCar?.user_phone || "",
        visit_date: modalCar?.user_visit_date || ""
    });

    const [invalidForm,setInvalidForm] = useState({
        name: "",
        email: "",
        phone: "",
        visit_date: ""
    });


    function handleFormChange(e) {
        setInvalidForm({
            ...invalidForm,
            [e.target.name]: ""
        });
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }

    function handleSubmitRequest(){
        if(form.visit_date === "" || form.name === "" || !validateEmail(form.email)){
            let newInvalid = {}
            form.visit_date === "" && (newInvalid["visit_date"] = "Visit date is required!");
            form.name === "" && (newInvalid["name"] = "Name is required!");
            !validateEmail(form.email) && (newInvalid["email"] = "Invalid email format!");
            setInvalidForm({
                ...invalidForm,
                ...newInvalid
            });
            return;
        }
        ///////
        apiRequestTestDrive({
            code: modalCar.code,
            name: form.name,
            email: form.email,
            phone: form.phone,
            visit_date: form.visit_date,
            token: userData?.token
        },(error,data)=>{
            if(error || data?.success === 0){
                dispatchModalCar({
                    type: "update",
                    payload: {
                        msg:{
                            type: "error",
                            message: data?.error ?? "Something went wrong! Please try again later.",
                        }
                    }
                });
                return;
            }
            dispatchModalCar({
                type: "update",
                payload: {
                    user_name: form.name,
                    user_email: form.email,
                    user_phone: form.phone,
                    user_visit_date: form.visit_date,
                    currentModal: 'primaryModal',
                    msg:{
                        type: "success",
                        message: "Your request for a test drive has been submitted successfully! Our team will be in touch with you shortly.",
                        counterLeft:1
                    }
                },
            });
        })      
        ///////
    }


    function handleBackButton(){
        dispatchModalCar({
            type: 'set', payload: {
              currentModal: 'primaryModal'
            }
        });
    }

    let tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);

    return (
        <Col lg={6} xl={4} className="col-12 bordered-speed mt-4">
            <div className="header-speed text-center">
                Test Drive
            </div>
            <InputCalcSpeed value={form.name} invalid={invalidForm.name} onChange={handleFormChange} name="name" label="Name" placeholder="Enter Name here..." />
            <InputCalcSpeed value={form.email} invalid={invalidForm.email} onChange={handleFormChange} name="email" label="Email Address" placeholder="Enter Email here..." />
            <InputCalcSpeed value={form.phone} invalid={invalidForm.phone} format="phoneNumber" onChange={handleFormChange} name="phone" label="Phone" icon={<small className="text-muted">Optional</small>} placeholder="Enter Phone here..." />
            
            {/* <InputCalcSpeed value={form.visit_date} invalid={invalidForm.visit_date} type="datetime-local" onInput={handleFormChange} name="visit_date" label="Date & Time *" placeholder="Enter Date and time here..." /> */}
            <div className='input-calc-speed-label my-1'>Date & Time *</div>
            <div className="input-calc-speed">
                    <BsFillCalendarFill size={25} className="me-1" />
                    <ReactDatePicker className='input-no-decoration w-100'
                    onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                    onFocus={(e) => {
                        e.preventDefault();
                        e.target.blur();
                    }}
                     minDate={tomorrowDate}
                      showTimeSelect dateFormat="MMMM d, yyyy h:mm aa"
                    placeholderText='Select Date & Time'
                     selected={form.visit_date}
                    onChange={(time)=>handleFormChange({target:{name: "visit_date",value: time} })} />
            </div>
            {invalidForm.visit_date && <div>
                <div className='italic-info-error ps-1'>
                    {invalidForm.visit_date}
                </div>
            </div>}
            
            <LongButtonModel disabled={Object.values(invalidForm).some((i)=>i !== "")} onClick={handleSubmitRequest} className="mt-3" colors={["#fff", "#B62949"]}>Request Test Drive</LongButtonModel>
            <div className="d-flex justify-content-center"><div onClick={handleBackButton} className="back-btn-tri">Go Back</div></div>
        </Col>
    )
}

export default TestDrive


export function apiRequestTestDrive({token,code,name,email,phone,visit_date},cb){
    // -/v1/seller/inventory/:code/test-drive
    let params = new URLSearchParams();
    params.append("name",name);
    params.append("email",email);
    phone && params.append("phone",phone);
    params.append("visit_date",visit_date);

    axios({
        method: 'post',
        url: `${baseURL}/seller/inventory/${code}/test-drive`,
        data: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': token
        }
    }).then((res)=>{
        cb(null,res.data);
    }).catch((err)=>{
        cb(err,null);
    });
}