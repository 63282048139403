import InputCalcSpeed from "component/componenetInput/InputCalcSpeed"
import LongButtonModel from "component/componenetInput/LongButtonModel"
import SelectSpeed from "component/componenetInput/SelectSpeed"
import { useContext, useEffect, useState, useRef } from "react"
import { ModalCarContext } from "component/searchCars/Context/ModalCarContext"
import axios from "axios"
import { baseURL } from "config"
import { UserDataContext } from "App"
import { useNavigate } from "react-router-dom"
import { apiFetchTaxRateFromZipCode } from "component/searchCars/request/apiFetchTaxRateFromZipCode"
import { BreakDownPartCalculator } from "./CalculatorParts"

/*
    price: 0,
    downPayment: 0,
    needFinancing: true,
    taxRate: 0,
    tradeInValue: 0,
    tradeInLoanBalance: 0,
    hasTradeIn: false,
    monthlyPayment: 0,
    totalDue: 0,
    apr: 0,
    loanTerm: 0,
*/

const Calculator = () => {
    const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
    const [userData,setUserData] = useContext(UserDataContext);
    const refUploadInfo = useRef(null);
    const [form,setForm] = useState({
        price: modalCar?.calc?.price ?? modalCar?.price ?? 0,
        downPayment: modalCar?.calc?.downPayment ?? 0,
        taxRate: (modalCar?.calc?.taxRate * 100) || 0,
        needFinancing: modalCar?.calc?.needFinancing ?? true,
        monthlyPayment: modalCar?.calc?.monthlyPayment ?? 0,
        totalDue: modalCar?.calc?.totalDue ?? 0,
        apr: modalCar?.calc?.apr ?? 8.99,
        loanTerm: modalCar?.calc?.loanTerm ?? 72,

        hasTradeIn: modalCar?.calc?.hasTradeIn ?? false,

        tradeInValue: parseInt(modalCar?.calc?.tradeInValue) || 0,
        tradeInLoanBalance: parseInt(modalCar?.calc?.tradeInLoanBalance) || 0,

        zipCode: modalCar?.user_zip_code ?? "",
    });
    const navigate = useNavigate();
    useEffect(()=>{
        refUploadInfo.current.classList.add("d-none");
        // refUploadInfo.current.querySelector(".counter").innerHTML = `(${counterUploads} / ${modalCar?.trade?.images?.length})`;
        refUploadInfo.current.nextElementSibling.removeAttribute("disabled");
    },[]);
    useEffect(() => {
        console.log("form",form);
        dispatchCalc();
    },[form]);

    function dispatchCalc(){
        let {zipCode,taxRate,...otherForm} = form;
        taxRate = (parseFloat(taxRate) / 100) || 0;
        dispatchModalCar({
            type: 'calc-update',
            payload: {
                ...otherForm,
                taxRate
            }
        });
        dispatchModalCar({type:"calculate"});
    }

    function handleChangesForm(e){
        e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        let name = e.target.name;
        let value = e.target.value;
        if(name === "price" || name === "downPayment" || name === "tradeInValue" || name === "tradeInLoanBalance"){
            value = parseInt(value);
            value = value > 500_000 ? 500_000 : value;
        }
        if(name === "apr" || name === "taxRate"){
            // value = parseFloat(value);
            value = value > 100 ? 100 : value;
        }
        
        setForm({
            ...form,
            [ e.target.name]: value
        });
    }


    function handleUpdateNeedFinancing(option){
        setForm({
            ...form,
            needFinancing: option
        });
    }

    function handleTradeInSubModal(){
        dispatchModalCar({
            type: 'update',
            payload: {
                currentSubModal: "tradeByType"
            }
        });
    }

    function handleChangesZipCode(e){
        setForm({
            ...form,
            zipCode:  e.target.value,
            taxRate: 0
        });

        apiFetchTaxRateFromZipCode(e?.target?.value,(error,data)=>{
            if(error || data?.success === 0){
                return;
            }
            setForm((prev)=>{
                return {
                    ...prev,
                    taxRate: parseFloat((data?.rate * 100).toFixed(2))
                }
            });
        });
    }

    function handleMakeThisOffer(){
        let counterUploads = 0;
        apiRequestMakeThisOfferStart(modalCar,userData.token,(error,data)=>{
            console.log("error,data:",error,data);
            let {success,...otherData} = data;

            if(success === 0){
                dispatchModalCar({
                    type: 'update',
                    payload: {
                        msg:{
                            type: "error",
                            message: data?.error
                        }
                    }
                });
                return;
            }
            let {negotitation_code,car_id} = otherData;
            // http://localhost:3000/negotiations?code=
            // navigate(`/negotiations?code=${negotitation_code}`);
            for(let i = 0; i < modalCar?.trade?.images?.length; i++){
                let {File,local_id,progress,status} = modalCar?.trade?.images[i];
                apiUploadImage({file:File,car_id,token:userData.token},{
                    doneCallback: (error,data)=>{
                        counterUploads++;
                        if(counterUploads === modalCar?.trade?.images?.length){
                            navigate(`/negotiations?code=${negotitation_code}`);
                        }
                        refUploadInfo.current.classList.remove("d-none");
                        refUploadInfo.current.querySelector(".counter").innerHTML = `(${counterUploads} / ${modalCar?.trade?.images?.length})`;
                        refUploadInfo.current.nextElementSibling.setAttribute("disabled",true);
                    }
                });
            }
            if(!modalCar?.trade?.images || modalCar?.trade?.images?.length === 0){
                navigate(`/negotiations?code=${negotitation_code}`);
            }
        });
    }


    if(!modalCar?.calc){
        return <></>;
    }

    return (
        <div className="bordered-speed mt-4 d-flex flex-wrap w-100">
            <div className="col-12 col-xl-6 px-2">
                <div className="header-speed text-center my-3">Offer Calculator</div>

                <div className="d-flex flex-column bordered-speed">
                    <div>
                        <InputCalcSpeed name="price" onChange={handleChangesForm} value={form.price}  label="Car Price" icon="$" placeholder="Enter here..." />
                    </div>
                    <div className="d-flex my-2">
                        <InputCalcSpeed value={form.zipCode} name="zipCode" onChange={handleChangesZipCode} containerClassName="w-25 px-1" label="Zip Code" placeholder="Enter Code..." />
                        <InputCalcSpeed value={(form.taxRate)} name="taxRate" onChange={handleChangesForm} containerClassName="w-75 px-1" label="Tax Rate" icon="%" placeholder="Enter Tax here..." />
                    </div>

                    {!form.hasTradeIn && <div className="mb-1">
                        <div className='input-calc-speed-label my-1 '>Trade-in / Loan Balance</div>
                        <LongButtonModel onClick={handleTradeInSubModal} colors={["#B62949", "#F4DFE4"]} >+ Add Trade-In</LongButtonModel>
                    </div>}

                    {form.hasTradeIn && <div className="d-flex my-1">
                        <InputCalcSpeed name="tradeInValue" value={form.tradeInValue}
                         onChange={handleChangesForm} containerClassName="w-50 px-1" label="Trade in value" 
                        placeholder="Enter Trade in value" />
                        <InputCalcSpeed name="tradeInLoanBalance" value={form.tradeInLoanBalance}
                         onChange={handleChangesForm} containerClassName="w-50 px-1"
                          label="Loan Balance"  placeholder="Enter Trade-in Loan Balance" />
                    </div>}


                </div>
            </div>


            <div className="col-12 col-xl-6 px-2">
                <div className="header-speed text-center my-3" style={{ color: "#B62949" }}>Financing Details</div>
                <div className="d-flex flex-column bordered-speed" style={{borderColor:"#B629494D"}}>
                    {modalCar.calc.needFinancing ? <>
                    <div className="d-flex">
                        <InputCalcSpeed name="downPayment" onChange={handleChangesForm} value={form.downPayment} containerClassName="w-50 px-1" label="Down-Payment" icon="$" placeholder="Enter here..." />

                        <SelectSpeed name="loanTerm" onChange={handleChangesForm} value={form.loanTerm} containerClassName="w-50 px-1" label="Length of Loan"  options={[
                            { value: "12", label: "12 Months" },
                            { value: "24", label: "24 Months" },
                            { value: "36", label: "36 Months" },
                            { value: "48", label: "48 Months" },
                            { value: "60", label: "60 Months" },
                            { value: "72", label: "72 Months" },
                        ]} />
                    </div>
                    <div className="my-2">
                        <InputCalcSpeed name="apr" onChange={handleChangesForm} value={form.apr} label="Annual Percentage rate (APR)" icon="%" placeholder="Enter APR here..." />
                    </div>
                    
                    <div className="d-flex justify-content-end">
                        <span onClick={()=>handleUpdateNeedFinancing(false)} className='link-clickable'>No Financing</span>
                    </div>
                    </> : <>
                        <LongButtonModel onClick={()=>handleUpdateNeedFinancing(true)} colors={["#B62949", "#F4DFE4"]} >+ Add Financing</LongButtonModel>
                    </>}
                </div>
            </div>


            <div className="d-flex col-12 my-2 flex-wrap mx-2">
                {modalCar.calc.needFinancing && <div className="col-12 col-xl-2 d-flex flex-column justify-content-center flex-grow-1">
                    <div style={{color:"#767980",fontSize:"13px"}}>Total Amount:</div>
                    <div style={{color:"#1A202C",fontSize:"20px",fontWeight:"bold"}}>${Math.round(modalCar.calc.totalDue).toLocaleString()}</div>
                    <BreakDownPartCalculator data={modalCar}/>
                </div>}
                <div className={"col-12 col-xl-10 bordered-speed d-flex align-tiems-center py-2 flex-grow-1 "+(modalCar.calc.needFinancing ? "col-xl-10" : "col-xl-12")}>
                        <div className="col-6 d-flex align-items-center ms-2">
                            {modalCar.calc.needFinancing ? <>
                            <span style={{color:"#B62949",fontSize:"30px",fontWeight:"bold"}}>${Math.round(modalCar?.calc?.monthlyPayment).toLocaleString()}</span>
                            <span style={{color:"#8C8F95",fontSize:"13px",marginTop:"8px"}}>/Monthly Payment</span>
                            </>:<>
                            <span style={{color:"#B62949",fontSize:"30px",fontWeight:"bold"}}>${Math.round(modalCar?.calc?.totalDue).toLocaleString()}</span>
                            <span style={{color:"#8C8F95",fontSize:"13px",marginTop:"8px"}}> /Total Due</span>
                            <span className="d-inline-block ms-3"><BreakDownPartCalculator data={modalCar}/></span>
                            </>}
                        </div>
                        <div className="col-6">
                            <div className="text-center text-muted d-none" style={{fontSize:"14px"}} ref={refUploadInfo}>
                                Uploading images... <span className="counter">( X / X)</span>
                            </div>
                            <LongButtonModel onClick={handleMakeThisOffer} colors={["#ffffff", "#B62949"]} >Make this Offer</LongButtonModel>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default Calculator



/*
{ inventory_code: String, price: Decimal, down_payment: Decimal, trade_in: Decimal, api: Decimal, tax_rate: Decimal, loan_term: Number,
     monthly_payment: Decimal, buy_now: 1/0, financing: 1/0,
trade: 1/0, trade_method: ”vin/plate/manual”, trade_mileage: Number, trade_condition_id: Number, trade_year: Number, trade_make: String, 
trade_model: String, trade_trim: String, trade_vin: String, trade_plate: String, trade_state: String, trade_asking_price: Number, trade_vin_id: Number,
 trade_loan_balance: Number }
*/
function apiRequestMakeThisOfferStart(modalCarData,token,callback = ()=>{}){
    const params = new URLSearchParams();
    params.append('inventory_code', modalCarData.code);
    params.append('price', parseInt(modalCarData.calc.price));
    params.append('down_payment', modalCarData.calc.downPayment);
    params.append('trade_in', modalCarData.calc.tradeInValue);
    modalCarData?.calc?.hasTradeIn && params.append("trade_asking_price", modalCarData.calc.tradeInValue);
    params.append('apr', modalCarData.calc.apr);
    params.append('tax_rate', parseFloat(modalCarData?.calc?.taxRate)*100 || 0);
    params.append('loan_term', modalCarData.calc.loanTerm);
    params.append('monthly_payment', modalCarData.calc.monthlyPayment);
    // params.append('buy_now', modalCarData.calc.buyNow ? 1 : 0);
    params.append('financing', modalCarData.calc.needFinancing ? 1 : 0);
    params.append('trade', modalCarData.calc.hasTradeIn ? 1 : 0);

    if(modalCarData?.calc?.hasTradeIn){
        params.append('trade_year', modalCarData?.trade?.year);
        params.append('trade_make', modalCarData?.trade?.make);
        params.append('trade_model', modalCarData?.trade?.model);
        params.append('trade_trim', modalCarData?.trade?.trim);
        params.append('trade_mileage', modalCarData?.trade?.currentMileage);
        params.append('trade_condition_id', modalCarData?.trade?.carCondition);
        params.append('trade_loan_balance', modalCarData?.calc?.tradeInLoanBalance || 0);
    }
    switch(modalCarData?.trade?.tradeType){
        case "manual":
            params.append('trade_method', "manual");
            break;
        case "vin":
            params.append('trade_method', "vin");
            params.append('trade_vin', modalCarData.trade.vin);
            params.append('trade_vin_id', modalCarData.trade.vin_id);
            break;
        case "plate":
            params.append('trade_method', "plate");
            params.append('trade_plate', modalCarData.trade.plate);
            params.append('trade_state', modalCarData.trade.state);
            params.append('trade_vin_id', modalCarData.trade.vin_id);
            params.append('trade_vin', modalCarData.trade.vin);

            break;
        default:
            console.log('trade type not found', modalCarData?.trade?.tradeType);
    }


    console.log(" -- params send to server -- ");
    for(let [key,value] of params.entries()){
        console.log(key,value);
    }

    axios({
        method: 'post',
        url: baseURL+ "/seller/negotiation/start",
        headers:{
            'Authorization': token,
            'Content-Type':'application/x-www-form-urlencoded'
        },
        data: params
    }).then((response)=>{
        callback(null,response.data);
    }).catch((error)=>{
        console.log(error);
        callback(error,null);
    });
}




function apiUploadImage({
    file, car_id, token,controller
}, {
    progressCallback = () => { },
    doneCallback = () => { }
}) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("car_id", car_id);
    axios({
        method: "POST",
        url: baseURL + "/seller/upload",
        headers: {
            'Authorization': token
        },
        data: formData,
        signal: controller ? controller?.signal : null,
        onUploadProgress: function (progressEvent) {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            progressCallback(percentCompleted);
        }
    }).then((response) => {
        doneCallback(null, response.data);
    }).catch((error) => {
        doneCallback(error);
    });
}