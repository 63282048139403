import axios from 'axios';
import { baseURL } from 'config';
export function apiSaveVin(token,vin,callback){
    let params = new URLSearchParams();;
    params.append("vin", vin);
    axios({
        method: "POST",
        url: baseURL + "/seller/vin-kbb",
        headers: {
            "Authorization": token,
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: params
    }).then((response) => {
        callback(null,response.data);
    }).catch((error) => {
        callback(error,null);        
    });
}