// @ts-nocheck
import { UserDataContext } from 'App';
import axios from 'axios';
import ButtonBoder from 'component/componenetInput/ButtonBoder';
import { baseURL } from 'config';
import React, { useContext, useRef, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';

const ModalCounterBid = () => {
    const [userData, setUserData] = useContext(UserDataContext);
    let counterRef = useRef(null);
    const [modalShow, setModalShow] = useState(false); // counter offer
    const [shadowBid, setShadowBid] = useState(null);
    var shadowCallback = null;


    function counterBidSubmit() {
        let params = new URLSearchParams();
        let valueCounter = counterRef.current.value; // save value of input
        if (isNaN(parseInt(valueCounter))) { // in case the parsing fails  do not submit the counter!
            return;
        }
        params.append("bid_id", shadowBid.id);
        params.append("counter", valueCounter);
        axios({
            method: "POST",
            url: baseURL + "/seller/bid/counter",
            data: params,
            headers: {
                'Authorization': userData.token,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(() => {
            if (shadowCallback) {
                shadowCallback(shadowBid, valueCounter);
            }
        });
    }
    function showCounterModal(bid) {
        setShadowBid(bid);
        setModalShow(true);
    }

    function ModalCounterjsx({ callback }) {
        shadowCallback = callback;
        return <>
            <Modal show={modalShow} onHide={() => { setModalShow(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Counter Offer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control
                                ref={counterRef}
                                type="number"
                                placeholder="Enter your counter offer"
                                autoFocus
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonBoder onClick={() => { setModalShow(false); counterBidSubmit(); }} label="Counter" />
                </Modal.Footer>
            </Modal>
        </>
    }
    return { ModalCounterjsx, showCounterModal };
}

export default ModalCounterBid