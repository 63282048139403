import React from 'react'
import { apiFetchOffers } from 'component/homeStar/request/apiFetchOffers';
//import {MdOutlineArrowBackIos} from '@react-icons/all-files/md/MdOutlineArrowBackIos';
//import {SlArrowDown} from '@react-icons/all-files/sl/SlArrowDown';
import { IoIosArrowDown } from '@react-icons/all-files/io/IoIosArrowDown';
import { useEffect, useContext, useState } from 'react';
import { SocketDataContext, UserDataContext } from 'App';
import { useNavigate } from 'react-router-dom';

const TopOffers = () => {
    const [userData, setUserData] = useContext(UserDataContext);
    const [isBidClose, setIsBidClose] = useState(false);
    const [bidsSpliced, setBidsSpliced] = useState([]);
    const { socketData, setSocketData } = useContext(SocketDataContext);
    const [visibleList, setVisibleList] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        apiFetchOffers(userData.token, responseFetchOffers);
    }, []);

    function responseFetchOffers(error, data) {
        if (error) { throw error; }
        let arrayBids = data;

        // find if winner= 1 in arrayBids if it is true then set isBidClose to true
        let isBidClose = arrayBids.findIndex((bid) => {
            return bid.winner === 1;
        });
        if (isBidClose !== -1) {
            setIsBidClose(true);
        }


        // get only the last 4 bids (the highest) for each dealer_name
        let bidsSpliced = arrayBids.reduce((acc, curr) => {
            let dealer_name = curr.dealer_name;
            let index = acc.findIndex((obj) => obj.dealer_name === dealer_name);
            if (index === -1) {
                acc.push({
                    dealer_name: dealer_name,
                    bids: [curr]
                });
            } else {
                acc[index].bids.push(curr);
            }
            return acc;
        }, []);

        // sort the bids by highest price
        bidsSpliced.forEach((dealer) => {
            dealer.bids.sort((a, b) => {
                return b.price - a.price;
            }
            );
        }
        );
        // get only one bid from 4 bids (the highest) for each dealer_name
        bidsSpliced.forEach((dealer) => {
            dealer.bids = dealer.bids.slice(0, 1);
        });

        // dublicate bidsSpliced 6 times
        // bidsSpliced = bidsSpliced.concat(bidsSpliced, bidsSpliced, bidsSpliced, bidsSpliced, bidsSpliced);


        setBidsSpliced(bidsSpliced.slice(0, 6));
    }
    useEffect(() => {
        const socket = socketData?.socket;
        if (!socket) return;

        socket.on('new-bid', (data) => {
            apiFetchOffers(userData.token, responseFetchOffers);
        });
        return () => {
            socket.off('new-bid');
        };
    }, [socketData.socket]);

    return (
        <>
            {bidsSpliced.length !== 0 &&
                <div className='top-offers-bordered mt-3'>
                    {bidsSpliced.length !== 0 && <>
                        <div className={'d-flex justify-content-between ' + (visibleList ? "mb-3" : "")}>
                            <div className='header'>Offers</div>
                            <div onClick={() => setVisibleList(!visibleList)} className='header-clickable'>{visibleList ? "Hide" : "Show"}</div>
                        </div>

                        {visibleList && !isBidClose && bidsSpliced.slice(0, 6).map((bid, index) => {
                            return <div key={index} >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div onClick={() => (navigate("/messages", { state: bid }))} className={'row-for-top-offer-speed ' + (index === 0 ? "" : "white")}>
                                        <div style={{ color: (index === 0 ? "black" : "#A3A6AB"), display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "baseline", marginBottom: "-6px" }}>
                                            <span>{bid.dealer_name}</span>
                                            <span>${bid.bids[0].bid_amount.toLocaleString()}</span>
                                        </div>
                                        {index === 0 && <div style={{ color: "#04CE00", textAlign: "center", fontSize: "13px", marginTop: "10px" }}>Highest Offer</div>}
                                    </div>
                                </div>
                            </div>
                        })}
                        {bidsSpliced.length === 0 && <h5 className="fw-bold text-center text-muted">No offers</h5>}
                    </>}
                </div>
            }
        </>
    )
}

export default TopOffers
/*
    background: rgb(196, 196, 196);
    width: 41px;
    height: 41px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 20px;
*/

/*
{"dealer_name":"Dealer 6","bids":[{"auction_id":3913,"auction_start_date":"2022-11-01T10:39:55.000Z","id":4285,"bid_amount":100,"created_date":"2022-11-02T11:04:01.000Z","dealer_name":"Dealer 6","location":"Bartlett, IL","winner":null,"winner_date":null,"view_date":null}]}
one row 
{
    "auction_id": 3913,
    "auction_start_date": "2022-11-01T10:39:55.000Z",
    "id": 4285,
    "bid_amount": 100,
    "created_date": "2022-11-02T11:04:01.000Z",
    "dealer_name": "Dealer 6",
    "location": "Bartlett, IL",
    "winner": null,
    "winner_date": null,
    "view_date": null
}*/