// @ts-nocheck
/* eslint-disable */
import axios from 'axios';
import ImageUploader from 'component/configDetails/componentDetails/ImageUploader';
import React, { useState, useEffect, useContext, useRef, useCallback, useMemo } from 'react'
import { Col, Row, ButtonGroup } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { UserDataContext } from '../../../App';
import { baseURL, baseURLv2 } from '../../../config';
import ButtonBoder from '../../componenetInput/ButtonBoder';
import InputBotBoder from '../../componenetInput/InputBotBoder';
import RadioBoder from '../../componenetInput/RadioBoder';
import SelectBotBoder from '../../componenetInput/SelectBotBoder';
import Loading from '../../Loading';
import { validateEmail, validateUSAPhoneNumber } from 'utils/validateUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { formatPhoneNumber, parseJSONWithDefault } from 'utils/generalUtil';
import RecentlySold from 'component/homeStar/section/RecentSold';
import { FcInfo } from '@react-icons/all-files/fc/FcInfo';
import InputCalcSpeed from 'component/componenetInput/InputCalcSpeed';
import MaskedInput from 'react-text-mask';
import { NavLink } from 'react-router-dom';

const ManualRegister = () => {

    const setUserData = useContext(UserDataContext)[1];
    //const phoneRef = useRef(null);
    const [step, setStep] = useState(parseJSONWithDefault(window.localStorage['step-manual-v2'], { step: 0, isLoading: false }));
    let navigator = useNavigate();
    const [form, setForm] = useState({
        year: window.localStorage.getItem('year') || '',
        make: window.localStorage.getItem('make') || '',
        model: window.localStorage.getItem('model') || '',
        trim: window.localStorage.getItem('trim') || '',
        condition_id: window.localStorage.getItem('condition_id') || '',
        mileage: window.localStorage.getItem('mileage') || '',
        zip_code: window.localStorage.getItem('zip_code') || '',
        //phone: window.localStorage.getItem('phone') || '',
        email: window.localStorage.getItem('email') || '',
        password: window.localStorage.getItem('password') || '',
        asking_price: window.localStorage.getItem('asking_price') || '',
        phone: window.localStorage.getItem('phone') || '',
    }); // form data generated by user input
    const [extraForm, setExtraForm] = useState(parseJSONWithDefault(window.localStorage['extraForm-manual'], {})); // form data generated from request
    const [hasError, setHasError] = useState("");

    const [formError,setFormError] = useState({});

    useEffect(() => {
        window.localStorage.setItem('step-manual-v2', JSON.stringify(step));
    }, [step.step]);

    useEffect(() => {
        window.localStorage.setItem('extraForm-manual', JSON.stringify(extraForm));
    }, [extraForm]);

    useEffect(() => {
        window.localStorage.setItem('year', form.year);
        window.localStorage.setItem('make', form.make);
        window.localStorage.setItem('model', form.model);
        window.localStorage.setItem('trim', form.trim);
        window.localStorage.setItem('condition_id', form.condition_id);
        window.localStorage.setItem('mileage', form.mileage);
        window.localStorage.setItem('zip_code', form.zip_code);
        //window.localStorage.setItem('phone', form.phone);
        window.localStorage.setItem('email', form.email);
        //window.localStorage.setItem('password', form.password); 
        window.localStorage.setItem('asking_price', form.asking_price);
        window.localStorage.setItem('phone', form.phone);
    }, [form]);


   function restFormError(){
        setFormError({});
    }
    function setError(key,value){
        setFormError((p)=>{
            return {...p,[key]:value}
        });
    }
    function hasFormError(){
        return Object.keys(formError).length>0;
    }


    async function checkZipCodeValid(zip_code) {
        try{
            const response = await axios.get(`${baseURL}/tax/${zip_code}`);
            return !!response.data.success;
        }catch(error){
            return false;
        }
    }


    async function submitNextEvent() {
        setHasError("");
        restFormError();
        switch (step.step) {
            case 0:
                let mileage = parseInt(form.mileage);
                if (isNaN(mileage)) {
                    //setHasError("Mileage format is incorrect should be number format!");
                    setError('mileage','Mileage format is incorrect should be number format!');
                    return
                }


                if(isNaN(parseInt(form.asking_price || 0)) ){
                    setError('asking_price','Asking price required to be a number format!');
                    return;
                }


                let zip_code = form.zip_code;
                if (zip_code === "") {
                    setError('zip_code','Zip code is required to be filled in.');
                    return;
                } else if (isNaN(parseInt(zip_code))) {
                    setError('zip_code','Zip code required to be a number format!');
                    return;
                }

                if(!await checkZipCodeValid(zip_code)){
                    setError('zip_code','Zip code is invalid!');
                    return;
                }


                let year = form.year;
                let make = form.make;
                if (year === "") {
                   // setHasError("Year and make is required to be selected!");
                    setError("year","Year is required to be selected!");
                } else if(make === ""){
                    setError("make","Make is required to be selected!");
                } else {
                    finalSubmit(()=>{
                        goNextStep();
                    });
                }
                break;
            case 1:
                setUserData((p) => {
                    window.localStorage.clear();
                    return { ...p, isOnline: true };
                });
                navigator("/");
                break;
            default:
                return "default"
        }
    }

    function finalSubmit(callbackSuccess) {
        setStep((state) => {
            return { ...state, isLoading: true }
        });
        let params = new URLSearchParams();
        for (var [key, value] of Object.entries({ ...form, ...extraForm })) {
            params.append(key, value);
        }
        axios({
            method: "POST",
            /// /v2/seller/register-plate
            url: baseURLv2 + "/seller/register-plate",
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === 1) {
                setUserData({
                    ...response.data,
                });
                callbackSuccess?.();
            } else {
                setHasError(response.data.error);
            }
        }).catch((error) => {
            setHasError("Something went wrong or check network connection!");
            console.log("response from above", error);
        }).finally(() => {
            setStep((state) => {
                return { ...state, isLoading: false }
            });

        });
    }


    function goNextStep() {
        setStep((state) => {
            return { ...state, step: state.step + 1 }
        });
    }

    const [listMaker, setListMaker] = useState([]);
    const [listModel, setListModel] = useState([]);
    const [listTrim, setListTrim] = useState([]);

    useEffect(() => {
        if (form.year === "") return;
        // fetchMakerList
        if (form.make === "") return;
        // fetchModelMaker
        if (form.model === "") return;
        // fetchTrimsMakerList
    }, [form]);

    function fetchMakerListRaw() {
        let year = form.year;
        if (year === "") return;
        axios({
            method: "GET",
            url: baseURL + "/makes/" + year,
        }).then((response) => {
            let arrayModels = response.data;
            setListMaker(arrayModels);
        }).catch((error) => {
            console.log("error:", error);
            setListMaker([]);
        });
    }

    // const fetchMakerList = useCallback(fetchMakerListRaw, [form.year]);
    const fetchMakerList = useMemo(fetchMakerListRaw, [form.year]);

    function fetchModelMakerRaw() {
        let year = form.year;
        let maker = form.make;
        if (year === "" || maker === "") return;
        axios({
            method: "GET",
            url: baseURL + "/models/" + year + "," + maker,
        }).then((response) => {
            let arrayModels = response.data;
            setListModel(arrayModels);
        }).catch((error) => {
            console.log("error:", error);
            setListModel([]);
        });
    }

    // const fetchModelMaker = useCallback(fetchModelMakerRaw, [form.year, form.make]);
    const fetchModelMaker = useMemo(fetchModelMakerRaw, [form.year, form.make]);

    function fetchTrimsMakerListRaw() {
        let year = form.year;
        let maker = form.make;
        let model = form.model;
        if (year === "" || maker === "" || model === "") return;
        axios({
            method: "GET",
            url: baseURL + "/trims/" + year + "," + maker + "," + model,
        }).then((response) => {
            let arrayModels = response.data;

            setListTrim(arrayModels);
        }).catch((error) => {
            console.log("error:", error);
            setListTrim([]);
        });
    }

    // const fetchTrimsMakerList = useCallback(fetchTrimsMakerListRaw, [form.year, form.make, form.model]);
    const fetchTrimsMakerList = useMemo(fetchTrimsMakerListRaw, [form.year, form.make, form.model]);

    function oneStepBack() {
        setStep((state) => {
            let result = state.step - 1;
            if (result < 0) {
                navigator("/register");
                return { ...state, step: 0 };
            }
            return { ...state, step: result }
        });
    }

    let arrayYearList = Array.from({ length: ((new Date()).getFullYear() + 2) - 1990 }).map((item, index) => 1990 + index);
    arrayYearList.reverse();
    return (<div className='container container-d-flex'>
        <div className='d-flex justify-content-center'>
            <div className={step.step !== 1 ? 'col-12 col-md-4' : 'col-12 col-md-9'} >
                <div className='text-center box-register-logo' >
                    <img alt="autos today logo no transparent" className="my-2" src="/images/speed/autos-today-logo.png" />
                </div>
                <div className='box-register'>

                    {step.step < 1 &&
                        <>
                            <div className='navigator-container'>
                                <span onClick={oneStepBack} className='box-go-back'>
                                    <FontAwesomeIcon icon={faArrowLeftLong} />
                                </span>
                            </div>
                            <div className="textOrRegister small-text my-3">
                                Fill out this simple form and receive <b>offers in minutes</b>. We are <b>100% free</b> and anonymous platform and there is <b>no obligation</b> to sell your car.
                            </div>
                            
                            <div className="text-center">
                                <ButtonGroup >
                                    <NavLink to="/register/plate" className={'button-choose-register btn btn-outline-danger' + (window.location.pathname === "/register/plate" ? " active" : "")}>PLATE</NavLink>
                                    <NavLink to="/register/vin" className={'button-choose-register btn btn-outline-danger' + (window.location.pathname === "/register/vin" ? " active" : "")}>VIN</NavLink>
                                    <NavLink to="/register/manual" className={'button-choose-register btn btn-outline-danger' + (window.location.pathname === "/register/manual" ? " active" : "")}>MANUAL</NavLink>
                                </ButtonGroup>
                            </div>
                        </>
                    }

                  
                    {hasError !== "" && <div className="warning-error">{hasError}</div>}
                    {/* Step null for loading */}
                    {((typeof step.step === 'undefined' || step.isLoading === true) && <Loading />)}
                    {step.step === 0 && !step.isLoading && <>
                        <div className="my-3">
                            <SelectBotBoder invalid={formError.year} defaultValue={form.year} onChange={(e) => { setForm((i) => { return { ...i, year: e.target.value } }) }} label="Select Year*" placeholder="Select year" labelOptions={arrayYearList} valueOptions={arrayYearList} />
                        </div>
                        {form.year !== "" && <div className="my-3">
                            <SelectBotBoder invalid={formError.make} defaultValue={form.make}  onChange={(e) => { setForm((i) => { return { ...i, make: e.target.value } }) }} label="Select Make*" placeholder="Select make" labelOptions={listMaker} valueOptions={listMaker} />
                        </div>}
                        {form.make !== "" && <div className="my-3">
                            <SelectBotBoder invalid={formError.model} defaultValue={form.model} onChange={(e) => { setForm((i) => { return { ...i, model: e.target.value } }) }} label="Select Model*" placeholder="Select model" labelOptions={listModel} valueOptions={listModel} />
                        </div>}

                        {form.model !== "" && <div className="my-3">
                            <SelectBotBoder invalid={formError.trim}  defaultValue={form.trim} onChange={(e) => { setForm((i) => { return { ...i, trim: e.target.value } }) }} label="Select Trim*" placeholder="Select trim" labelOptions={listTrim} valueOptions={listTrim} />
                        </div>}
                        <div className="my-3">
                            <InputCalcSpeed invalid={formError.mileage}  value={form.mileage} type="number" onChange={(e) => { setForm((i) => { return { ...i, mileage: e.target.value } }) }} placeholder="Current mileage" label="Current Mileage*" />
                        </div>
                        <div className='my-3'>
                            <InputCalcSpeed type="number" invalid={formError.zip_code} value={form.zip_code} onChange={(e) => { setForm(i => { return { ...i, zip_code: e.target.value } }) }} label="Zip Code*" placeholder="Zip code" />
                        </div>
                        <div className="my-3">
                            <InputCalcSpeed invalid={formError.asking_price} value={form.asking_price} type="number" onChange={(e) => { setForm((i) => { return { ...i, asking_price: e.target.value } }) }} placeholder="What do you think your car is worth? (optional)" label="Asking Price" />
                        </div>

                    </>}
                   
                    {step.step === 1 && !step.isLoading && <>
                        <div className="my-3">
                            <ImageUploader type="register" />
                        </div>
                    </>}
                    <Row className='fixed-position-bottom'>
                        <Col>
                            {!step.isLoading && <ButtonBoder onClick={submitNextEvent} label={step.step === 1 ? "Finish" : "GET MY OFFER"} />}
                        </Col>
                        {step.step === 1 && !step.isLoading && <Col>
                            <ButtonBoder onClick={submitNextEvent} label="Skip" />
                        </Col>}
                    </Row>
                    {step.step === 1 && <div className='accept-terms-text'>
                        By signing up with our platform you agree to our <a target="_blank" href='https://autostoday.com/terms-of-use/'>Terms of Use</a>
                    </div>}
                </div>

            </div>
        </div>
        <div className="flex-grow-1"></div>
        <div>
            <RecentlySold />
        </div>
    </div>);


}

export default ManualRegister