// @ts-nocheck
import { UserDataContext } from 'App'
import axios from 'axios'
// import ButtonBoder from 'component/componenetInput/ButtonBoder'
import InputBotBoder from 'component/componenetInput/InputBotBoder'
import LongButtonModel from 'component/componenetInput/LongButtonModel'
import RadioBoder from 'component/componenetInput/RadioBoder'
import TextAreaBoder from 'component/componenetInput/TextAreaBoder'
import Loading from 'component/Loading'
import { baseURL } from 'config'
import { useContext, useEffect, useState } from 'react'
import { Container} from 'react-bootstrap'
import "react-toastify/dist/ReactToastify.css";


const FormDetails = () => {

  const [userData, setUserData] = useContext(UserDataContext);
  const [form, setForm] = useState({
    mileage: -1,
    condition_id: -1,
    notes: "",
    vin: "",
    asking_price: "",
    part1DetailsUpdate: null,
    part2DetailsUpdate: null,
  });
  const [isError,setIsError] = useState("");
  const [isErrorVin,setIsErrorVin] = useState("");
  const [isSuccess,setIsSuccess] = useState("");
  const [isSuccessVin,setIsSuccessVin] = useState("");

  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    fetchDetails(); // fetch details car from server
    fetchVinNumber(); // fetch vin number from server
  }, []);


  //create function to fetch vin number from server and set to form
  function fetchVinNumber() {
    axios({
      method: "GET",
      url: baseURL + "/seller/progress",
      headers: {
        "Authorization": userData.token
      }
    }).then((response) => {
        setForm((p)=>{
          return {
            ...p,
            vin: response.data?.auction?.vin || "",
            asking_price: response.data?.auction?.asking_price || "",
          }
        });
    });
  }


  function fetchDetails() {
    if(!userData?.car) return ; // stop no car is set
    axios({
      method: "GET",
      url: baseURL + "/car/details/" + userData.car.id,
      headers: {
        'Authorization': userData.token
      }
    }).then((response) => {
      setForm((p) => {
        let mileage = response.data.mileage;
        let condition_id = response.data.condition_id;
        let notes = response.data.notes ? response.data.notes : "";
        return {
          ...p,
          mileage: mileage,
          condition_id: condition_id,
          notes: notes,
          part1DetailsUpdate: true
        }
      }
      );
    }
    ).catch((error) => {
      console.log(error);
      setForm((p)=>{
        return {
          ...p,
          part1DetailsUpdate: false
        }
      })
    }
    ).finally(() => {
      setIsLoading(false);
    }
    );
  }
  
  function updateVin() {
    
    let params = new URLSearchParams();
    params.append("vin", form.vin);
    params.append("mileage", form.mileage);
    params.append("zip_code", userData.zip_code);
    // params.append("asking_price", form.asking_price);
    setIsErrorVin("");
    setIsSuccess("");
    axios({
      method: "POST",
      url: baseURL + "/seller/vin-kbb",
      headers: {
        'Authorization': userData.token,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: params
    }).then((response) => {
      console.log("response from vin-kbb:", response);
      if(response.data.success === 1){
        setIsSuccess("VIN updated successfully");
      }else{
        setIsErrorVin("VIN not found!");
      }
    }
    ).catch((error) => {
      //console.log(error);
      setIsErrorVin("VIN update failed");

    }
    ).finally(() => {
      setIsLoading(false);
    }
    );
  }


 

  //// update details car to server
  function updateDetails() {
    let params = new URLSearchParams();
    params.append("mileage", form.mileage);
    params.append("condition_id", form.condition_id);
    params.append("notes", form.notes);
    params.append("asking_price", form.asking_price);
 
    setIsSuccess("");
    setIsError("");
    axios({
      method: "POST",
      url: baseURL + "/seller/car/details",
      headers: {
        'Authorization': userData.token,
        'Content-Type':  'application/x-www-form-urlencoded'
      },
      data: params
    }).then((response) => {
      setIsSuccess("Successfully updated details");
    }
    ).catch((error) => {
      setIsError("Failed to update details");

    }
    ).finally(() => {
      setIsLoading(false);
    }
    );
  }





  if (isLoading) {
    return <Loading>Showing Details...</Loading>;
  }

  return (
    <>
      <div className="header-text-details-speed">Update Details</div>
      <div>
      {isError !== "" && <div className="error-message">{isError}</div>}
      {isSuccess !== ""  && <div className='bg-color-success rounded text-center text-white py-2'>{isSuccess}</div>}
      {isErrorVin !== "" && <div className="error-message my-2">{isErrorVin}</div>}
      {isSuccessVin !== ""  && <div className='bg-color-success rounded text-center text-white py-2'>{isSuccessVin}</div>}
      </div>
      <div>
        <InputBotBoder value={form.mileage} onChange={(e)=>{setForm((p)=>{return {...p,mileage:parseInt(e.target.value) || 0}})}} 
        label={<b style={{ color: "#030229" }}>Current Mileage</b>} placeholder="Current Mileage" />
      </div>
      <div >
        <label style={{ color: "#030229", fontWeight: "bold" }} className='input-bot-label '>Car condition</label>
        <RadioBoder checked={form.condition_id === 1} onChange={(e)=>{setForm((p)=>{return {...p,condition_id:1}})}} id="condition_id" name="condition_id" label="Excellent" description="Like new condition, no issues" />
        <RadioBoder checked={form.condition_id === 2} onChange={(e)=>{setForm((p)=>{return {...p,condition_id:2}})}}  id="condition_id" name="condition_id" label="Good" description="Few minor defects,no major issues" />
        <RadioBoder checked={form.condition_id === 3} onChange={(e)=>{setForm((p)=>{return {...p,condition_id:3}})}}  id="condition_id" name="condition_id" label="Fair" description="Possible major issues" />
      </div>
      <div>
        <TextAreaBoder rows={5} value={form.notes} onChange={(e)=>{setForm((p)=>{return {...p,notes:e.target.value}})}}  label={<b style={{ color: "#030229" }}>Seller Notes</b>} placeholder="Provide more details about your car, condition, maintenance and services, etc" />
      </div>
      <div>
        <InputBotBoder  value={form.vin} onChange={(e)=>{setForm((p)=>{return {...p,vin:e.target.value}})}}  label={<b style={{ color: "#030229" }}>VIN Number</b>} placeholder="VIN Number" />
      </div>
      <div>
        <InputBotBoder value={form.asking_price === 0 ? "" : form.asking_price} onChange={(e)=>{setForm((p)=>{return {...p,asking_price:parseInt(e.target.value) || 0}})}}  label={<b style={{ color: "#030229" }}>Asking Price</b>} placeholder="What do you think your car is worth? (optional)" />
      </div>
      <div className="mt-auto">
        <LongButtonModel colors={["#FFFFFF", "#B62949"]} onClick={()=>{updateDetails();updateVin();}}>UPDATE</LongButtonModel>
      </div>
     
    </>
  )
}

export default FormDetails