import InputCalcSpeed from 'component/componenetInput/InputCalcSpeed'
import LongButtonModel from 'component/componenetInput/LongButtonModel';
import { ModalCarContext } from 'component/searchCars/Context/ModalCarContext';
import { useContext, useState } from 'react'
import { Col } from 'react-bootstrap'
import { validateEmail } from 'utils/validateUtil';
import axios from 'axios';
import { baseURL } from 'config';
import TextAreaBoder from 'component/componenetInput/TextAreaBoder';
import { UserDataContext } from 'App';
import Loading from 'component/Loading';

const MoreInfo = () => {
    const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
    const userData = useContext(UserDataContext)[0];

    // { name: String, email: String, phone: String, message: String }
    const [form, setForm] = useState({
        name: modalCar?.user_name || "",
        email: modalCar?.user_email || "",
        phone: modalCar?.user_phone || "",
        message: ""
    });
    const [invalidForm, setInvalidForm] = useState({
        name: "",
        email: "",
        phone: "",
        message: ""
    });
    const [response, setResponse] = useState({
        status: null,
        message: ""
    });


    function handleFormChange(e) {
        setInvalidForm({
            ...invalidForm,
            [e.target.name]: ""
        });
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }

    function handleSubmitRequest() {
        if (form.visit_date === "" || form.name === "" || form.message === "" || !validateEmail(form.email)) {
            let newInvalid = {}
            form.visit_date === "" && (newInvalid["visit_date"] = "Visit date is required!");
            form.name === "" && (newInvalid["name"] = "Name is required!");
            !validateEmail(form.email) && (newInvalid["email"] = "Invalid email format!");
            form.message === "" && (newInvalid["message"] = "Message is required!");
            setInvalidForm({
                ...invalidForm,
                ...newInvalid
            });
            return;
        }

        setResponse({ status: "loading" });
        apiRequestMoreInfo({
            code: modalCar?.code,
            token: userData?.token,
            ...form
        }, (error, data) => {
            setResponse({ status: "done" });
            if (error || data?.success === 0) {
                dispatchModalCar({
                    type: "update",
                    payload: {
                        msg: {
                            type: "error",
                            message: data?.error ?? "Something went wrong! Please try again later.",
                        }
                    }
                });
                return;
            }

            dispatchModalCar({
                type: "update",
                payload: {
                    user_name: form.name,
                    user_email: form.email,
                    user_phone: form.phone,
                    user_message: form.message,
                    currentModal: 'primaryModal',
                    msg: {
                        type: "success",
                        message: "Your request for more info has been submitted successfully! Our team will be in touch with you shortly.",
                        counterLeft:1
                    }
                },
            });
        });
    }

    function handleBackButton() {
        dispatchModalCar({
            type: 'set', payload: {
                currentModal: 'primaryModal'
            }
        });
    }

    return (
        <Col lg={6} xl={4} className="bordered-speed mt-4 col-12">
            <div className="header-speed text-center">
                More Info
            </div>
            <InputCalcSpeed value={form.name} invalid={invalidForm.name} onChange={handleFormChange} name="name" label="Name" placeholder="Enter Name here..." />
            <InputCalcSpeed value={form.email} invalid={invalidForm.email} onChange={handleFormChange} name="email" label="Email Address" placeholder="Enter Email here..." />
            <InputCalcSpeed value={form.phone} invalid={invalidForm.phone} format="phoneNumber" onChange={handleFormChange} name="phone" label="Phone" icon={<small className="text-muted">Optional</small>} placeholder="Enter Phone here..." />
            <TextAreaBoder style={{ background: "#f7f7f8" }} label="Message" onChange={handleFormChange} name="message" placeholder="Type Message here...">
                {invalidForm.message && <div>
                    <div className='italic-info-error ps-1'>
                        {invalidForm.message}
                    </div>
                </div>}
            </TextAreaBoder>
            {response.status === "loading" ? <Loading /> : <LongButtonModel disabled={Object.values(invalidForm).some((i) => i !== "")} onClick={handleSubmitRequest} className="mt-3" colors={["#fff", "#B62949"]}>Request More Info</LongButtonModel>}
            <div className="d-flex justify-content-center"><div onClick={handleBackButton} className="back-btn-tri">Go Back</div></div>
        </Col>
    )
}

export default MoreInfo




///  { name: String, email: String, phone: String, message: String }
export function apiRequestMoreInfo({ code, token, email, name, phone, message }, cb) {
    /// /v1/seller/inventory/:code/request-info
    let params = new URLSearchParams();
    params.append('name', name);
    params.append('email', email);
    phone && params.append('phone', phone);
    params.append('message', message);

    axios({
        method: 'POST',
        url: `${baseURL}/seller/inventory/${code}/request-info`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': token,
        },
        data: params
    }).then((res) => {
        cb(null, res.data);
    }).catch((err) => {
        cb(err);
    });
}