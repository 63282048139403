import axios from 'axios';
import { baseURL } from 'config';

export function fetchProgressVehicle(token,callback) {
    axios({
        method: "GET",
        url: baseURL + "/seller/progress",
        headers: {
            "Authorization": token
        }
    }).then((response) => {
        callback(null,response.data);
    }).catch((error) => {
        callback(error,null);
    });
}