import InputBotBoder from 'component/componenetInput/InputBotBoder'
import RadioBoder from 'component/componenetInput/RadioBoder';
import ButtonBoder from 'component/componenetInput/ButtonBoder';
import React, { useState, useContext } from 'react';
import { ContextForm } from '../contexts/FormContext';
import { AddCarRequest , FetchAuctionProgress  , FetchDataCarMoreAuction} from '../request/AddCarRequest.js'
import { UserDataContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { FcInfo } from '@react-icons/all-files/fc/FcInfo';

const ExtendCarDeatils = () => {
    const [userData, setUserData] = useContext(UserDataContext);
    const { formRegister, appendFormRegister, editFormRegister } = useContext(ContextForm);
    const [form, setForm] = useState({
        mileage: formRegister.mileage ?? '',
        condition_id: formRegister.condition_id ?? '',
        platform: "web",
        asking_price: formRegister.asking_price ?? '',
        phone: formRegister.phone ?? '',
    });
    const navigate = useNavigate();
    const [formError, setFormError] = useState({});

    function validateForm() {
        let error = {};
        if (form.mileage === "") error.mileage = "Mileage is required";
        if (form.condition_id === "") error.condition_id = "Condition is required";
        if (form.asking_price === "") error.asking_price = "Asking price is required";
        if (isNaN(parseInt(form.asking_price))) error.asking_price = "Asking price must be a number";


        setFormError(error);
        if (Object.keys(error).length > 0) return false;
        return true;
    }

    function handleSubmit() {
        if (!validateForm()) return;
        appendFormRegister({ ...form, loading: true }, (data) => {
            const { year, make, model, trim, mileage, condition_id,asking_price,
                 platform, vin , vin_id, plate, plate_state ,msrp,type,engine ,phone } = data;
            AddCarRequest( {phone, asking_price,year, make, model, trim, mileage, condition_id, platform, vin , vin_id, plate, plate_state ,msrp,type,engine }, userData.token, responseAddCar);
        });
    }

    function responseAddCar(error, data) {
        if (error) throw error;
        if (data.success === 0) {
            editFormRegister("errorMessage",data.error);
        }else{
            const { car_id } = data;
            FetchDataCarMoreAuction(car_id, userData.token,(error,moreData)=>{
                if(error) throw error;
                setUserData({...userData,car:{...moreData,id:car_id}});
                editFormRegister("loading",false);

                setTimeout(()=>{
                    window.location.reload();
                },200);
            });
        }
    }

    return (
        <>
            {formRegister?.errorMessage !== "" && <div className="warning-error">{formRegister.errorMessage}</div>}
            <div className="my-3">
                <InputBotBoder invalid={formError.mileage} value={form.mileage} type="number" onChange={(e) => { setForm((i) => { return { ...i, mileage: e.target.value } }) }} placeholder="Current Mileage" label="Current Mileage" />
            </div>
            <div className="my-3">
                <div className='input-bot-label'>
                    Car Condition*
                </div>
                <RadioBoder name="condition_id" value="1" defaultChecked={form.condition_id === "1"} onChange={(e) => { setForm(i => { return { ...i, condition_id: e.target.value } }) }} label="Excellent" description="Like new condition, no issues" />
                <RadioBoder name="condition_id" value="2" defaultChecked={form.condition_id === "2"} onChange={(e) => { setForm(i => { return { ...i, condition_id: e.target.value } }) }} label="Good" description="Few minor defects,no major issues" />
                <RadioBoder name="condition_id" value="3" defaultChecked={form.condition_id === "3"} onChange={(e) => { setForm(i => { return { ...i, condition_id: e.target.value } }) }} label="Fair" description="Possible major issues" />
                {formError.condition_id !== "" && <p className="italic-info-error">{formError.condition_id}</p>}
            </div>
            <div className="my-3">
                <InputBotBoder invalid={formError.asking_price} value={form.asking_price} type="number" onChange={(e) => { setForm((i) => { return { ...i, asking_price: e.target.value } }) }} placeholder="Asking Price" label="Asking Price" />
            </div>

            
            <div className="my-3">
                <div className='input-bot-label'>Phone (Optional)</div>
                <div className="input-calc-speed">
                    <MaskedInput placeholder="Enter Phone Number" onChange={(e)=>setForm(i => { return { ...i, phone:e.target.value}})} value={form.phone || ""} className='input-no-decoration' mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} />
                </div>
                <div className='italic-info d-flex'>
                    <FcInfo className="me-1" style={{ fontSize: "30px" }} />
                    <div>Putting your number in will give you
                    real-time alerts for any updated bids or
                    messages regarding your auction.</div>
                </div>
            </div>
            <ButtonBoder onClick={handleSubmit} label="Next" className="mt-3" />
        </>
    )
}

export default ExtendCarDeatils