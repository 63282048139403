// @ts-nocheck
import React from 'react'
import { Form } from 'react-bootstrap'

const SelectBotBoder = React.forwardRef( (props,ref) => {


    var {children,defaultValue,placeholder,label,labelOptions,valueOptions,selected,invalid,...attrProps} = props;

    valueOptions ??= [];
    labelOptions ??= [];

    
    return (
      <>
       <label className="input-calc-speed-label mb-1">{label}</label>
          <Form.Select defaultValue={(typeof defaultValue !== 'undefined' ? defaultValue : "")} className='select-bot-bodar' ref={ref} {...attrProps}>
          <option value=""  disabled>{placeholder}</option>
            {labelOptions.map((item,index)=>{
                return <option key={index} value={valueOptions[index]}>{item}</option>
            })}
          </Form.Select>
          {invalid !== "" &&  <p className="italic-info-error">{invalid}</p>}
      </>
    )
  }
);

export default SelectBotBoder