// @ts-nocheck
/// lib/s
import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './component/layout/NavBar';
//import SideBar from './component/layout/SideBar';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// main style
import './App.css';


import { setCookie } from 'utils/cookieUtil';
import { projectMode } from 'config';


// PageComponent
import { ChangePassword } from './component/ChangePassword';

import Login from './component/Login';
import ForgetPassword from './component/ForgetPassword';
import Profile from './component/Profile';
import VinRegister from './component/register/wayRegister/VinRegister';
import Register from './component/register/Register';
import { MessageLayout } from './component/messages/MessageLayout';
import PlateRegister from './component/register/wayRegister/PlateRegister';
import ManualRegister from './component/register/wayRegister/ManualRegister';
import Home from './component/home/Home';
import IndexDetails from 'component/configDetails/IndexDetails';

import MainSearch from 'component/searchCars/MainSearch';
import TestGround from 'component/TestGround';
import NegotiationsRoute from 'component/negotiations/NegotationsRoute';
import WindowPostMessage from 'component/WindowPostMessage';
import SideBarStar from 'component/layout/SideBarStar';
import HomeStar from 'component/homeStar/HomeStar';
import FilterContext from 'component/searchCars/Context/FilterContext';




// socket io lib


export const UserDataContext = React.createContext(); // create Contect for userData
export const SideBarVisibility = React.createContext(); // create Contect for sidebar
export const SocketDataContext = React.createContext(); // create Contect for socket


// 
const localData = JSON.parse(window?.localStorage?.['userData'] || "{}"); // load userData in local if already logged!
//const localData = JSON.parse(getCookie('userData') || "{}"); // load data from cookie in local if already logged! 


function App() {
 
  const [userData, setUserData] = useState(localData); // use hook for data
  const [sideBarVisibility, setSideBarVisibility] = useState({
    show:true,
    isMobile: window.innerWidth < 765
  });
  const [socketData, setSocketData] = useState({});
  const location = useLocation();

  function reSizeWindowsEvent() {
    if (window.innerWidth < 765) {
      setSideBarVisibility({
        show: false,
        isMobile: true
      });
    } else {
      setSideBarVisibility({
        show: true,
        isMobile: false
      });
    }
  }

  useEffect(() => {
    //console.clear();
    if (userData.isOnline && userData.isOnline === true) {
      window.localStorage.clear();// clear local storage
    }
    const isProfileCompleteBaseEmail = !(userData.email === "" || userData.email === null || (typeof userData.email === 'undefined'));
    userData['isProfileCompleted'] = isProfileCompleteBaseEmail;
    window.localStorage['userData'] = JSON.stringify(userData); // load data from localStorage
    setCookie('userData', JSON.stringify(userData), 3600); // set data to cookie
    window.onresize = reSizeWindowsEvent; // event listen
    reSizeWindowsEvent(); // init at startup
    return () => { }
  }, [userData]);

  useEffect(() => {
    setSideBarForMobile(false);
  },[location]);

  function setSideBarForMobile(isVisible) {
    if (window.innerWidth < 765) {
      setSideBarVisibility({
        show: isVisible,
        isMobile: true
      });
    }else{
      setSideBarVisibility({
        show: true,
        isMobile: false
      });
    }
  }


  return (
    <SocketDataContext.Provider value={{ socketData, setSocketData }}>
      <SideBarVisibility.Provider value={[sideBarVisibility, setSideBarForMobile]}>
        <UserDataContext.Provider value={[userData, setUserData]}>

          {/* Usage to comunicate with top iframe website if is in iframe */}
          <WindowPostMessage />

          {userData.isOnline && <div className="grid-container">
            {/* NavBar */}
            <div className="navbar-grid"><NavBar /></div>

            {/* SideBar */}
            {/* {sideBarVisibility && */}
             <div className={"menu-grid "+(sideBarVisibility.show ? "show" :"")}>
              {/* <SideBar /> */}
              <SideBarStar/>
            </div>
              {sideBarVisibility.show && sideBarVisibility.isMobile &&<div onClick={()=>setSideBarForMobile(false)} className="shadow-backdrop"></div>}
            {/* } */}

            <div className="content-grid">
              <Routes>
                {/* Main Car Details/HomePage */}
                  <Route path={"/"} element={<HomeStar />} />
                  <Route path={"/home"} element={<HomeStar />} />
                  <Route path={"/mainhome"} element={<Home />} />


                {userData.car && <>
                  {/* Car Details */}
                  <Route path="/car-details" element={<IndexDetails />} />

                  {/* Messages */}
                  <Route path="/messages" element={<MessageLayout />} />
                </>}

                {/* Main Profile/edit/change */}
                <Route path="/profile" element={<Profile />} />
                <Route path="/change-password" element={<ChangePassword />} />




                {/* Search for cars */}
                <Route path="/car-search" element={<FilterContext><MainSearch /></FilterContext>} />
                {/* negotiation */}
                <Route path="/negotiations" element={<NegotiationsRoute />} />

                {/* DEV */}
                {projectMode === "DEV" && <Route path="/test" element={<TestGround />} />}


                {/* 404 error */}
                <Route path='*' status={404} element={<Navigate to="/home" replace />} />

              </Routes>
            </div>
          </div>
          }

          {!userData.isOnline && <>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/forgot-pasword" element={<ForgetPassword />} />

              {/* Register */}
              <Route path="/register" element={<Register />} />
              {/* Register ways  */}
              <Route path="/register/vin" element={<VinRegister />} />
              <Route path="/register/plate" element={<PlateRegister />} />
              <Route path="/register/manual" element={<ManualRegister />} />

              <Route path="*" status={404} element={<Navigate to="/" replace />} />

            </Routes>
          </>}

        </UserDataContext.Provider>
      </SideBarVisibility.Provider>
    </SocketDataContext.Provider>
  )


}

export default App