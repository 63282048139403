import axios from "axios";
import { baseURL} from "config";


export function apiFetchCarDetails(token,carId,cb){
        axios({
            method: "GET",
            url: baseURL + "/car/details/" + carId,
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            cb(null,response.data);
        }).catch((error) => {
            cb(error,null);
        });
}