import React from 'react'
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
const ProgressCircle = ({progress,label,onclick,className,pathColor}) => {
    progress ??= 0.45;
    label ??= "[No Label]";
    onclick ??= ()=>{console.log("No onclick")};
    pathColor ??= "#B72949";
    return (
        <div onClick={onclick} className={className+" c-pointer flex-centered"}>
                <CircularProgressbar strokeWidth={10} styles={buildStyles({
                    pathColor: pathColor,
                    textColor: "#000000",
                })}
                value={(Math.floor(progress * 100))}
                text={`${Math.floor(progress * 100)}%`}/>
            
                  <div style={{ cursor: "pointer",fontSize:"14px" ,textAlign:"center" }} className='fw-bold '>
               {label}
            </div>
        </div>
    );
}

export default ProgressCircle