import React from 'react'

const ButtonOutlineBoder = React.forwardRef((props,ref) => {
    const {label,children,className,isactive,...attrProps } = props;
    return (
        <>
            <button ref={ref} {...attrProps} className={"button-outline-boder "+(className || "")+" "+(isactive ? "active" : "")}>{label}</button>
            {children}
          </>
    )
  })

export default ButtonOutlineBoder