// @ts-nocheck
import axios from 'axios';
import { baseURL } from 'config';
import React, { useContext, useEffect, useState } from 'react'



import { Form } from 'react-bootstrap';
import { UserDataContext } from '../App';
import Loading from './Loading';


export const ChangePassword = () => {
    var [userData] = useContext(UserDataContext);

    const [hasError,setHasError] = useState("");
    const [hasSuccess,setHasSuccess] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const [form, setForm] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    });

    const [errors, setErrors] = useState({});

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });
    }


    const handleSubmit = e => {
        e.preventDefault();
        var { currentPassword, newPassword, confirmPassword } = form;
        setErrors({
            "confirmPassword": (confirmPassword !== newPassword ? "Confirm password not maching with new password!" : null),
            //"newPassword": (!newPassword || newPassword.length < 6 ? "Your new password must be at least 6 characters long." : null),
            "newPassword": (!newPassword || newPassword === '' ? "New Password cannot be empty!" : null),
            "currentPassword": (!currentPassword || currentPassword === '' ? "Empty current Password" : null)
        });
    }
    useEffect(() => {
        if (Object.keys(errors).length === 0) return;
        let errorsLength = Object.values(errors).filter((r) => r).length;
        
        if (errorsLength === 0) {
            sendRequestChange();
        }
    }, [errors]);


    function sendRequestChange() {
        const params = new URLSearchParams()
        params.append("current", form.currentPassword);
        params.append("new", form.newPassword);

        setIsLoading(true);
        setHasError("");
        setHasSuccess("");
        axios({
            method: "POST",
            url: baseURL + "/seller/password/change",
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': userData.token
            }
        }).then((response) => {
            if(response.data.success === 0){
                //setHasError("Current password incorrect!");  
                setErrors({
                    ...errors,
                    "currentPassword":"Current password incorrect!"
                });              
            }else{
                setHasSuccess("The password was successfully updated.")
            }
        }).catch((error)=>{
            setHasError("Something went wrong direction!");             
        }).finally(()=>{
            setIsLoading(false);
        });
    }

    if(isLoading){
        return <Loading>Changing password...</Loading>
    }
   

    return (
        <div className='container mt-5'>
            <div >
                <Form className='d-flex justify-content-center'>
                    <div className='col-12 col-lg-4'>
                        <h4 className='mb-4 text-center'>Change Password</h4>

                        { hasError !== "" && <div className="error-message">{hasError}</div>}
                        { hasError === "" && hasSuccess && <div className='bg-color-success rounded text-center text-white py-2'>{hasSuccess}</div>}

                        <Form.Group>
                            <Form.Label>Current password</Form.Label>
                            <Form.Control isInvalid={!!errors.currentPassword} className="input-control" onChange={(e) => setField('currentPassword', e.target.value)} type="password" placeholder="Current password" />
                            <Form.Control.Feedback type="invalid">
                                {errors.currentPassword}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>New password</Form.Label>
                            <Form.Control isInvalid={!!errors.newPassword} className="input-control" onChange={(e) => setField('newPassword', e.target.value)} type="password" placeholder="New password" />
                            <Form.Control.Feedback type="invalid">
                                {errors.newPassword}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>Confirm password</Form.Label>
                            <Form.Control className="input-control" isInvalid={!!errors.confirmPassword} onChange={(e) => setField('confirmPassword', e.target.value)} type="password" placeholder="Confirm password" />
                            <Form.Control.Feedback type="invalid">
                                {errors.confirmPassword}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <button className='danger-button mt-4' onClick={(e) => handleSubmit(e)}>UPDATE</button>

                    </div>
                </Form>
            </div>
        </div>
    )
}
