import axios from "axios";
import { baseURL } from "config.js";


let controller = new AbortController();

export function fetchSelectedDetails(token,code,callback = ()=>{}) {
    if(!code) return ;
    if(controller) controller.abort();
    controller = new AbortController();
    axios({
        method: "GET",
        url: baseURL + "/seller/negotiation/"+code,  //code:2c0149528400c8cb7b8baec754f21e75
        headers: {
            'Authorization': token,
        },
        signal: controller.signal
    }).then(response=>{
        callback(null,response.data,code);
    }).catch(error=>{
        if(error.name === "AbortError" || error.name === "CanceledError"){
            return;
        }
        callback(error,null,code);
    });
}