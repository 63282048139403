// @ts-nocheck
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { SocketDataContext, UserDataContext } from '../../App';
import { baseURL, socketURL } from '../../config';
import { ChatMessage } from './ChatMessage'
import Loading from '../Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faRotateLeft, faArrowCircleLeft, faArrowCircleRight, faComment, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '../../utils/generalUtil';

import io from 'socket.io-client';
import ButtonBoder from 'component/componenetInput/ButtonBoder';
import { useLocation } from 'react-router-dom';
import ModalAproveBid from './messageFunctions/ModalAproveBid';
import ModalCounterBid from './messageFunctions/ModalCounterBid';
import HearRefferal from 'component/HearRefferal/HearRefferal';
import { BsFillPersonFill } from 'react-icons/bs';



var tempBid_id = null; // selected bid id, usage in counter bids without using state

const MessageLayout = () => {
    const location = useLocation();
    // console.log("locaiton.state:",location.state);
    const {socketData, setSocketData} = useContext(SocketDataContext);
    const [userData, setUserData] = useContext(UserDataContext); // user token and data
    const [activeTab, setActiveTab] = useState(0); // control view in mobile
    const [isLoading, setIsLoading] = useState(true); // show loading screen
    const [dealersInfo, setDealersInfo] = useState([]); // list of dealers object
    const [searchInput, setSearchInput] = useState(""); // search input
    const [selected, setSelected] = useState(null); // chat selected
    const [displayHeardRefferal, setDisplayHeardRefferal] = useState({display:false,onComplete:()=>{}}); // display heard refferal
    const [chatData, setChatData] = useState({
        loading: null,
        chatMessages: [],
        roomData: {}
    }); // chat messages 
    const [bids, setBids] = useState([]);
    const refSearchInput = useRef(null)


    // Request: {first_name: String, last_name: String, email: String, phone: String, zip_code: String, bid_id: Number }
    let isCompletedFirstName = userData.first_name === 'Anonymous';
    const [formComplete, setFormComplete] = useState({
        isComplete: isCompletedFirstName,
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        zip_code: "",
        bid_id: ""
    });

    useEffect(()=>{
        // console.log("refSearchInput:",refSearchInput);
        if(refSearchInput.current){
            refSearchInput.current.addEventListener("keyup", searchFromList);
        }
        return ()=>{
            if(refSearchInput.current){
                refSearchInput.current.removeEventListener("keyup", searchFromList);
            }
        }
    },[refSearchInput?.current]);



    function searchFromList(){
        // query all row-container-op1 and hide them if not match with value of refSearchInput.current.value 
        const list = [...document.querySelectorAll(".row-container-op1")];
        list.forEach((parent)=>{
            let item = parent.querySelector(".title");
            if(item.innerText.toLowerCase().indexOf(refSearchInput.current.value.toLowerCase())===-1){
                parent.style.display = "none";
            }else{
                parent.style.display = "";
            }
        });
    }

    const { ModalAprovejsx, handleShowConfirmModal } = ModalAproveBid();
    const { ModalCounterjsx, showCounterModal } = ModalCounterBid();

    useEffect(() => {
        setUserData((p) => {
            return { ...p, newMessage: 0 };
        });
        // get chat dealers rooms
        fetchRooms();
        // get bids list
        fetchBids();
    }, []);
    function fetchBids(){
        if(!userData?.car)  return ; // stop no car is set
        axios({
            method: "GET",
            url: baseURL + "/seller/bids",
            headers: {
                'Authorization': userData.token
            }
        }).then((response) => {
            setBids([...response.data]);
        });
    }

    function fetchRooms() {
        if(!userData?.car)  return ; // stop no car is set
        axios({
            method: "GET",
            url: baseURL + "/seller/rooms",
            headers: {
                "Authorization": userData.token
            }
        }).then((response) => {
            setDealersInfo(() => {
                return response.data;
            }); // set Dealrs Info into array
        }).catch((error) => {
            setDealersInfo([]);
        }).finally(() => {
            setIsLoading(false);
        });
    }
    useEffect(()=>{
        if(selected) return;


        if(location.state?.showTarget){
            let target = dealersInfo.find((dealer)=>{ return dealer.dealer_name === location.state.showTarget});
            if(target){
                setSelected(target);
                roomInfo(target);
            }
            location.state = null;
            return;
        }

        // get newest dealer from dealerInfo message_date
        let newestDealer = dealersInfo.sort((a, b) => {
            return new Date(b.message_date) - new Date(a.message_date);
        }).slice(0, 1);
        if (newestDealer.length > 0) {
            setSelected(newestDealer[0]);
            roomInfo(newestDealer[0]);
        }

    },[dealersInfo]);

    useEffect(() => {
        const socket = socketData?.socket;
    
        if(!socket) return;

        socket.on('new-bid', function (msg) {
            fetchBids();
            fetchRooms();
        });

        socket.on('new-message', function (msg) {
            
            var split = msg.split("|"); // name|Message|roomid
            let goldenFind = dealersInfo.findIndex((dealer) => {
                return dealer.dealer_name === split[0];
            });
            if (goldenFind === -1) {
                //refetch rooms
                fetchRooms();
            }
            // find the dealer and update new to 1
            setDealersInfo((prevState) => {
                if (selected) {
                    if (selected.room_id === parseInt(split[2])) {
                        return prevState;
                    }
                }
                let findIndex = prevState.findIndex((dealer) => {
                    return dealer.room_id === parseInt(split[2]);
                });
                if (findIndex !== -1) {
                    prevState[findIndex] = { ...prevState[findIndex], new: 1 };
                }
                return [...prevState];
            });

        });
        return () => {
            if(socket.readyState === 1){
                socket.disconnect();
                socket.destroy();
                socket.close();
              }
        }
    }, [socketData?.socket]);

    function roomInfo(item, action) {
        if (action === "RM-LOCATION") {
            location.state = null;
        }
        setSelected(item);
        setActiveTab(1);
        setUserData((prevState) => {
            return {
                ...prevState,
                newMessage: 0,
                newMessageFrom: [],
                newBid: 0,
                statusNotification: []
            }
        });

        // find item in dealersInfo and repace new 1 to new 0
        setDealersInfo((prevState) => {
            let newDealersInfo = [...prevState];
            let findIndex = newDealersInfo.findIndex((dealer) => {
                return dealer.dealer_name === item.dealer_name;
            });
            newDealersInfo[findIndex].new = 0;
            return newDealersInfo;
        });


        setChatData({
            loading: true,
            chatMessages: [],
            roomData: {}
        });

        /// 
        axios({
            method: "GET",
            url: baseURL + "/seller/messages/" + item.room_id + ",0",
            headers: {
                'Authorization': userData.token,
            }
        }).then((response) => {
            let chatMessagesArray = response.data;
            chatMessagesArray.reverse();
            setChatData({
                loading: false,
                chatMessages: chatMessagesArray,
                roomData: item
            });

        }).catch((error) => {
            setChatData({
                loading: false,
                chatMessages: [],
                roomData: {}
            });
        });
        ///
    }



    function completeDetails() {
        /// Request: {first_name: String, last_name: String, email: String, phone: String, zip_code: String, bid_id: Number }
        let params = new URLSearchParams();
        params.append("first_name", formComplete.first_name);
        params.append("last_name", formComplete.last_name);
        params.append("email", formComplete.email);
        params.append("phone", formComplete.phone);
        params.append("zip_code", formComplete.zip_code);
        params.append("bid_id", tempBid_id);
        axios({
            method: "POST",
            url: baseURL + "/seller/complete",
            headers: {
                'Authorization': userData.token,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            console.log("response complete:", response.data);
        }).catch((error) => {
            console.log("complete details error:", error)
        });

    }



    


    if (isLoading) return <Loading>Getting chat messages...</Loading>


    let bidsFilter = selected ? bids.filter((r) => r.dealer_name === selected.dealer_name) : [];
    bidsFilter.sort((a, b) => b.bid_amount - a.bid_amount);

    let filterdealersInfo = [...dealersInfo];

    let isAnotherWinner = false;
    // get winner from bids
    let winner = bids.find((r) => r.winner === 1);
    if (winner) {
        if (!bidsFilter.includes(winner)) {
            isAnotherWinner = true;
        }
    }



    return (<>
        {/* For mobile view port  */}
        <div className='mobile-navigator'>
            {/*  when is chat active */}
            {activeTab === 1 && <div className='c-pointer' onClick={() => setActiveTab(0)}><FontAwesomeIcon className="mx-2" icon={faAngleLeft} />List</div>}
            {activeTab === 1 && <div className='c-pointer' onClick={() => setActiveTab(2)}>Bids <FontAwesomeIcon icon={faAngleRight} /></div>}
            {activeTab === 2 && <div className='c-pointer' onClick={() => setActiveTab(1)}><FontAwesomeIcon icon={faAngleLeft} /> Chat</div>}
        </div>
        <Row className="h-100 position-relative pb-3">
            <Col md={3} className={"list-users flex-column d-xl-flex sidebar-border-coos px-0 col-xl-3 " + (activeTab === 0 ? 'active' : '')}>
            <div className='list-chat-search-speed'>
                <div className="title">Messages</div>
                        <div><input ref={refSearchInput} className="search-chat" placeholder="Search" type="text"/></div>
                </div>
                <div className="scroll-as-height">
                    {filterdealersInfo.map((item, index) => {
                        let bidLengthForDealer = bids.filter((itemBid, index) => itemBid.dealer_name === item.dealer_name).length;
                        if (location.state && location.state.dealer_name) {
                            if (location.state.dealer_name === item.dealer_name) {
                                // execute roomInfo function with item as argument once on render
                                roomInfo(item, "RM-LOCATION");
                            }
                        }

                        return (
                        <div onClick={() => { roomInfo(item) }} key={index} className={'row-container-op1 ' + (selected?.room_id === item.room_id ? 'active' : '')}>
                            <div className="profile-image-row-container"><BsFillPersonFill /></div>
                            <div className="flex-grow-1">
                                <div className='name-of-dealer title'>{item.dealer_name}</div>
                                <div className='bids-count'>{bidLengthForDealer > 0 ?
                                    <>{bidLengthForDealer} Offer{(bidLengthForDealer > 1 && "s")}</>
                                    : "No Offers"}</div>
                            </div>
                            <div className='right-side-content'>
                                <div className='time-message'>{(new Date(item.message_date)).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: "numeric" })}</div>
                                {item.new > 0 ? <div><span className='circle-messages'></span></div> : <div>&nbsp;</div>}
                            </div>
                        </div>)
                    })}

                </div>
            </Col>
            <Col className={"chat-user " + (activeTab === 1 ? 'active' : '')}>
                {chatData.isLoading === true && <Loading />}
                <ChatMessage  {...chatData} selected={selected} setChatData={setChatData} />
            </Col>
            <Col md={2} className={"user-bids scroll-as-height flex-column h-100 bid-column-container " + (activeTab === 2 ? 'active' : '')}>
                {bidsFilter.length > 0 && <>
                    <div className="header-bids">
                        Offers
                    </div>
                    <div className="scroll-as-height">
                        {selected && bidsFilter.map((bid, index) => {
                            return <div key={index}><div className="action-bid-card">
                                <strong style={{ color: "#619c6f" }}>${parseInt(bid.bid_amount).toLocaleString()}</strong>
                                <div className='time-message'>{(new Date(bid.created_date)).toLocaleDateString('en-US', { weekday: 'long', hour: 'numeric', minute: "numeric" })}</div>
                            </div>
                            </div>
                        })}
                    </div>
                    <div>
                        {!isAnotherWinner && <>

                            {bidsFilter[0].winner !== 1 && bidsFilter[0].winner !== 2 && <ButtonBoder onClick={() => {
                                
                                setDisplayHeardRefferal({
                                    display: true,
                                    onComplete: () => {
                                        console.log("onComplete Trigged");
                                        handleShowConfirmModal(bidsFilter[0]);
                                    },
                                    onCancel:()=>{
                                        console.log("onCancel Trigged");
                                        setDisplayHeardRefferal((prevState) => {
                                            return {
                                                ...prevState,
                                                display: false
                                        }});
                                    }
                                });

                            }} style={{ background: "rgb(34 184 89)", marginBottom: "10px" }} label={<>
                                <FontAwesomeIcon className='me-2' size="lg" icon={faCheck} />{"Accept"}
                            </>} />}

                            {bidsFilter[0].winner !== 1 && bidsFilter[0].winner !== 2 && <ButtonBoder onClick={() => {
                                if (bidsFilter.length > 0) {
                                    showCounterModal(bidsFilter[0]);
                                }
                               
                            }} style={{ background: "#B72949" }} label={<>
                                <FontAwesomeIcon className='me-2'  size="lg" icon={faRotateLeft} />{"Counter"}
                            </>} />}

                            {/* Create ButtonBoder disabled with label Winner  if any bid is winner  */}
                            {bidsFilter.length > 0 && bidsFilter[0].winner === 1 && bidsFilter[0].winner !== 2 && <ButtonBoder disabled={true} style={{ background: "#B72949" }} label={<>
                                {"Winner"}
                            </>}
                            />}
                            <ModalAprovejsx callback={()=>{
                                window.location.reload();
                            }} setBids={setBids} bid={bidsFilter[0]} />
                        </>}
                    </div>
                </>
                }
            </Col>
            <HearRefferal {...displayHeardRefferal}/>

            <ModalCounterjsx callback={(bid, valueCounter) => {
                setChatData((prevChatData) => {
                    return {
                        ...prevChatData,
                        chatMessages: [...prevChatData.chatMessages, {
                            file_name: null,
                            message: "I would like to counter-offer you with $" + parseInt(valueCounter).toLocaleString(),
                            message_date: new Date().toISOString(),
                            message_id: Math.floor(Math.random() * (2 ** 31 - 1)),
                            generated_local: true,
                            you: 1,
                        }]
                    }
                });
            }} />


        </Row>
    </>
    )
}



export { MessageLayout }