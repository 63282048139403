// @ts-nocheck
import axios from 'axios';
import { baseURL } from 'config';
import React, { useContext, useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { UserDataContext } from '../App';
import { validateEmail } from '../utils/validateUtil';
import ButtonBoder from './componenetInput/ButtonBoder';
import InputBotBoder from './componenetInput/InputBotBoder';
import Loading from './Loading';
import ModalPortal from './componenetInput/ModalPortal';
import MaskedInput from 'react-text-mask';
const ConfirmDeleteAccount = () => {
    const [userData, setUserData] = useContext(UserDataContext);
    const [showModal, setShowModal] = useState(false);

    function removeAccount() {
        axios({
            method: "POST",
            url: baseURL + "/seller/unlist",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': userData.token
            }
        }).then((request) => {
            setUserData({});
        });
    }
    function RenderModalConfirmRemove() {
        return <ModalPortal>
            <Modal centered show={showModal} onHide={() => { setShowModal(false) }}>
                <Modal.Header>
                    <Modal.Title style={{ color: "red" }}>IMPORTANT!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you wish to remove your account from out platform?
                    <br />
                    You will lose access to your <b>bids</b> and <b>messages</b>.
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <Col>
                        <ButtonBoder style={{ background: '#878787' }} label="Cancel" onClick={() => { setShowModal(false) }} />
                    </Col>
                    <Col>
                        <ButtonBoder label="Yes, Remove" onClick={() => {
                            removeAccount();
                        }} />
                    </Col>
                </Modal.Footer>
            </Modal>
        </ModalPortal>
    }

    return [setShowModal,RenderModalConfirmRemove];

}

const Profile = () => {

    const [isLoading, setIsLoading] = useState(true);
    var [userData, setUserData] = useContext(UserDataContext);
    var [hasError, setHasError] = useState("");
    var [hasSuccess, setHasSuccess] = useState(false);
    const [profile, setProfile] = useState({
        "first_name": "",
        "last_name": "",
        "email": "",
        "phone": "",
        "zip_code": "",
    });
    const [setShowModalRemoveAccount, RenderModalConfirmRemoveAccount] = ConfirmDeleteAccount();

    function fetchProfile() {
        axios({
            method: "GET",
            url: baseURL + "/seller/profile",
            headers: {
                'Authorization': userData.token
            }
        }).then((request) => {
            setProfile(request.data);
            setIsLoading(false);
        }).catch(error => {
            console.error("Error:", error);
        })
    }

    function inputProfileChange(e) {
        setProfile({
            ...profile,
            [e.target.name]: e.target.value ? e.target.value : ""
        });
    }
    function submitProfileUpdate(e) {
        e.preventDefault();
        
        if (!validateEmail(profile.email ? profile.email : "")) {
            setHasError("Invalid email format!");
            return;
        }
        if (typeof profile?.first_name === 'undefined' || profile.first_name === "" || profile.first_name === "null") {
            setHasError("First name is required!");
            return;
        }
        if (typeof profile?.last_name === 'undefined' || profile.last_name === "" || profile.last_name === "null") {
            setHasError("Last name is required!");
            return;
        }
        if(typeof profile?.phone === 'undefined' || profile.phone === "" || profile.phone === "null"){
            setHasError("Phone number is required!");
            return;
        }
        if(typeof profile?.zip_code === 'undefined' || profile.zip_code === "" || profile.zip_code === "null"){
            setHasError("Zip code is required!");
            return;
        }
       
        const params = new URLSearchParams();
        for (var [key, value] of Object.entries(profile)) {
            params.append(key, value);
        }
        setIsLoading(true);
        axios({
            method: "POST",
            url: baseURL + "/seller/profile",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': userData.token
            },
            data: params
        }).then((request) => {
            if (request.data.success == 0) {
                setHasError(request.data.error);
            } else {
                setHasError("");
                setHasSuccess(true);
                setUserData({
                    ...userData,
                    ...profile
                });
            }
        }).catch((error) => {
            setHasError("Something went wrong!");
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        fetchProfile();
    }, []);

    function onChangePhone(e){
        setProfile({
            ...profile,
            phone: e.target.value
        });
    }



    if (isLoading) {
        return <Loading>Loading profile info...</Loading>
    }

    return (
        <div className='container mt-5'>
            <div>
                <Form className='d-flex justify-content-center'>
                    <div className='col-12 col-lg-5 profile-card-o2'>
                        <h4 className='mb-4 text-center'>PROFILE</h4>
                        {hasError !== "" && <div className="error-message">{hasError}</div>}
                        {hasError === "" && hasSuccess && <div className='bg-color-success rounded text-center text-white py-2'>Profile Updated!</div>}
                        <Row>
                            <Col xxl="6">
                                <InputBotBoder labelstyle={{marginTop:"0px"}} name="first_name" onChange={inputProfileChange} value={profile.first_name || ""} className='person-icon-as-background' label='First name' placeholder="First Name" />
                            </Col>
                            <Col xxl="6">
                                <InputBotBoder labelstyle={{marginTop:"0px"}} name="last_name" onChange={inputProfileChange} value={profile.last_name || ""} className='person-icon-as-background' label='Last name' placeholder="Last Name" />
                            </Col>
                        </Row>
                        <div>
                            <InputBotBoder labelstyle={{marginTop:"0px"}} name="zip_code" onChange={inputProfileChange} value={profile.zip_code || ""} className='zip-icon-as-background' label='Zip code' type="number" placeholder='ZIP CODE' />
                        </div>
                        <div>
                            <InputBotBoder labelstyle={{marginTop:"0px"}} name="email" onChange={inputProfileChange} value={profile.email || ""} className='email-icon-as-background' label='Email' placeholder='Your Email' />
                        </div>
                        <div>
                        <label className="input-bot-label" style={{marginTop:"0px"}}>Phone Number</label>
                           <MaskedInput placeholder="Enter Phone Number" onChange={onChangePhone} value={profile.phone || ""} className='input-bot-boder' mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} />
                           <div className='italic-info'>Your phone number will only be used for notifications. We do NOT share your number with dealers</div>
                        </div>
                    
                        <ButtonBoder className="mt-3" onClick={submitProfileUpdate} label="UPDATE PROFILE" />
                    </div>
                </Form>
            </div>
            <div className='text-center row justify-content-center'>
                <div className="col-6">
                    <div style={{ fontSize: '13px', fontStyle: 'italic' }}>
                        If you wish to remove your account and remove your car from out platform please click the button below.
                    </div>
                </div>
                <div className="col-12"></div>
                <div className="col-6 col-lg-3">
                     <button className='remove-account-profile' onClick={()=>{
                        setShowModalRemoveAccount(true);
                    }}>DELETE ACCOUNT</button>
                </div>
            </div>
            <RenderModalConfirmRemoveAccount/>
        </div>
        
    )
}


export default Profile