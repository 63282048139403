import LongButtonModel from 'component/componenetInput/LongButtonModel';
import { Col } from 'react-bootstrap';
import { useContext,  useEffect,  useState } from 'react';
import { ModalCarContext } from 'component/searchCars/Context/ModalCarContext';
import InputCalcSpeed from 'component/componenetInput/InputCalcSpeed';
import SelectBotBoder from 'component/componenetInput/SelectBotBoder';
import { fetchMakerList,fetchModelMaker,fetchTrimsMakerList } from 'component/searchCars/request/apiFetchManualValues';

export default function TradeManual() {
    const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
    const [form, setForm] = useState({
        year:"",
        make:"",
        model:"",
        trim:"",
    });
    const [hasError, setHasError] = useState("");
    const [listMake, setListMake] = useState([]);
    const [listModel, setListModel] = useState([]);
    const [listTrim, setListTrim] = useState([]);
    let contextTrade = modalCar?.trade || {};
    contextTrade = contextTrade?.tradeType == "manual" ? contextTrade : {};
    useEffect(() => {
        dispatchModalCar({
            type: "set-action", payload: {
                backButton: function () {
                    dispatchModalCar({
                        type: "update", payload: {
                            currentModal: "secondaryModal",
                            currentSubModal: "tradeByType"
                        }
                    });
                }
            }
        });
        return () => {
            dispatchModalCar({
                type: "set-action", payload: {
                    backButton: null
                }
            });
        }
    }, []);

    const onSelectYear = (e) => {
        
        fetchMakerList(e.target.value, (err, data) => {
            if (err) return;
            setForm({ ...form, year: e.target.value });
            setListMake(data);
        });
    }
    const onSelectMake = (e) => {
        fetchModelMaker(form.year, e.target.value, (err, data) => {
            if (err) return;
            setForm({ ...form, make: e.target.value });
            setListModel(data);
        });
    }
    const onSelectModel = (e) => {
        fetchTrimsMakerList(form.year, form.make, e.target.value, (err, data) => {
            if (err) return;
            setForm({ ...form, model: e.target.value });
            setListTrim(data);
        });
    }

    function handleContinueButton(){
        if(form.year === ""){
            setHasError("Please select a year");
            return;
        }
        if(form.make === ""){
            setHasError("Please select a make");
            return;
        }
        if(form.model === ""){
            setHasError("Please select a model");
            return;
        }
        dispatchModalCar({
            type: "trade-set", payload: {
                tradeType: "manual",
                ...form
            }
        });
        dispatchModalCar({
            type: "update", payload: {
                currentSubModal: "tradeDetails"
            }
        });
    }

    


   
   
    return <>
        <Col lg={6} xl={4} className="col-12 bordered-speed mt-4 d-flex justify-content-center flex-column">
            <div className="header-speed text-center">
                Trade-in by Manual
            </div>

            <div className='parents-select-modifier'>
                <SelectBotBoder onChange={onSelectYear} defaultValue={form.year} label="Year" placeholder="Choose Year" labelOptions={arrayYearList} valueOptions={arrayYearList} />
         
                <SelectBotBoder onChange={onSelectMake} defaultValue={form.make} label="Make" disabled={listMake.length === 0} placeholder="Choose Make" labelOptions={listMake} valueOptions={listMake} />
            
                <SelectBotBoder onChange={onSelectModel} defaultValue={form.model} label="Model" disabled={listModel.length === 0} placeholder="Choose Model" labelOptions={listModel} valueOptions={listModel} />
            
                <SelectBotBoder onChange={(e) => setForm({ ...form, trim: e.target.value })} style={{ maxWidth: "350px" }} label="Trim" defaultValue={form.trim} disabled={listTrim.length === 0} placeholder="Choose Trim" labelOptions={listTrim} valueOptions={listTrim} />
            </div>
         

            <div style={{ minWidth: "35%", marginTop: "25px" }}>
                <LongButtonModel onClick={handleContinueButton} colors={["#FFFFFF", "#B72949"]} className="my-2">Continue</LongButtonModel>
            </div>
            <div className="d-flex text-center justify-content-center">
                <div onClick={modalCar?.actions?.backButton} className="back-btn-tri">Go Back</div>
            </div>
        </Col>

    </>
}


let arrayYearList = Array.from({ length: ((new Date()).getFullYear() + 1) - 1990 }).map((item, index) => 1990 + index);
arrayYearList.reverse();