import ButtonBoder from 'component/componenetInput/ButtonBoder'
import InputBotBoder from 'component/componenetInput/InputBotBoder'
import ModalPortal from 'component/componenetInput/ModalPortal'
import React from 'react'
import { forwardRef, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios'
import { baseURL } from 'config.js';
import RadioBoder from 'component/componenetInput/RadioBoder';
import { FaSpinner } from '@react-icons/all-files/fa/FaSpinner';
import { useContext } from 'react';
import { UserDataContext } from 'App.js';
import SelectBotBoder from 'component/componenetInput/SelectBotBoder';
import { fetchMakerList, fetchModelMaker, fetchTrimsMakerList } from 'component/searchCars/request/apiFetchManualValues';

const dd = console.log;

const TradeInModal = forwardRef(({ state, reload }, ref) => {
    const [title, setTitle] = useState("");
    const userData = useContext(UserDataContext)[0];
    const [modalConfig, setModalConfig] = useState({ show: false, step: "chooseTrade" });
    const [form, setForm] = useState({
        tradeType: null,
        carDetails: {},
        carStatus: {}
    });

 

    function modalShow(option) {
        setModalConfig((prevState) => ({
            ...prevState,
            show: option
        }));
    }

    function chosenTrade(option) {
        setForm((prevState) => ({
            ...prevState,
            tradeType: option
        }));
    }

    function setCarDetails(data) {
        setForm((prevState) => ({
            ...prevState,
            carDetails: data
        }));
    }

    function setCarStatus(data) {
        setForm((prevState) => ({
            ...prevState,
            carStatus: data
        }));
    }

    function changeStep(step) {
        setModalConfig((prevState) => ({
            ...prevState,
            step: step
        }));
    }

    function finalSubmit(cb = () => { }) {
        dd("Final Submit has been Called!");
        let finalePayload = {
            "trade_method": form?.tradeType,
            "trade_mileage": form?.carStatus.currentMileage,
            "trade_condition_id": form?.carStatus.carCondition,
            "trade_loan_balance": form?.carStatus.carPayLoanBalance,
            "trade_asking_price": form?.carStatus.carWorth,
            "trade_vin": form?.carDetails?.vin,
            "trade_vin_id": form?.carDetails?.vin_id,
            "trade_year": form?.carDetails?.year,
            "trade_make": form?.carDetails?.make,
            "trade_model": form?.carDetails?.model,
            "trade_trim": form?.carDetails?.trim,
            "trade_type": form?.carDetails?.type,
            "trade_engine": form?.carDetails?.engine,
            "trade_plate": form?.carDetails?.plate,
            "trade_state": form?.carDetails?.state
        }
        dd("-----------------");
        let params = new URLSearchParams();
        for (let key in finalePayload) {
            if (finalePayload[key]) {
                dd("key,value: ", key, finalePayload[key]);
                params.append(key, finalePayload[key]);
            }
        }
        dd("-----------------");

        axios({
            method: "POST",
            url: `${baseURL}/seller/negotiation/${state.code}/trade`,
            data: params,
            headers: {
                'Authorization': userData.token,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((res) => {
            dd("rest.data:", res.data);
            cb(null, res.data);
            setModalConfig((prevState) => ({
                ...prevState,
                step: "chooseTrade",
                show: false
            }));
            reload();
        }).catch((err) => {
            dd("err", err);
            cb(err, null);
        });
    }

    return (
        <>
            {!state?.trade_condition && <ButtonBoder className="mt-2" label="ADD TRADE-IN" onClick={() => modalShow(true)} />}
            <ModalPortal>
                <Modal show={modalConfig.show} onHide={() => modalShow(false)} centered>
                    <Modal.Header closeButton>
                        <h5>{title ? title : "ADD Trade-in"}</h5>
                    </Modal.Header>
                    <Modal.Body>

                        {modalConfig.step === "chooseTrade" && <ChooseTypeTrade chosenTrade={chosenTrade} changeStep={changeStep} setTitle={setTitle} />}

                        {modalConfig.step === "enterVin" && <EnterVinNumber setCarDetails={setCarDetails} changeStep={changeStep} setTitle={setTitle} />}
                        {modalConfig.step === "enterLicensePlate" && <EnterLicensePlate setCarDetails={setCarDetails} changeStep={changeStep} setTitle={setTitle} />}
                        {modalConfig.step === "enterManual" && <EnterManual setCarDetails={setCarDetails} changeStep={changeStep} setTitle={setTitle} />}


                        {modalConfig.step === "carStatus-vin" && <ChooseCarStatus setCarStatus={setCarStatus} changeStep={changeStep} setTitle={setTitle} type="vin" />}
                        {modalConfig.step === "carStatus-plate" && <ChooseCarStatus setCarStatus={setCarStatus} changeStep={changeStep} setTitle={setTitle} type="plate" />}
                        {modalConfig.step === "carStatus-manual" && <ChooseCarStatus setCarStatus={setCarStatus} changeStep={changeStep} setTitle={setTitle} type="manual" />}


                        {modalConfig.step === "finalWords" && <FinalWords finalSubmit={finalSubmit} setTitle={setTitle} />}

                    </Modal.Body>
                </Modal>
            </ModalPortal>
        </>
    )
});

function FinalWords({ finalSubmit, setTitle }) {
    useEffect(() => {
        finalSubmit();
        setTitle("Submitting Trade-in");
    }, []);
    return <div className="d-flex justify-content-center flex-column align-items-center py-3">
        <div>Loading...</div>
        <div>
            <FaSpinner className="spinner" color="#B72949" size={40} />
        </div>
    </div>
}


function ChooseTypeTrade({ chosenTrade, changeStep, setTitle }) {
    useEffect(() => {
        setTitle("Choose Trade Type");
    }, []);
    return <>
        <div className="justify-content-center d-flex flex-column align-items-center">
            <button onClick={() => { chosenTrade("vin"); changeStep("enterVin"); }} className='btn-tri'>Vin Number</button>
            <button onClick={() => { chosenTrade("plate"); changeStep("enterLicensePlate") }} className='btn-tri'>License Plate</button>
            <button onClick={() => { chosenTrade("manual"); changeStep("enterManual") }} className='btn-tri'>Manual</button>
        </div>
    </>
}

function EnterVinNumber({ setCarDetails, changeStep, setTitle }) {
    const [vinError, setVinError] = useState("");
    const [responseData, setResponseData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setTitle("Vin Number Information");
    }, []);

    function handleChangeVin(e) {
        let vin = e.target.value;
        setVinError("");
        setIsLoading(true);
        setResponseData({});
        apiFetchVin(vin, (err, data) => {
            setIsLoading(false);
            if (err || data?.success === 0) {
                setVinError(data?.error || "Network Error, Please try again later");
                return;
            }
            setResponseData(data);
        });
    }

    function handleContinue() {
        if (!responseData?.vin) {
            setVinError("Please enter your VIN number");
            return;
        }
        if (isLoading) {
            setVinError("Please wait for the response");
            return;
        }
        setCarDetails(responseData);
        changeStep("carStatus-vin");
    }


    return <>
        <div className="d-flex justify-content-center align-items-center flex-column">
            <div>
                <InputBotBoder invalid={vinError} onChange={handleChangeVin} label="VIN NUMBER" />
                {isLoading && <div className='d-flex justify-content-end mt-2'>
                    <FaSpinner color="#BF405C" className="spinner" />
                </div>}
            </div>
            <table className="my-2 d-flex justify-content-center">
                <tbody>
                    {responseData?.year && <tr className='row-tri-info'>
                        <td><b>Year</b></td>
                        <td>{responseData?.year}</td>
                    </tr>}
                    {responseData?.make && <tr className='row-tri-info'>
                        <td><b>Make</b></td>
                        <td>{responseData?.make}</td>
                    </tr>}
                    {responseData?.model && <tr className='row-tri-info'>
                        <td><b>Model</b></td>
                        <td>{responseData?.model}</td>
                    </tr>}
                    {responseData?.trim && <tr className='row-tri-info'>
                        <td><b>Trim</b></td>
                        <td>{responseData?.trim}</td>
                    </tr>}
                    {responseData?.type && <tr className='row-tri-info'>
                        <td><b>Type</b></td>
                        <td>{responseData?.type}</td>
                    </tr>}
                    {responseData?.engine && <tr className='row-tri-info'>
                        <td><b>Engine</b></td>
                        <td>{responseData?.engine}</td>
                    </tr>}
                </tbody>
            </table>
            <ButtonBoder label="Continue" onClick={handleContinue} />
            <BackButton onClick={() => changeStep("chooseTrade")} />
        </div>
    </>
}


var countryCode = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY", "DC", "AS", "GU", "MP", "PR", "UM", "VI"]
var countryNames = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming", "District of Columbia", "American Samoa", "Guam", "Northern Mariana Islands", "Puerto Rico", "United States Minor Outlying Islands", "U.S. Virgin Islands"];

function EnterLicensePlate({ setCarDetails, changeStep, setTitle }) {
    const [plateData, setPlateData] = useState({ plate: "", state: "" });

    const [isLoading, setIsLoading] = useState(false);
    const [verboseError, setVerboseError] = useState({ type: "hidden", error: "" });

    useEffect(() => {
        setTitle("License Plate Information");
    }, []);



    function handlePlateChange(e) {
        handleChanges({
            plate: e.target.value,
        });
    }
    function handleSelect(e) {
        handleChanges({
            state: e.target.value,
        });
    }

    function handleChanges({ plate, state }) {
        plate = plate ?? plateData.plate;
        state = state ?? plateData.state;

        setPlateData({ plate, state });
        setVerboseError({ type: "hidden", error: "" });
        setIsLoading(true);
        apiFetchPlateVin({
            plate: plate,
            state: state
        }, (err, data) => {
            setIsLoading(false);
            if (err || data?.success === 0) {
                setVerboseError({ type: "visible", error: data?.error || "Network Error, Please try again later" });
                return;
            }
            setPlateData((prev) => ({ ...prev, ...data }));
        });
    }

    function handleContinue() {
        if (!plateData?.plate) {
            setVerboseError({ type: "visible", error: "Please enter your License Plate" })
            return;
        }
        if (!plateData?.state) {
            setVerboseError({ type: "visible", error: "Please select your state" })
            return;
        }
        if (verboseError.type === "visible") {
            return;
        }
        setCarDetails(plateData);
        changeStep("carStatus-plate");
    }


    return <div className="d-flex justify-content-center align-items-center flex-column">
        {/*  */}
        <div>
            {verboseError.type === "visible" && <div className="sydnic-error" style={{ maxWidth: "400px" }}>{verboseError.error}</div>}
            {/* <div className="go-header-custom-tx1 text-center">License Plate Information </div> */}
            <InputBotBoder value={plateData.plate} onChange={handlePlateChange} label="License Plate" placeholder="Enter License Plate" />

            <SelectBotBoder defaultValue={plateData.state} onChange={handleSelect} label="State" placeholder="Select State" labelOptions={countryNames} valueOptions={countryCode} />
            <div className="d-flex justify-content-end">
                {isLoading && <FaSpinner className='spinner' />}
                {" "}</div>
        </div>
        {/* Table */}
        <div>
            <table style={{ minWidth: "215px" }}>
                <tbody>
                    {plateData?.make && <tr className='row-tri-info'><td><b>Make</b></td><td>{plateData.make}</td></tr>}
                    {plateData?.year && <tr className='row-tri-info'><td><b>Year</b></td><td>{plateData.year}</td></tr>}
                    {plateData?.model && <tr className='row-tri-info'><td><b>Model</b></td><td>{plateData.model}</td></tr>}
                    {plateData?.trim && <tr className='row-tri-info'><td><b>Trim</b></td><td>{plateData.trim}</td></tr>}
                    {plateData?.engine && <tr className='row-tri-info'><td><b>Engine</b></td><td>{plateData.engine}</td></tr>}
                    {plateData?.type && <tr className='row-tri-info'><td><b>Type</b></td><td>{plateData.type}</td></tr>}
                </tbody>
            </table>
        </div>
        <div style={{ minWidth: "50%", marginTop: "25px" }}>
            <ButtonBoder onClick={handleContinue} label="Continue" className="py-2" style={{ background: "#B72949" }} />
        </div>
        <BackButton onClick={() => changeStep("chooseTrade")} />
    </div>
}


function ChooseCarStatus({ setCarStatus, type, changeStep, setTitle }) {

    const [hasError, setHasError] = useState("");
    const [form, setForm] = useState({
        currentMileage: "",
        carCondition: "",
        carPayLoanBalance: "",
        carWorth: ""
    });

    useEffect(() => {
        setTitle("Trade Car");
    }, []);

    function onChangeForm(e) {
        setHasError("");
        setForm((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    function handleContinue() {
        if (!form.currentMileage) {
            setHasError("Please enter current mileage");
            return;
        }
        if (!form.carCondition) {
            setHasError("Please select car condition");
            return;
        }
        if (!form.carWorth) {
            setHasError("Please enter car worth");
            return;
        }
        setCarStatus(form);
        changeStep("finalWords");
    }

    return (<>
        <div className="pt-2">
            {hasError && <div className="sydnic-error">{hasError}</div>}
            <InputBotBoder name="currentMileage" value={form.currentMileage} onChange={onChangeForm} type="number" label="Current Mileage" placeholder="Enter Current Mileage" />
            <small className="text-muted mb-2">Car condition</small>

            <RadioBoder name="carCondition" onChange={onChangeForm} checked={form.carCondition === "1"} value="1" label="Excellent" description="Like new condition, no issues" />
            <RadioBoder name="carCondition" onChange={onChangeForm} checked={form.carCondition === "2"} value="2" label="Good" description="Few minor defects,no major issues" />
            <RadioBoder name="carCondition" onChange={onChangeForm} checked={form.carCondition === "3"} value="3" label="Fair" description="Possible major issues" />

            <InputBotBoder className="bot-boder-dollar" name="carWorth" onChange={onChangeForm} value={form.carWorth} type="number" label="Car Worth" placeholder="What do you think your car is worth?" />

            <small className="text-muted mb-2">Approximate Loan Balance</small>
            <InputBotBoder name="carPayLoanBalance" value={form?.carPayLoanBalance || 0} onChange={onChangeForm} className="bot-boder-dollar" type="number" placeholder="Enter Loan Balance" />
        </div>
        <ButtonBoder label="Continue" onClick={handleContinue} />
        <BackButton onClick={() => {
            if (type === "vin") {
                changeStep("enterVin");
            }
            if (type === "plate") {
                changeStep("enterLicensePlate");
            }
            if (type === "manual") {
                changeStep("enterManual");
            }
        }} />
    </>);
}


function EnterManual({ changeStep, setCarDetails, setTitle }) {

    const [listMake, setListMake] = useState([]);
    const [listModel, setListModel] = useState([]);
    const [listTrim, setListTrim] = useState([]);
    const [hasError, setHasError] = useState("");

    const [form, setForm] = useState({
        year: "",
        make: "",
        model: "",
        trim: ""
    });

    useEffect(() => {
        setTitle("Vehicle Specifications");
    }, []);

    const onSelectYear = (e) => {
        fetchMakerList(e.target.value, (err, data) => {
            if (err) return;
            setForm({ ...form, year: e.target.value });
            setListMake(data);
        });
    }
    const onSelectMake = (e) => {
        fetchModelMaker(form.year, e.target.value, (err, data) => {
            if (err) return;
            setForm({ ...form, make: e.target.value });
            setListModel(data);
        });
    }
    const onSelectModel = (e) => {
        fetchTrimsMakerList(form.year, form.make, e.target.value, (err, data) => {
            if (err) return;
            setForm({ ...form, model: e.target.value });
            setListTrim(data);
        });
    }


    function handleContinue() {
        if (!form.year) {
            setHasError("Please select year");
            return;
        }
        if (!form.make) {
            setHasError("Please select make");
            return;
        }
        if (!form.model) {
            setHasError("Please select model");
            return;
        }
        setCarDetails(form);
        changeStep("carStatus-manual");
    }


    let arrayYearList = Array.from({ length: ((new Date()).getFullYear() + 1) - 1990 }).map((item, index) => 1990 + index);
    arrayYearList.reverse();

    return <>
        <div style={{ minWidth: "350px" }}>
            {hasError && <div className="sydnic-error" style={{ maxWidth: "400px" }}>{hasError}</div>}

            {/* <div className="go-header-custom-tx1 text-center">Vehicle Specifications</div> */}
            <div className="my-1">
                <SelectBotBoder onChange={onSelectYear} defaultValue={form.year} label="Year" placeholder="Choose Year" labelOptions={arrayYearList} valueOptions={arrayYearList} />
            </div>
            <div className="my-1">
                <SelectBotBoder onChange={onSelectMake} defaultValue={form.make} label="Make" disabled={listMake.length === 0} placeholder="Choose Make" labelOptions={listMake} valueOptions={listMake} />
            </div>
            <div className="my-1">
                <SelectBotBoder onChange={onSelectModel} defaultValue={form.model} label="Model" disabled={listModel.length === 0} placeholder="Choose Model" labelOptions={listModel} valueOptions={listModel} />
            </div>
            <div className="my-1">
                <SelectBotBoder onChange={(e) => setForm({ ...form, trim: e.target.value })} label="Trim" defaultValue={form.trim} disabled={listTrim.length === 0} placeholder="Choose Trim" labelOptions={listTrim} valueOptions={listTrim} />
            </div>
        </div>
        <div style={{ minWidth: "35%", marginTop: "25px" }}>
            <ButtonBoder onClick={handleContinue} label="Continue" className="py-2" style={{ background: "#B72949" }} />
        </div>
        <BackButton onClick={() => changeStep("chooseTrade")} />
    </>
}

function BackButton({ onClick }) {
    onClick ??= (() => { });
    return <div className="d-flex justify-content-center">
        <div onClick={onClick} className="back-btn-tri">Go Back</div>
    </div>
}


// startline requests
let controllerApiFetch = null;
function apiFetchVin(vin, cb) {
    if (controllerApiFetch) {
        controllerApiFetch.abort();
    }
    controllerApiFetch = new AbortController();
    axios({
        method: 'post',
        url: `${baseURL}/seller/register/vin`,
        data: {
            vin: vin
        },
        signal: controllerApiFetch.signal
    }).then((res) => {
        dd(res.data);
        cb(null, res.data);
    }).catch((err) => {
        if (err.name === "CanceledError") {
            return;
        }
        cb(err);
    });
}

function apiFetchPlateVin({ plate, state }, cb) {
    if (controllerApiFetch) {
        controllerApiFetch.abort();
    }
    controllerApiFetch = new AbortController();
    axios({
        method: 'POST',
        url: `${baseURL}/seller/register/plate-to-vin`,
        data: {
            plate: plate,
            state: state
        },
        signal: controllerApiFetch.signal
    }).then((res) => {
        dd(res.data);
        cb(null, res.data);
    }).catch((err) => {
        if (err.name === "CanceledError") {
            return;
        }
        cb(err);
    });
}
// endline requests

export default TradeInModal