// @ts-nocheck

import React, { useEffect, useRef } from 'react'
import { formatPhoneNumber } from 'utils/generalUtil';


const InputBotBoder = React.forwardRef((props, ref) => {
  var { children, label, name, labelstyle, className, invalid, ...attrProps } = props;
  className = className ? `${className} input-bot-boder` : 'input-bot-boder';
  labelstyle ??= {};
  name ??= label ?? '';






  function seRefs(e) {
   if(ref){
    if(typeof ref === 'function'){
      ref(e);
    }else{
      ref.current = e;
    }
   }
  }
  
  return (
    <>
     {label === undefined ? null :  <label className="input-bot-label" style={labelstyle}>{label}</label>}
      <input name={name} ref={seRefs} {...attrProps} className={className} />
      {invalid !== "" &&  <p className="italic-info-error">{invalid}</p>}
      {children}
    </>
  )
})

export default InputBotBoder