import { forwardRef } from 'react'

export default forwardRef((props, ref) => {
    let { label, invalid, placeholder, containerClassName, options, ...otherProps } = props;
    options ??= [];
    

    return <>
        <div className={containerClassName}>
            {label && <div className='input-calc-speed-label my-1'>{label}</div>}
            <select {...otherProps} className="input-calc-speed w-100">
              {placeholder && <option disabled value=''>{placeholder}</option>}
               {options.map((option, index) => {
                     return <option key={index} value={option.value}>{option.label}</option>
               })}
            </select>
            {invalid && <div>
                <div className='italic-info-error ps-1'>
                    {invalid}
                </div>
            </div>}
        </div>
    </>
});