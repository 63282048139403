import ButtonOutlineBoder from 'component/componenetInput/ButtonOutlineBoder'
import React,{ useContext,useEffect } from 'react';
import {ContextForm} from '../contexts/FormContext'

const ChooseTypeAdd = () => {
  const {editFormRegister,resetFormRegister} = useContext(ContextForm);
  const setStepType = (stepTypeValue) => {
    editFormRegister("stepType",stepTypeValue);
  }
  useEffect(() => {
    resetFormRegister();
  }, [])
  
  return (
    <>
    <ButtonOutlineBoder onClick={()=>{setStepType("vin")}} label="VIN" className="mb-2" />
        <ButtonOutlineBoder onClick={()=>{setStepType("plate")}} label="PLATE" className="mb-2" />
        <p className='text-center mt-4' style={{fontSize:"13px"}}>Don't have a license plate or a VIN?</p>
        <div style={{ textAlign: "center" }}>
            <a href={''} style={{ color: "black", fontWeight: "bold" }} onClick={(e) => {
              e.preventDefault();
              setStepType("manual");
            }}>List car manually</a>
        </div>
        </>
  )
}

export default ChooseTypeAdd