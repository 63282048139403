import ImageCarusel from 'component/home/homecomponent/ImageCarusel'
import ProgressContainerCircles from 'component/homeWorld/MyVehicle/section/ProgressContainerCircles'
import { Row, Col } from 'react-bootstrap'
import { apiFetchCarDetails } from 'component/homeStar/request/apiFetchCarDetails';
import { useEffect, useContext, useState, useRef } from 'react';
import { UserDataContext } from 'App';
import { fetchProgressVehicle } from 'component/homeWorld/request/fetchProgressVehicle';
import AutoCheckInfoStar from 'component/homeStar/section/AutoCheckInfoStar';
import TimerCountDown from 'component/home/homecomponent/TimerCountDown';
import TopOffers from './section/TopOffers';
import NegotationsStar from './section/NegotationsStar';
import HearRefferal from 'component/HearRefferal/HearRefferal';
import FormContext from 'component/NoCar/contexts/FormContext';
import RecentSold from './section/RecentSold';
import NoCarSection from './section/NoCarSection';
import NotificationBar from './section/NotificationBar';

const HomeStar = () => {
    const refModal = useRef(null);

    const [userData, setUserData] = useContext(UserDataContext);

    const [carDetails, setCarDetails] = useState({
        "vin": null,
        "title": "",
        "mileage": -1,
        "condition_id": -1,
        "notes": null,
        "vehicle_type": null,
        "engine": null,
        "msrp": null,
        "zip_code": "",
        "start_date": "",
        "end_date": "",
        "location": "",
        "year": "",
    });


    useEffect(() => {
        initHomeStar();
    }, []);
    function initHomeStar() {
        if (!userData?.car?.id) return;
        apiFetchCarDetails(userData?.token, userData?.car?.id, responseFetchCarDetails);
        fetchProgressVehicle(userData.token, responseFetchProgressVehicle);
    }

    function responseFetchCarDetails(error, data) {
        if (error) { throw error; }
        setCarDetails((pState) => {
            return { ...pState, ...data };
        });
    }
    function responseFetchProgressVehicle(error, data) {
        if (error) { throw error; }
        // remove null value from object data.auction
        for (const [key, value] of Object.entries(data.auction)) {
            if (value === null) {
                delete data.auction[key];
            }
        }
        setCarDetails((pState) => {
            return { ...pState, ...data.auction };
        });
    }

    return (
        <div className="row m-0">
            <NotificationBar />

            {!userData?.car && <Col className="col-12 d-flex  text-center justify-content-center pb-4">
                <img style={{ maxWidth: "100%" }} src="/images/speed/autos-today-logo.png" />
            </Col>}

            <Row>
                <Col md="12" xl="8" style={{ flexGrow: "1" }} className="d-flex container-container-my-vehicle justify-content-center">
                    {userData?.car ? <>
                        <div className="container-my-vehicle">
                            <Row className="justify-content-center">
                                <Col xs="12">
                                    <div className='header-car-title-speed'>{carDetails.title}</div>
                                    <div>{carDetails.year}</div>
                                </Col>
                                <Col md="12" xl="7" xxl="8">
                                    <div style={{ borderRadius: "24px", overflow: "hidden", height: "350px", marginBottom: "30px" }}><ImageCarusel /></div>
                                    <ProgressContainerCircles initHomeStar={initHomeStar} ref={refModal} />
                                </Col>
                                <Col md="12" xl="5" xxl="4">
                                    <TimerCountDown auction={carDetails} mode={2} />
                                    <TopOffers />
                                </Col>
                                <Col md="12">
                                    <AutoCheckInfoStar refModal={refModal} autocheck={carDetails} />
                                </Col>

                            </Row>
                        </div>  </> : <>
                        <Col className="container-my-vehicle col-xl-8 col-12 ">
                            <FormContext>
                                <NoCarSection />
                            </FormContext>
                        </Col>
                    </>}
                </Col>
                <Col md="12" xl="4" className="mt-4  mt-xl-0" style={{ flexGrow: "1", background: "#FFFFFF", borderRadius: "10px", padding: "1rem" }}>
                    <NegotationsStar />
                </Col>
            </Row>

            <Col md="12" className="mt-3 pe-lg-4 ps-lg-4"  >
                <RecentSold />
            </Col>
            <HearRefferal display={true} />
        </div>
    )
}

export default HomeStar