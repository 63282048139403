import DualRangeSliderInput from "component/componenetInput/DualRangeSliderInput"
import InputBotBoder from "component/componenetInput/InputBotBoder";
import SlimSelectAdapter from "component/componenetInput/SlimSelect/SlimSelectAdapter"
import { useEffect, useState, useContext } from "react"
import { FilterMainContext } from "../Context/FilterContext";
import { FaFilter } from "react-icons/fa";
import { GrClose } from "@react-icons/all-files/gr/GrClose";

////
var colorsListObject = [
    {
        "colorName": "Red",
        "colorHex": "#ff0000"
    },
    {
        "colorName": "Black",
        "colorHex": "#000000"
    },
    {
        "colorName": "Gray",
        "colorHex": "#808080"
    },
    {
        "colorName": "Silver",
        "colorHex": "#c0c0c0"
    },
    {
        "colorName": "White",
        "colorHex": "#ffffff"
    },
    {
        "colorName": "Burgundy",
        "colorHex": "#800020"
    },
    {
        "colorName": "Gobi",
        "colorHex": "#d2b48c"
    },
    {
        "colorName": "Blue",
        "colorHex": "#0000ff"
    },
    {
        "colorName": "Blue Dark",
        "colorHex": "#00008b"
    },
    {
        "colorName": "Gray Metalic",
        "colorHex": "#a9a9a9"
    },
    {
        "colorName": "Marron",
        "colorHex": "#c0c0c0"
    },
    {
        "colorName": "Beige",
        "colorHex": "#f5f5dc"
    },
    {
        "colorName": "White Sand",
        "colorHex": "#f5f5dc"
    },
    {
        "colorName": "Brown",
        "colorHex": "#a52a2a"
    },
    {
        "colorName": "Gold",
        "colorHex": "#ffd700"
    }
];
colorsListObject.sort((a, z) => a.colorName.localeCompare(z.colorName));


const SideFilter = ({ listCars, makeList, setMakeList, modelList, setModelList, handleChangeModel, handleChangeMake }) => {
    const [dealerNamesList, setDealerNamesList] = useState([{ placeholder: true, text: "Select Dealers" }]);
    const { filterReducer, setFilterReducer } = useContext(FilterMainContext);

    useEffect(() => {
        setMakeList([...new Set(listCars.map(i => i.make))].sort((a, b) => a.localeCompare(b)));
        setDealerNamesList([...new Set(listCars.map(i => i.dealer_name))].sort((a, b) => a.localeCompare(b)).map(i => ({ text: i, value: i })));

        document.querySelector(".content-grid").addEventListener("scroll", handleScrollContentGrid);
        return () => {
            if (document.querySelector(".content-grid")) {
                document.querySelector(".content-grid").removeEventListener("scroll", handleScrollContentGrid);
            }
        }
    }, []);

    function handleScrollContentGrid(e) {
        let contentGrid = e.target;
        let targetPinned = document.querySelector(".header-filter-speed-container-mobile");
        let targetButtonMoreFilter = document.querySelector("#more-filers-button");
        if (contentGrid.scrollTop > targetPinned.getBoundingClientRect().height) {
            targetPinned.classList.add("is-pinned");
            targetButtonMoreFilter.innerHTML = ' Filters';
        } else {
            targetPinned.classList.remove("is-pinned");
            targetButtonMoreFilter.innerHTML = ' More Filters';
        }
    }
    useEffect(() => {
        var result = [];
        for (var i = 0; i < modelList.length; i++) {
            const oneMakeObje = modelList[i];
            if (oneMakeObje?.options) {
                for (var j = 0; j < oneMakeObje.options.length; j++) {
                    if (oneMakeObje.options[j].selected) {
                        result.push(oneMakeObje.options[j].value);
                    }
                }
            }
        }
        setFilterReducer({
            model: result
        });

    }, [modelList]);


    function handleChangePrice(min, max) {
        setFilterReducer({
            price_min: min,
            price_max: max
        });
    }
    function handleChangeMileage(min, max) {
        setFilterReducer({
            mileage_min: min,
            mileage_max: max
        });
    }
    function handleChangeYear(min, max) {
        setFilterReducer({
            year_min: min,
            year_max: max
        });
    }
    function resetDefault() {
        setModelList([{ placeholder: true, text: "Select Model" }]);
        setDealerNamesList([...dealerNamesList]);
        setFilterReducer("resetDefault");
    }
    function handleChangeKeywords(event) {
        setFilterReducer({
            search_keywords: event.target.value
        });
    }




    function handleChangeColor(info) {
        setFilterReducer({
            color: info.map(i => i.value)
        });
    }

    function handleChangeDealer(info) {
        setFilterReducer({
            dealersNames: info.map(i => i.value)
        });
    }

    function hideModal(e) {
        setTimeout(() => {
            let target = e.target;
            let parent = target.closest('.filter-left-side-car-search');
            if (parent) {
                parent.classList.remove('show');
            }
        }, 200);
    }

    return (
        <>
            <div className="d-flex justify-content-between">
                <h5>Buy a Car</h5>
                <div onClick={hideModal} className='show-on-mobile'><GrClose /></div>
            </div>

            <div className="container-filter-options">

                <InputBotBoder className='search-icon-as-background' onChange={handleChangeKeywords} label="" value={filterReducer.search_keywords} placeholder="Search keywords" />

                <SlimSelectAdapter defaultValue={filterReducer.make} onSelectTarget={(info) => {
                    handleChangeMake(info);
                }} placeholder="Select Make" data={makeList.map(i => ({ text: i, value: i }))} multiple={true} />

                {/* Model List */}
                <SlimSelectAdapter disabled={filterReducer.make.length === 0} onSelectTarget={handleChangeModel} placeholder="Filter Model" data={modelList} multiple={true} />

                <SlimSelectAdapter defaultValue={filterReducer.color} placeholder="Select Colors" onSelectTarget={handleChangeColor} data={[
                    ...colorsListObject.map((i, idx) => { return { innerHTML: `<div class="d-flex-vertical-center"><span class="dot-circle-iot" style="background-color:${i.colorHex}"></span> ${i.colorName}</div>`, text: i.colorName, value: i.colorName } })
                ]} multiple={true} />

                {/* Dealer Names List */}
                <SlimSelectAdapter defaultValue={filterReducer.dealersNames} placeholder="Select Dealers" onSelectTarget={handleChangeDealer} data={dealerNamesList} multiple={true} />

                <DualRangeSliderInput values={{ min: filterReducer.price_min, max: filterReducer.price_max }} onValueChange={handleChangePrice} label="PRICE" format={(v) => `$${v.toLocaleString()}`} max={500_000} step={1_000} />
                <DualRangeSliderInput values={{ min: filterReducer.mileage_min, max: filterReducer.mileage_max }} onValueChange={handleChangeMileage} label="MILEAGE" format={(v) => `${Math.floor(v / 1000)}k`} max={200_000} step={1_000} />
                <DualRangeSliderInput values={{ min: filterReducer.year_min, max: filterReducer.year_max }} onValueChange={handleChangeYear} label="YEAR" min={1980} max={(new Date().getFullYear()) + 1} step={1} />


            </div>
            <div className="d-flex align-items-center justify-content-between mt-2">
                <div id="clear-filers" onClick={(e) => { resetDefault(); hideModal(e); }} style={{ color: "#FF4423", fontSize: "14px", textAlign: "right" }} className="c-pointer">
                    Reset Filters
                </div>
                <div onClick={hideModal} className="apply-button-filter show-on-mobile">
                    APPLY
                </div>
            </div>

        </>
    )
}

export default SideFilter