// @ts-nocheck
import React, { useContext, useState, useRef } from 'react';
import axios from "axios";
import { Form } from 'react-bootstrap';
import { UserDataContext } from '../App';
import { Link, useNavigate } from 'react-router-dom';
import { baseURL } from '../config';
import InputBotBoder from './componenetInput/InputBotBoder';
import ButtonBoder from './componenetInput/ButtonBoder';
import ButtonOutlineBoder from './componenetInput/ButtonOutlineBoder';
import InputCalcSpeed from './componenetInput/InputCalcSpeed';
import LongButtonModel from './componenetInput/LongButtonModel';

export default function Login() {
    const setUserData = useContext(UserDataContext)[1];
    const [isError, setIsError] = useState("");

    const navigator = useNavigate();

    const refEmail = useRef(null);
    const refPassword = useRef(null);

    const refEmailo2 = useRef(null);


    function LoginHandler(e) {
        e.preventDefault();

        // console.log("refEmail,refPassword:",refEmail.current.value,refPassword.current.value);

        const params = new URLSearchParams()
        params.append("email", refEmail.current.value);
        params.append("password", refPassword.current.value);
        // get userData
        axios({
            method: "post",
            url: baseURL + "/seller/login",
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((request) => {
            if (request.data.success === 0) {
                throw new Error("error");
            }
            setIsError("");
            setUserData({
                ...request.data,
                isOnline: true
            });
        }).catch((error) => {
            setIsError("Incorrect email or password!");
            refPassword.current.value = "";
        });

    }

    function keyDownHandler(e) {
        if (e.key === "Enter") {
            LoginHandler(e);
        }
    }

    return (
        <div className='container mt-5'>
            <div>
                <Form className='d-flex justify-content-center'>
                    <div className='col-12 col-lg-4'>
                        <div className='text-center box-register-logo' >
                            <img alt="autos today logo" className='my-2' src="/images/speed/autos-today-logo.png" />
                        </div>
                        {isError !== "" && <div className='error-message'>
                            {isError}
                        </div> }
                        <div className='box-register' onKeyDown={keyDownHandler}>
                            <div className="textOrRegister mb-3">
                                Receive offers in under a minute<br />100% free and anonymous
                            </div>

                            {/* <InputCalcSpeed value={vinData?.vin || ""} onChange={handleOnChange} style={{ minWidth: "300px" }} label="What is the VIN on your vehicle?" placeholder="Enter VIN" /> */}
                            <InputCalcSpeed  ref={refEmail} label="Email Address" placeholder="Your email address" />
                            <InputCalcSpeed type="password"  ref={refPassword} label="Password" placeholder="Your password" />
                            {/* <InputBotBoder ref={refEmail} label="Email Address" placeholder="Your email" /> */}
                            {/* <InputBotBoder ref={refPassword} label="Password" type="password" placeholder="Your password" /> */}

                            <div className='text-center my-2'>
                                <Link to="/forgot-pasword" className='anchor-red'> Forgot password? </Link>
                            </div>

                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <LongButtonModel colors={["#FFFFFF","#B62949"]} className="w-75" onClick={(e) => LoginHandler(e)}>SIGN IN</LongButtonModel>
                                <div className="textOrRegister">
                                    or
                                </div>
                                <LongButtonModel colors={["#B62949","#f1f1f1"]}  className="w-75 py-3"  onClick={(e) => navigator("/register")}> Sell My Car </LongButtonModel>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
           
        </div>
    )
}
