import { Carousel, NavDropdown } from 'react-bootstrap'
import { imageURL } from 'config.js'
import ButtonBoder from 'component/componenetInput/ButtonBoder';
import ModalAddImages from './ModalAddImages';
import { useState } from 'react';
import { AiFillEdit } from '@react-icons/all-files/ai/AiFillEdit';
import { RiAddBoxFill } from '@react-icons/all-files/ri/RiAddBoxFill';
import ModalPortal from 'component/componenetInput/ModalPortal';
import ModalConfirm from './ModalConfirm';
import { HiDotsVertical } from 'react-icons/hi';
import ImagesAndUpload from './ImagesAndUpload';

const CarInformation = ({ reload, refModalTradeIn, setSelected, dispatchState, state, selected, handleTradeInChange, handleUpdateOffer, handleTradeLoanBalanceChange }) => {
    const [showModal, setShowModal] = useState(false);

    let mileageValue = parseInt(state?.trade_mileage) ? parseInt(state?.trade_mileage).toLocaleString() : "N/A";
    let tradeInValue = parseInt(state?.trade_in) ? parseInt(state?.trade_in).toLocaleString() : 0;
    let tradeConditionValue = state?.trade_condition ? state?.trade_condition : "N/A";
    let tradeLoanBalanceValue = state?.trade_loan_balance ? parseInt(state?.trade_loan_balance) : 0;


  
    return (
        <>
            {/* <ModalAddImages selected={selected} setSelected={setSelected} dispatchState={dispatchState} carState={state} show={showModal} setShowModal={setShowModal} /> */}

            {state.trade_condition && <div className="card-info-about mt-2">


               <div className="d-flex justify-content-between">
                    <div>
                        <div className='text-speed-medium text-speed-bold'>Trade in Details</div>
                        {state.trade_condition && <div className='text-speed-small text-muted'>
                            {selected?.trade_title ?? "N/A"}
                        </div>}
                    </div>
                    <div>
                       
                        <NavDropdown className='hide-mobile' align="end" title={<>
                            <div className="circle-icon-speed"><HiDotsVertical/></div>
                        </>}>
                            <NavDropdown.Item onClick={()=> document.querySelector('#image-pusher').click()}>
                                Add Photos
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item>
                                <ModalConfirm reload={reload} state={state} />
                            </NavDropdown.Item>
                        </NavDropdown>

                    </div>

               </div>


                <div >
                    <div className="pb-2">

                      <ImagesAndUpload carState={state} photos={[...(state?.trade_photos?.split(",") ?? [])]} />

                        {state.trade_condition && <div className="card-row-info-about">
                            <div><small className='fw-semibold'>Mileage</small></div>
                            <div>{mileageValue}</div>
                        </div>}

                        {state.trade_condition && <div className="card-row-info-about">
                            <div><small className='fw-semibold'>Condition</small></div>
                            <div className="fw-semibold" style={{color: ["Good","Excellent"].includes(tradeConditionValue) ? "#12DE00" : "black"}}>{tradeConditionValue}</div>
                        </div>}

                        <div className="card-row-info-about c-pointer" onClick={() => refModalTradeIn.current.show()} >
                            <div><small className='fw-semibold'>Trade In Value</small></div>
                            <div>
                                <input disabled={!!state?.dealer_trade_in_accepted} onChange={handleTradeInChange} onFocus={(e) => e.target.select()} value={"$" + tradeInValue} type="text" className="press-input-transit" />
                            </div>
                        </div>

                        <div className="card-row-info-about">
                            <div><small className='fw-semibold'>Loan Balance</small></div>
                            <div>
                                <input onChange={handleTradeLoanBalanceChange} onFocus={(e) => e.target.select()} value={"$" + tradeLoanBalanceValue.toLocaleString()} type="text" className="press-input-transit " />
                            </div>
                        </div>
                        

                    </div>
                </div>
            </div>}
         
            {state.showButtonUpdate && <ButtonBoder className="mt-2" onClick={handleUpdateOffer} label="Send to dealer" />}
        </>
    )
}

export default CarInformation