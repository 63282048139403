// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import Loading from '../Loading';
import { SocketDataContext, UserDataContext } from '../../App';
import ReciverMsg from './messageTypes/ReciverMsg';
import SenderMsg from './messageTypes/SenderMsg';
import ReciverMsgImage from './messageTypes/ReciverMsgImage';
import SenderMsgImage from './messageTypes/SenderMsgImage';
import UploadMsgFile from './messageFunctions/UploadMsgFile';
import axios from 'axios';
import { ProgressBar } from 'react-bootstrap';
import ModalImage from 'component/componenetInput/ModalImage';
import { baseURL } from 'config.js';
import { useRef } from 'react';
import { fetchSelectedDetails } from 'component/negotiations/request/fetchSelectedDetails';
import {MdDone} from '@react-icons/all-files/md/MdDone';
import {ImPhone} from '@react-icons/all-files/im/ImPhone';
import {BiDotsVerticalRounded} from '@react-icons/all-files/bi/BiDotsVerticalRounded';
import { BsFillSendFill } from 'react-icons/bs';

var roomGlobal = null;
const PinnedOfferingTradeIn = ({value,onClick})=>{
    return <span className="ms-auto">
    Dealer is offering ${value?.toLocaleString()} for your trade-in: 
    <button onClick={onClick} className='pinned-button'><MdDone/>Accept offer</button>
    </span>;
}

const ChatMessageRawIN = ({ room_id, selected, setSelected }) => {
    const [pinned,setPinned] = useState(null);
    const { socketData } = useContext(SocketDataContext);
    const messageInputRef = useRef(null);
    const bodyMessagesRef = useRef(null);
    const progressBarRef = useRef(null);
    const userData = useContext(UserDataContext)[0];
    const [modalImageShow, setModalImageShow] = useState({
        show: false,
        image: ""
    }); // onclick image fullscreen
    const [isLoading, setIsLoading] = useState(false);

    const [chatMessages, setChatMessages] = useState([]);

    useEffect(() => {
        const socket = socketData?.socket;
        if (!socket) return;

        socket.on('new-message', onNewMessage);
        socket.on('new-numbers', onNewNumbersFetch);
        socket.on('trade-counter-offer', reFetchDetailsAndMessages)

        return () => {
            socket.off('new-message', onNewMessage);
            socket.off('new-numbers', onNewNumbersFetch);
            socket.off('trade-counter-offer', reFetchDetailsAndMessages)
        }
    }, [socketData?.socket]);
    

    useEffect(() => {
        let dealer_trade_in = selected?.dealer_trade_in;
        let dealer_trade_in_accepted = selected?.dealer_trade_in_accepted;
        //console.log("selected from chatMessagesIN:", selected);
        if (dealer_trade_in && !dealer_trade_in_accepted) {
            dealer_trade_in ??= 10_000;
            let pinned = <PinnedOfferingTradeIn value={dealer_trade_in} onClick={acceptTradeInOffering}/>
            setPinned(pinned);
        }else{
            setPinned(null);
        }
    },[selected?.dealer_trade_in,selected?.dealer_trade_in_accepted,selected?.id]);

    useEffect(() => {
        roomGlobal = room_id;
        setIsLoading(true)
        fetchMessage();
        refetchSelectedDetails();
    }, [room_id]);

    useEffect(() => {
        try {
            bodyMessagesRef.current.scrollBy({ top: Number.MAX_SAFE_INTEGER });
        } catch (erro) { }
    }, [chatMessages]); 

    useEffect(() => {
        messageInputRef?.current?.addEventListener('keydown', onEnterPress);
        return () => {
            messageInputRef?.current?.removeEventListener('keydown', onEnterPress);
        }
    }, [chatMessages]);

    function acceptTradeInOffering(){
        axios({
            method: "POST",
            url: baseURL + `/seller/negotiation/${selected?.code}/trade/counter/accept`,
            headers: {
                'Authorization': userData.token,
            }
        }).then((response) => {
            console.log("response:", response);
            reFetchDetailsAndMessages();
        }).catch((error) => {
            console.log("response error:", error);
        });    
    }

    function reFetchDetailsAndMessages(){
        fetchMessage();
        refetchSelectedDetails();
    }

    function onEnterPress(e) {

        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    }



    function sendMessage() {
        if (!messageInputRef.current.value || messageInputRef.current.value === "") return;
        // set params 
        let params = new URLSearchParams();
        params.append("room_id", roomGlobal);
        params.append("message", messageInputRef.current.value);
        // send request
        axios({
            method: "POST",
            url: baseURL + "/seller/message",
            data: params,
            headers: {
                'Authorization': userData.token,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {

            fetchMessage();
        }).catch((error) => {
            console.log("response error:", error);
        }).finally(() => {
            messageInputRef.current.value = '';
        });
    }

    function fetchMessage() {
        axios({
            method: "GET",
            url: baseURL + `/seller/messages/${roomGlobal},0`,
            headers: {
                'Authorization': userData.token,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            setChatMessages(response.data.reverse());
        }).catch((error) => {
            console.log("response  fetch edge error:", error);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function onNewNumbersFetch() {
        fetchMessage();
    }


    function onNewMessage(msg) {
        console.log("new-message from chatMessage negotiation:", msg);
        const split = msg?.split("|") ?? []; // Dealer 6|asdasdasd|3121|undefined
        if (split.length < 2) return; // if not valid

        const room_id_c = split[2];
        const message = split[1];
        if (room_id_c == roomGlobal) {
            fetchMessage();
        }
    }


    function refetchSelectedDetails() {
        fetchSelectedDetails(userData.token, selected.code, (error, data, codeVal) => {
            if (error) throw error;
            if (selected.code !== codeVal) return;

            if (!data) {
                data = { failed_loaded: true }; // if data is null or undefined or ""
            } else {
                data = { failed_loaded: false, ...data }
            }
            const { code, ...restOfData } = data;
            setSelected((pState) => {
                return { ...pState, ...restOfData }
            });
        });
    }

    if (isLoading)
        return <Loading>Loading data...</Loading>


    return (
        <>
            <div className='chat-header'>
                <div className='d-flex align-items-center w-100'>
                    <div className='img-no-image'> </div>
                    <div className='ms-3 d-flex justify-content-between align-items-center w-100'>
                        <div>
                            <div className="name-text-header">{selected?.dealer_name ?? ""}</div>
                            <div style={{color:"#9a9aa9"}}>{selected?.location_short ?? ""}</div>
                        </div>
                        <div>
                            {/* <a href=“tel:555-666-7777”>555-666-7777</a> */}
                            <a className="anchor-no-decoration d-flex align-items-center" href={`tel:${selected?.dealer_phone ?? selected?.phone_number ?? selected?.phone ?? selected?.number ?? ""}`}>
                                <div>
                                    <div className="call-dealer-header">Call dealer:</div>
                                    <div className="phone-number">{selected?.dealer_phone ?? selected?.phone_number ?? selected?.phone ?? selected?.number ?? "N/A"}</div>
                                </div>
                                <div className='circle-icon me-3'><ImPhone/></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {false &&<div className='chat-header-pinned'>
                {pinned}
            </div>}

            <div ref={bodyMessagesRef} className='scroll-as-height-chat-body pt-1'>
                {chatMessages.map((item) => {
                    if (item.file_name !== null && item.you === 0)
                        return <ReciverMsgImage modal={[modalImageShow, setModalImageShow]} key={item.message_id} item={item} />
                    else if (item.file_name !== null && item.you === 1)
                        return <SenderMsgImage modal={[modalImageShow, setModalImageShow]} key={item.message_id} item={item} />

                    if (item.you === 0)
                        return <ReciverMsg key={item.message_id} item={item} />
                    else
                        return <SenderMsg key={item.message_id} item={item} />
                })}
            </div>
            <div className="emoji-list-r" >
                <ModalImage setModalImageShow={setModalImageShow} image={modalImageShow.image} show={modalImageShow.show} onHide={() => { setModalImageShow((i) => { return { ...i, show: false } }) }} />
                <div className="progressUploadFile mb-1" style={{ display: "none" }}>
                    <ProgressBar ref={progressBarRef} striped variant="warning" />
                </div>
                <div className='chat-footer'>
                    <div className="d-flex align-items-center chat-input-container">
                        <UploadMsgFile fetchMessage={fetchMessage}
                            chatMessages={chatMessages} roomData={{ room_id: room_id }} progressBarRef={progressBarRef} />

                            
                        <textarea ref={messageInputRef} maxLength={200} className="chat-text-input" 
                        placeholder="Write your message..."></textarea>


                        {/* Send Message */}
                        <div className='mx-2 send-msg-button-chat' onClick={sendMessage}>
                            <img alt='' src="./images/speed/send.svg" />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}


export const ChatMessageIN = React.memo(ChatMessageRawIN)