
import {TiArrowSortedDown} from '@react-icons/all-files/ti/TiArrowSortedDown';
import axios from 'axios';
import { baseURL,imageUrlThumb } from 'config.js';
import { useEffect,useState,useReducer } from 'react';
import { Col, Row } from 'react-bootstrap';
import { HiLocationMarker } from 'react-icons/hi';

const RecentSold = () => {
    const [recentlySoldList,setRecentlySoldList] = useState([]);
    const [offset,dispatchOffset] = useReducer((state,action)=>{
        switch(action.type){
            case "next":
                return state+1 < recentlySoldList.length-3 ? state+1 : 0 ;
            case "prev":
                return state-1 >= 0 ? state-1 : recentlySoldList.length-3;
            case 'viewAll':
                return -1;
            case 'showLess':
                return 0;
            default:
                return state;
        }
    },0);
    useEffect(()=>{
        // fetchRecentlySoldList
        fetchRecentlySoldList((error,data)=>{
            if(!error) setRecentlySoldList(data);
        });
    },[]);
    // Recent Sold 
  return (
    <div className="col-recent-sold-speed-container-list">
        <div className="recent-sold-speed ">
            <div className="fw-bold fs-5 mb-2">Recent Sold</div>
            <div className="d-flex align-items-center">
               
                {offset === -1 ? 
                    <div onClick={()=>dispatchOffset({type:"showLess"})} className='subheader-speed-negotiations red c-pointer'>Show less</div> :
                    <>
                     <div className='arrows-container'>
                        <div>
                            <span role="button" onClick={()=>dispatchOffset({type:"prev"})} className='arrow-left'><TiArrowSortedDown  style={ {transform: 'rotate(90deg)' }}/></span>
                        </div>
                        <div>
                            <span role="button" onClick={()=>dispatchOffset({type:"next"})} className='arrow-right'><TiArrowSortedDown  style={ {transform: 'rotate(-90deg)' }}/></span>
                        </div>
                    </div>
                    <div onClick={()=>dispatchOffset({type:"viewAll"})} className='subheader-speed-negotiations red c-pointer ms-2'>View All</div>
                    </>
                }

            </div>
        </div>

        <Row>
            {offset !== -1 && recentlySoldList.slice(offset,offset+3).map((item,index)=>{
                return <ColRecentSold key={index} {...item} index={index} />
            })}
            {offset === -1 && recentlySoldList.map((item,index)=>{
                return <ColRecentSold key={index} {...item} index={index} />
            })}
        </Row>
    </div>
  )
}

export default RecentSold


/*
{
    "code": "4154e22503e5fdc0eaf2eb6f516497a3",
    "start_date": "2022-06-08T18:49:33.000Z",
    "title": "2012 Chevrolet Equinox LS 4dr SUV (2.4L 4cyl 6A)",
    "mileage": 147550,
    "cover_photo": "4f8a6d27-9253-4ba9-9442-3452201b4e84.jpg",
    "last_bid_amount": 3300,
    "location": "Chicago, IL"
}
*/

function ColRecentSold({cover_photo,title,location,index}){
    return (
        <Col md="6" lg="4" xl="4"  className="col-recent-sold-speed" >
            <div className='d-flex align-items-center' style={{borderRight:( index < 2 ? "1px solid #90a3c163" : "")}}>
                <div><img style={ {width: "114px",height: "100px",objectFit: "cover"}} src={imageUrlThumb+cover_photo}/></div>
                <div className="ms-2">
                    <div className="title">{title}</div>
                    <div className='location'>
                       <HiLocationMarker size={25} color="#B62949"/> {location}
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-between px-3'>
                <div></div>
                <div className="sold-text">Sold</div>
            </div>
        </Col>
    )
}



/// apis
function fetchRecentlySoldList(callback){
    axios({
        method: 'GET',
        url: baseURL+'/seller/recently-sold',
    }).then((response)=>{
        callback(null,response.data);
    }).catch((error)=>{
        callback(error,null);
    });
}