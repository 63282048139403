// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react'
import { secondsToDhmsArray } from '../../../utils/timeUtil';
import ButtonOutlineBoder from '../../../component/componenetInput/ButtonOutlineBoder';
import axios from 'axios';
import { baseURL } from '../../../config';
import { UserDataContext } from 'App';
import Loading from 'component/Loading';
import { useNavigate } from 'react-router-dom';

var interval = null;
const TimerCountDown = (props) => {
    const [timeLeftDHMS, setTimeLeftDHMS] = useState([0, 0, 0, 0, 0]); // days hours minutes seconds and raw-seconds  over 60s
    const [highestOffer, setHighestOffer] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useContext(UserDataContext);
    const navigate = useNavigate();
    const { end_date, vin } = props.auction;
    const mode = props.mode ?? 1;
    const unixTime_end_date = Math.floor((new Date(end_date)).getTime() / 1000);
    
    useEffect(() => {
        fetchOffersCount();
    }, [userData.newBid]);
    useEffect(() => {
        if(isNaN(unixTime_end_date)) return;
         interval = setInterval(() => {
            var unixTime_now_date = Math.floor((new Date()).getTime() / 1000);
            setTimeLeftDHMS(secondsToDhmsArray(unixTime_end_date - unixTime_now_date));
        });
        return () => {
            clearInterval(interval);
        }
    }, [end_date]);

    function fetchOffersCount() {
        axios({
            method: "GET",
            url: baseURL + "/seller/bids",
            headers: {
                'Authorization': userData.token
            }
        }).then((response) => {

            let highestOfferFixed = [0, null];
            for (var i = 0; i < response.data.length; i++) {
                if (response.data[i].bid_amount > highestOfferFixed[0]) {
                    highestOfferFixed = [response.data[i].bid_amount, response.data[i]];
                }
            }

            if (highestOfferFixed[0] > 0) {
                setHighestOffer(highestOfferFixed[1]);
            }

        }).catch((error) => {

        }).finally(() => {
            setIsLoading(false);
        });
    }

    function reList() {
        axios({
            method: "POST",
            url: baseURL + "/seller/relist",
            headers: {
                'Authorization': userData.token
            },
        }).then((response) => {
            setUserData((prevState) => {
                return {
                    ...prevState,
                    newMessage: 0,
                    newMessageFrom: [],
                    newBid: 0
                }
            });
            setTimeout(() => {
                window.location.reload();
            }, 200);
        });
    }

    if (isLoading) return <Loading />


    if (mode === 2) {
        return <>
            <div className="countdown-timer-speed">
                <div className='time-square-seller'>
                    <img src="./images/speed/clock.svg" />
                </div>
                <div style={{ marginTop: "11px", fontSize: "24px", textAlign: "center" }}>
                    {timeLeftDHMS[4] > 0 ?<div className='mb-3 fw-semibold'>Awaiting bids from local dealers...</div> : <div className='mb-3'>Listing Ended</div>}
                </div>
                <div>
                    {timeLeftDHMS[4] > 0 && <span className='timer-seller-container'>
                        <span className="timer-seller" data-unit="Days">{timeLeftDHMS[0].toString().padStart(2, '0')}</span>
                        <span className="timer-seller"> : </span>
                        <span className="timer-seller" data-unit="Hours">{timeLeftDHMS[1].toString().padStart(2, '0')}</span>
                        <span className="timer-seller"> : </span>
                        <span className="timer-seller" data-unit="Minutes">{timeLeftDHMS[2].toString().padStart(2, '0')}</span>
                        <span className="timer-seller"> : </span>
                        <span className="timer-seller" data-unit="Seconds">{timeLeftDHMS[3].toString().padStart(2, '0')}</span>
                    </span>
                    }
                    {timeLeftDHMS[4] < 0 && <ButtonOutlineBoder onClick={reList}  label="Re-list Your Car" />}
                </div>
            </div>
        </>
    }


    return (
        <>
            <div className="car-seller-card ">
                <img alt="" className='img-top-right' src="./images/icons/side-effect01.svg" />
                <img alt="" className='img-bottom-left' src="./images/icons/side-effect01.svg" />
                <div className='bell-umi'>

                </div>
                <div className='time-square-seller'>
                    <img src="./images/icons/time-square.svg" />
                </div>
                {timeLeftDHMS[0] > 0 ?
                    <div className='mb-3'>Awaiting bids from local dealers...</div> : <div className='mb-3'>Listing Ended</div>}
                <div>
                    {timeLeftDHMS[4] > 0 && <span className='timer-seller-container'>
                        <span className="timer-seller" data-unit="Days">{timeLeftDHMS[0].toString().padStart(2, '0')}</span>
                        <span className="timer-seller"> : </span>
                        <span className="timer-seller" data-unit="Hours">{timeLeftDHMS[1].toString().padStart(2, '0')}</span>
                        <span className="timer-seller"> : </span>
                        <span className="timer-seller" data-unit="Minutes">{timeLeftDHMS[2].toString().padStart(2, '0')}</span>
                        <span className="timer-seller"> : </span>
                        <span className="timer-seller" data-unit="Seconds">{timeLeftDHMS[3].toString().padStart(2, '0')}</span>
                    </span>
                    }
                    {timeLeftDHMS[4] < 0 && <ButtonOutlineBoder onClick={reList} className="w-50" label="Re-list Your Car" />}
                </div>


                {highestOffer ? <>
                    <div className='dw-container-offer' >
                        <h5>Highest Offer</h5>
                        <span onClick={() => {
                            navigate("/messages", { state: highestOffer });
                        }} className='dw-top-offers'>

                            <strong className='dw-offer-price'>${parseInt(highestOffer.bid_amount).toLocaleString()}</strong>
                            <div style={{ fontSize: "10px" }}>FROM</div>
                            <div>{highestOffer.dealer_name}</div>
                        </span>
                    </div>
                </> : <>
                    <div className="dw-container-offer"></div>
                </>}

            </div>
        </>
    )
}

export default TimerCountDown