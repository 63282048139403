// @ts-nocheck
import React, { useState } from 'react'
import { ChatMessageIN } from 'component/messages/ChatMessageIN';
import { useEffect } from 'react';


const NegotiationChat = ({ selected, id, setSelected }) => {
    let pinned = <button>Pinned TEST</button>
    const [chatData, setChatData] = useState({
        loading: false,
        chatMessages: [],
        roomData: {
            roomId: selected.room_id
        }
    });
    useEffect(() => {
        // clean from last room 
        setChatData({
            loading: false,
            chatMessages: [],
            roomData: {
                roomId: selected.room_id
            }
        });
    }, [selected.room_id]);

 

    return (
        <div id={id} style={ {
            height: "100%",
            backgroundColor: "#fff",
            boxShadow: "0px 5px 29px #00000014",
            borderRadius: "10px",
            padding: "0px 10px 5px 10px",
            marginBottom: "20px",
            position: "relative",
            maxHeight: "850px",
        }} className="d-flex flex-column">
            <ChatMessageIN  room_id={selected.room_id} selected={selected} setSelected={setSelected} />
        </div>
    )
}

export default NegotiationChat