import { ModalCarContext } from 'component/searchCars/Context/ModalCarContext';
import { apiFetchCheckPlate } from 'component/searchCars/request/apiFetchCheckPlate';
import { Col, Spinner } from 'react-bootstrap';
import { useEffect, useState, useContext } from 'react';
import InputCalcSpeed from 'component/componenetInput/InputCalcSpeed';
import SelectBotBoder from 'component/componenetInput/SelectBotBoder';
import InputBotBoder from 'component/componenetInput/InputBotBoder';
import LongButtonModel from 'component/componenetInput/LongButtonModel';
import { BsArrowRight } from 'react-icons/bs';


var countryCode = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY", "DC", "AS", "GU", "MP", "PR", "UM", "VI"]
var countryNames = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming", "District of Columbia", "American Samoa", "Guam", "Northern Mariana Islands", "Puerto Rico", "United States Minor Outlying Islands", "U.S. Virgin Islands"];



const TradeLicensePlate = () => {
    const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
    let contextTrade = modalCar?.trade || {};
    contextTrade = contextTrade?.tradeType == "plate" ? contextTrade : {};
    const [plateData, setPlateData] = useState({ ...contextTrade });
    const [responseStatus, setResponseStatus] = useState({
        status: null,
        message: null
     });

    useEffect(() => {
        dispatchModalCar({
            type: "set-action", payload: {
                backButton: function () {
                    dispatchModalCar({
                        type: "update", payload: {
                            currentModal: "secondaryModal",
                            currentSubModal: "tradeByType"
                        }
                    });
                }
            }
        });
        return () => {
            dispatchModalCar({
                type: "set-action", payload: {
                    backButton: null
                }
            });
        }
    }, []);


    function handleChangeForm(value, name) {
        let plate = plateData?.plate || "";
        let state = plateData?.state || "";
        if (name == "plate") {
            plate = value;
        } else if (name == "state") {
            state = value;
        }

        setPlateData({
            plate: plate,
            state: state
        });
        setResponseStatus({
            status: "loading",
            message: null
        });
        apiFetchCheckPlate(plate, state, (error, data) => {
            if (error || data?.success == 0) {
                setResponseStatus({
                    status: "error",
                    message: data?.message || "Error",
                    visible:false
                });
                return;
            }
            setResponseStatus({
                status: "success",
                message: null
            })
            let { success, ...otherData } = data;
            setPlateData({
                ...otherData,
                plate: plate,
                state: state
            });
            



            dispatchModalCar({type:"trade-set",payload:{
                tradeType:"plate",
                ...otherData,
                plate: plate,
                state: state
            }})

        })

    }


    function handleContinueButton() {
        if (!plateData?.plate || !plateData?.state) {
            setResponseStatus({
                status: "error",
                message: "Please enter a valid plate and state",
                visible:true,
            });
            return;
        }
        if(responseStatus.status === "loading"){
            setResponseStatus({
                status: "error",
                message: "Please wait for the data to load",
                visible:true,
            });
            return;
        }
        if(responseStatus.status === "error"){
            setResponseStatus((prevState)=>{
                return {...prevState,visible:true}
            });
            return;
        }

        if(responseStatus.status !== "success") return;

        dispatchModalCar({
            type: "trade-set", payload: {
                ...plateData
            }
        });
        dispatchModalCar({
            type: "update", payload: {
                currentSubModal: "tradeDetails"
            }
        });
        
    }

    return (
        
        <>
        {responseStatus?.visible && <div className="text-center text-danger mb-0">{responseStatus.message}</div>}
        <Col lg={6} xl={4} className="col-12 bordered-speed mt-4 d-flex justify-content-center flex-column">
            <div className="header-speed text-center">
                Trade-in by License Plate
            </div>

            <div>
                <InputBotBoder onChange={(e) => handleChangeForm(e.target.value, "plate")} name="Plate" label="Plate" placeholder="Enter Plate" />
            </div>
            <div>
                <SelectBotBoder onChange={(e) => handleChangeForm(e.target.value, "state")} 
                defaultValue={plateData.state} label="State" placeholder="Select State" 
                labelOptions={countryNames} valueOptions={countryCode} />
            </div>
            {responseStatus?.status === "loading" && <>
            <div className="d-flex justify-content-end" >
                <Spinner style={{width:"20px",height:"20px",color:"#B62949"}} />
            </div>
            </>}

            <div>
                <table className="w-100 mb-1">
                    <tbody>
                        {plateData?.make && <tr className='row-tri-info'><td><b>Make</b></td><td>{plateData.make}</td></tr>}
                        {plateData?.year && <tr className='row-tri-info'><td><b>Year</b></td><td>{plateData.year}</td></tr>}
                        {plateData?.model && <tr className='row-tri-info'><td><b>Model</b></td><td>{plateData.model}</td></tr>}
                        {plateData?.trim && <tr className='row-tri-info'><td><b>Trim</b></td><td>{plateData.trim}</td></tr>}
                        {plateData?.engine && <tr className='row-tri-info'><td><b>Engine</b></td><td>{plateData.engine}</td></tr>}
                        {plateData?.type && <tr className='row-tri-info'><td><b>Type</b></td><td>{plateData.type}</td></tr>}
                    </tbody>
                </table>
            </div>

            <LongButtonModel onClick={handleContinueButton} colors={["#fff", "#B62949"]}>
                Continue<BsArrowRight className="ms-1" />
            </LongButtonModel>

        </Col>
        </>
    )
}

export default TradeLicensePlate