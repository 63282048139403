// @ts-nocheck
import { useContext, useEffect, useRef, useState } from 'react'
import { Navbar, Container, Offcanvas, Nav, Dropdown, NavDropdown } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarsStaggered } from '@fortawesome/free-solid-svg-icons'
import { BsFillPersonFill } from '@react-icons/all-files/bs/BsFillPersonFill';

import { SideBarVisibility, SocketDataContext, UserDataContext } from '../../App';
import { useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';

import { baseURL, socketURL } from '../../config';
import io from 'socket.io-client';
import axios from 'axios';
import LogoutConfirm from 'component/LogoutConfirm';
import SearchInventory from './parts/SearchInventory';
import { HiOutlineX } from 'react-icons/hi';
import { BsGridFill } from 'react-icons/bs';
// import { IoLogoGooglePlaystore } from '@react-icons/all-files/io/IoLogoGooglePlaystore';
import {RiGooglePlayFill} from '@react-icons/all-files/ri/RiGooglePlayFill';
import {RiAppleFill} from '@react-icons/all-files/ri/RiAppleFill';
import {AppStoreIcon, PlayStoreIcon} from 'component/componenetIcons/MobileStoreIcon';



function NavBar() {
  const [userData, setUserData] = useContext(UserDataContext);
  const { socketData, setSocketData } = useContext(SocketDataContext);
  const [sideBarVisibility, setSideBarVisibility] = useContext(SideBarVisibility);
  const [totalMessages, setTotalMessages] = useState(0);
  const refCloseButton = useRef(null);



  var newNotification = userData.newMessage || 0;
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  useEffect(() => {
    if (userData && userData.newMessage) {
      if (userData.newMessage > 0) {
        document.title = "AutosToday (" + userData.newMessage + " new messages)";
      }
    }
    return () => {
      document.title = "AutosToday";
    }
  }, [userData.newMessage])


  useEffect(() => {
    fetchMessageAndStore();
    let audio = new Audio('./audio/sound-tiny.mp3');
    audio.volume = 0.10;
    // do not init socket if user is not logged in
    if (!userData.isOnline) {
      return;
    }

    // init socket
    const socket = io.connect(socketURL, {
      reconnectionAttempts: 10,
      transports: ['websocket']
    });
    socket.on('connect', () => {
      let UID = userData.uid;
      if (UID) {
        socket.emit('set-token', { uid: UID, socket: socket.id, type: "seller" });
        socket.emit('subscribe', { room: "sellers" });
      }

      setSocketData((pState) => {
        return { ...pState, socket: socket }
      });

    });



    socket.on('new-message', function (msg) {
      if (window.location.pathname.includes("/messages") || window.location.pathname.includes("/negotiations")) {
        return;
      }
      audio.play();
      let spliter = msg.split("|");

      if (spliter[0])
        toast((<><b>{spliter[0]}</b><br />
          <span>{spliter[1]}</span></>), {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });


      setUserData((p) => {
        let statusNotification = p?.statusNotification ?? [];
        if (spliter.length > 2) statusNotification.push({
          type: "message",
          name: spliter[0],
          message: spliter[1],
          time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          dateTime: new Date().toLocaleString()
        });
        return {
          ...p,
          newMessage: p.newMessage ? p.newMessage + 1 : 1,
          newMessageFrom: p.newMessageFrom ? [...p.newMessageFrom, spliter[0]] : [spliter[0]],
          statusNotification: statusNotification
        }
      });
    });
     return () => {
      if (socket.readyState === 1) {
        socket.disconnect();
        socket.destroy();
        socket.close();
      }
    }
  }, []);

  let navigator = useNavigate();

  function fetchMessageAndStore() {
    axios({
      method: "GET",
      url: baseURL + "/seller/rooms",
      headers: {
        'Authorization': userData.token
      }
    }).then((response) => {
      let data = response?.data;

      if (Array.isArray(data)) {
        let reducer = data.reduce((acc, curr) => {
          return isNaN(acc + curr?.message_count) ? 0 : acc + curr.message_count;
        }, 0);
        setTotalMessages(reducer);
      }

    });
  }

  function toggleSideBar() {
    setSideBarVisibility((pState) => {
      return { ...pState, show: !pState.show }
    });
  }
  function closeNavBarOnSide() {
    if (refCloseButton.current) {
      if (refCloseButton.current.querySelector('.btn-close')) {
        refCloseButton.current.querySelector('.btn-close').click();
      }
    }
  }


  let newNotifi = newNotification > 0 ? { ['data-count-total']: newNotification } : {};


  userData['newMessageFrom'] ??= [];
  let newMessageFrom = [...new Set(userData.newMessageFrom)];
  let newMessageFromLength = newMessageFrom.length;
  let newTotalMessages = newMessageFromLength > 0 ? { ['data-count']: newMessageFromLength } : {};

  return (
    <>
      <Navbar className="seller-theme-back" expand="md"  >
        <Container fluid>
          {sideBarVisibility.isMobile && <>
            {!sideBarVisibility.show ? <>
              <span style={{ border: '1px solid #ede9ea' }} onClick={toggleSideBar} className=" circle-navbar-icon border-1 d-md-none d-block px-3">
                <FontAwesomeIcon icon={faBarsStaggered} />
              </span>
            </> : <div onClick={toggleSideBar} style={{ zIndex: "11" }} className='mobile-menu-collapse-speed'>
              <HiOutlineX size={25} />
            </div>}
          </>}

          <span className='c-pointer ps-xl-5' onClick={() => { navigator("/") }} >
            <img className="logo-speed-01" src='/images/speed/autos-today-logo.png' alt='logo' style={{ maxWidth: '100%', height: "40px" }} />
            <img src="/images/speed/only-logo.png" alt='logo' style={{ maxWidth: '100%', height: "40px" }} />
          </span>

          <Navbar.Toggle className="circle-navbar-icon border-1"><BsGridFill color="#3D5278" /></Navbar.Toggle>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-md`}
            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
            placement="end">
            <Offcanvas.Header ref={refCloseButton} closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                Settings
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end d-flex align-items-center flex-grow-1 pe-3">
                <Nav.Item className="download-out-app">
                  <div className="label">Download our app:</div>
                  <a style={{color:"black"}} className="d-flex text-decoration-none" href="https://apps.apple.com/us/app/autos-today-sell-your-car/id1476273908" target="_blank">
                    <AppStoreIcon  size="30px" className="me-2 c-pointer"/>
                    <span className="d-block d-md-none" style={{lineHeight:1.8}}>AppStore</span>
                  </a>
                  <a style={{color:"black"}} className="d-flex text-decoration-none" href="https://play.google.com/store/apps/details?id=com.caripa.seller" target="_blank">
                    <PlayStoreIcon  size="30px" className="ms-2 c-pointer"/>
                    <span className="d-block d-md-none" style={{lineHeight:1.8}}>PlayStore</span>
                  </a>
                </Nav.Item>
                <Nav.Item>
                  <SearchInventory />
                </Nav.Item>

                <div className="d-flex ">

                  {newNotification > 0 ? <>
                    <span style={{ border: "1px solid rgb(230, 236, 244)", borderRadius: "50%", height: "40px", width: "40px", margin: "0px 20px" }} onClick={() => { navigator("/messages"); closeNavBarOnSide(); }} {...newNotifi} {...newTotalMessages} className='notification-icon-dw-container'>
                      <img src="./images/speed/bell.svg" />
                    </span>
                  </> : <>
                    <span className="mx-3 inactive-bell-notification" style={{ border: "1px solid rgb(230, 236, 244)", borderRadius: "50%", padding: "7px", cursor: "pointer" }}>
                      <img src="./images/speed/bell.svg" />
                    </span>
                  </>}

                  <span onClick={() => navigator("/profile")} className='circle-navbar-icon c-pointer'>
                    <img src="/images/speed/setting.svg" />
                  </span>
                </div>

                <NavDropdown className='hide-mobile' align="end" title={<>
                  <span className='one-part-bid hide-mobile' style={{ marginLeft: "5px", background: "#b72949", color: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <BsFillPersonFill size="25" />
                  </span>
                </>}>
                  <NavDropdown.Item href="#" onClick={() => navigator("/change-password")}>
                    Change Password
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#" onClick={() => setShowLogoutConfirm(true)}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>




                <ul className='show-on-mobile dropdown-menu-mobile mt-4'>
                  <li onClick={() => {
                    navigator("/profile");

                    closeNavBarOnSide();
                  }}>Profile</li>
                  {userData.isProfileCompleted && <li onClick={() => {
                    navigator("/change-password");
                    closeNavBarOnSide();
                  }}>Change password</li>}
                  <li onClick={() => {
                    setShowLogoutConfirm(true);
                    closeNavBarOnSide();
                  }} >Logout</li>
                </ul>

              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <ToastContainer />
      {showLogoutConfirm && <LogoutConfirm setShowLogoutConfirm={setShowLogoutConfirm} showLogoutConfirm={showLogoutConfirm} />}
    </>
  )
}

export default NavBar