import axios from 'axios';
import { baseURL } from 'config';

var controllerSignal = null;
export function apiFetchTaxRateFromZipCode(zipCode,callback) {
    zipCode |= "0";
    if(controllerSignal) controllerSignal.abort();
    controllerSignal = new AbortController();
    axios({
        method: "get",
        url: baseURL + "/tax/" + zipCode,
        signal: controllerSignal.signal
    }).then(response=>{
        callback(null,response.data);
    }).catch(error=>{
        if(error.name === "CanceledError"){
            return;
        }
        callback(error,null);
    });
}