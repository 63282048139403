import axios from "axios";
import {baseURL} from "config";


export function updateOfferAPI(payload,token,callback) {
    if(typeof payload !== "object"){
        throw new Error("payload must be an object");
    }
    const params = new URLSearchParams();
    for(var [key,value] of Object.entries(payload)){
        params.append(key,value);
    }

    axios({
        method: "post",
        url: baseURL + "/seller/negotiation/send",
        data: params,
        headers: {
            "Authorization": token,
            "Content-Type": "application/x-www-form-urlencoded"
        }
    }).then((response)=>{
        callback(null,response.data);
    }).catch((error)=>{
        callback(error,null);
    });
}


