import ButtonBoder from 'component/componenetInput/ButtonBoder'
import { Col } from 'react-bootstrap';
import { useEffect } from 'react';
import {HiLocationMarker} from 'react-icons/hi'
const AutoCheckInfoStar = ({ autocheck,refModal }) => {


    // red - DC3546
    // green - 22B859

    function openVinModal(){
        if(refModal?.current?.showVinModal){
            refModal.current.showVinModal()
        }else{
            console.log("DEV LOG: missing parent with showVinModal imperialref method");
        }
    }
    return (
        <div className="mt-5">
            <div className='d-flex flex-wrap justify-content-between align-items-center'>
                <div className="d-flex col-12 col-md-6 align-items-center"> 
                    <HiLocationMarker className="me-2" size={35} color="#b62949" />
                    <span className="ms-2" style={{color:"#90A3BF"}}>{autocheck?.location ?? "N/A"}</span>
                </div>

                <div className="d-flex col-12 col-md-6 justify-content-end">
                    <div  style={{color:"#90A3BF",fontWeight:"600"}}>
                        <img className="me-1" src="/images/icons/speed01-icons/clipboard.svg"/>{autocheck?.mileage?.toLocaleString() || "N/A"}
                    </div>
                </div>
            </div>

            <hr style={{borderColor:"#e3e3e3"}} />

            <div style={{ alignItems: "center", marginBottom: "30px", marginTop: "10px", display: "flex",flexWrap:"wrap" }}>
                <Col style={{ fontWeight: "bold", fontSize: "20px"}}>
                    <img src="/images/logo-autocheck.png" alt="" />
                </Col>
                {autocheck?.autocheck_owners ? <>
                <Col className="ps-3 d-flex fs-4">
                    <div className='stat-header-main fw-semibold pe-2'>Owners:</div>
                    <div className='stat-header-content'>{autocheck?.autocheck_owners ?? "N/A"}</div>
                </Col>
                <Col className="d-flex fs-4">
                    <div className='stat-header-main fw-semibold pe-2'>Accidents:</div>
                    {/* <div className='stat-header-content' style={{color:"#22B859"}}>{autocheck?.autocheck_accidents ?? "N/A"}</div> */}
                    {autocheck?.autocheck_accidents > 0 && <div className='stat-header-content' style={{ color: "#DC3546" }}>Issues</div>}
                    {autocheck?.autocheck_accidents == 0 && <div className='stat-header-content' style={{ color: "#22B859" }}>No Issues</div>}
                    {(typeof autocheck?.autocheck_accidents === 'undefined' || autocheck?.autocheck_accidents == null) && <div className='stat-header-content'>N/A</div>}
                </Col>
                <Col className="d-flex fs-4">
                    <div className='stat-header-main fw-semibold pe-2'>Tittle:</div>
                    {autocheck?.autocheck_assured === "Y" && <div className='stat-header-content' style={{ color: "#22B859" }}>No Issues</div>}
                    {autocheck?.autocheck_assured === "N" && <div className='stat-header-content' style={{ color: "#DC3546" }}>Issues</div>}
                    {(typeof autocheck?.autocheck_assured === 'undefined' || autocheck?.autocheck_assured == null) && <div className='stat-header-content'>N/A</div>}
                </Col>
                </> : <>
                    <Col xs="4">
                        <div>Get Free Report</div>
                        <ButtonBoder onClick={openVinModal} label="Add VIN Number" />
                    </Col>
                    <Col xs="4">
                    </Col>
                </>}
            </div>
        </div>
    )
}

export default AutoCheckInfoStar

/*
// get me random vin number
// vin: 1G1ZB5ST1GF123456
// JTKDE167660106709

Autocheck Data
{
    "vin": "1G6KS52Y4SU816128",
    "title": "2021 Acura ILX 4dr Sedan (2.4L 4cyl 8AM)",
    "mileage": 60060,
    "condition_id": 2,
    "notes": null,
    "vehicle_type": null,
    "engine": null,
    "msrp": null,
    "zip_code": "60060",
    "start_date": "2022-11-01T10:39:55.000Z",
    "end_date": "2022-11-04T15:39:55.000Z",
    "location": "Mundelein, IL",
    "status_id": 1,
    "created_date": "2022-11-01T10:39:55.000Z",
    "cover_photo": "cb7f2bd6-faa5-4681-85ae-c5ba6a3c3380.jpg",
    "condition_name": "Good",
    "autocheck_score": 30,
    "autocheck_owners": 2,
    "autocheck_accidents": 1,
    "autocheck_assured": "Y",
    "autocheck_bbp": 1
}
*/