import axios from 'axios';
import { baseURL } from 'config';

export function apiFetchCarDetails(carId,callback,triesLeft = 3) {
    axios({
        method: 'get',
        url: `${baseURL}/seller/inventory/${carId}`,
    }).then((response) => {
        callback(null, response.data);
    }
    ).catch((error) => {
        if (triesLeft > 0) {
            apiFetchCarDetails(carId,callback,triesLeft - 1);
            return;
        }
        callback(error, null);
    });
}
    