// @ts-nocheck
import { UserDataContext } from 'App';
import axios from 'axios';
import Loading from 'component/Loading';
import { baseURL } from '../../../config';
import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import ModalAproveBid from 'component/messages/messageFunctions/ModalAproveBid';
import ModalCounterBid from 'component/messages/messageFunctions/ModalCounterBid';
import { toast } from 'react-toastify';


const HighestOffers = ({ splice, getProgress, fetchDetails }) => {
    const [userData, setUserData] = useContext(UserDataContext);
    const [isLoading, setIsLoading] = useState(false);
    const [bidsSpliced, setBidsSpliced] = useState([]);
    const [isBidClose, setIsBidClose] = useState(false);

    const navigate = useNavigate();

    const { ModalAprovejsx, handleShowConfirmModal } = ModalAproveBid();
    const { ModalCounterjsx, showCounterModal } = ModalCounterBid();

    useEffect(() => {
        fetchOffers();
    }, [userData.newBid]);



    function fetchOffers() {
        axios({
            method: "GET",
            url: baseURL + "/seller/bids",
            headers: {
                'Authorization': userData.token
            }
        }).then((response) => {
            let arrayBids = response.data;

            // find if winner= 1 in arrayBids if it is true then set isBidClose to true
            let isBidClose = arrayBids.findIndex((bid) => {
                return bid.winner === 1;
            }
            );
            if (isBidClose !== -1) {
                setIsBidClose(true);
            }


            // get only the last 4 bids (the highest) for each dealer_name
            let bidsSpliced = arrayBids.reduce((acc, curr) => {
                let dealer_name = curr.dealer_name;
                let index = acc.findIndex((obj) => obj.dealer_name === dealer_name);
                if (index === -1) {
                    acc.push({
                        dealer_name: dealer_name,
                        bids: [curr]
                    });
                } else {
                    acc[index].bids.push(curr);
                }
                return acc;
            }, []);

            // sort the bids by highest price
            bidsSpliced.forEach((dealer) => {
                dealer.bids.sort((a, b) => {
                    return b.price - a.price;
                }
                );
            }
            );
            // get only one bid from 4 bids (the highest) for each dealer_name
            bidsSpliced.forEach((dealer) => {
                dealer.bids = dealer.bids.slice(0, 1);
            });

            // arrayBids.sort((a, b) => b.bid_amount - a.bid_amount);
            // arrayBids.splice(splice);
            // //console.log("arrayBids:",arrayBids);
            setBidsSpliced(bidsSpliced);

        }).catch((error) => {

        }).finally(() => {
            setIsLoading(false);
        });
    }
    function toChatMessage(itemBid) {
        navigate("/messages", { state: itemBid });
    }

    if (isLoading) return <Loading>Getting Offers</Loading>

    return (
        <>
            <div>
                <h4 className="car-header-text ms-3">Offers</h4>
                <Row>
                    {bidsSpliced.length === 0 && <div className='no-offers-text'>No Offers for now</div>}
                    {bidsSpliced.map((item, index) => {
                        let hightlight = index === 0 ? {
                            boxShadow: "0px 0px 3px 0px rgb(155 196 165 / 56%)"
                        } : {};
                        let boldStyle = index === 0 ? { fontWeight: "bold" } : {};
                        let textOffer = index === 0 ? "Highest offer" : "Current offer";
                        userData['newMessageFrom'] ??= [];
                        hightlight = userData.newMessageFrom.includes(item.dealer_name) ? {
                            boxShadow: " rgb(255 0 0) 0px 0px 3px 0px"
                        } : {};

                        // count userData.newMessageFrom for each dealer_name
                        let count = userData.newMessageFrom.reduce((acc, curr) => {
                            if (curr === item.dealer_name) {
                                acc++;
                            }
                            if (acc > 9 || isNaN(acc)) {
                                return "9+";
                            }
                            return acc;
                        }, 0);


                        return <Col key={index} xxl="3">
                               <ModalAprovejsx bid={item.bids[0]} callback={() => {
                                        window.location.reload();
                                    }} />
                            <div style={hightlight} onClick={() => { toChatMessage(item) }} className='seller-highest-offer-container'>
                                <div className="seller-highest-offer">
                                    <div style={{ width: "70%" }}>
                                        <div className='user-bid-info-con'>
                                            <div className='circle-name-offers'>{item.dealer_name.charAt(0).toUpperCase()}</div>
                                            <div>
                                                <div style={boldStyle} className='user-bid-name'>{item.dealer_name}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ textAlign: "right", width: "30%" }}>
                                        <div>
                                            {userData.newMessageFrom.includes(item.dealer_name) ? <>
                                                <span className='notification-to-message-red' data-count={count}> <img src="/images/icons/message-red.svg" /></span>
                                            </> : <>
                                                <span><img src="/images/icons/message-green.svg" /></span>
                                            </>}

                                        </div>
                                        <div className='highest-offer-text'>
                                            {textOffer}
                                        </div>
                                        <div className='seller-offer-price'>${parseInt(item.bids[0].bid_amount).toLocaleString()}</div>
                                    </div>

                                </div>
                                {!isBidClose && <>
                                    <div>
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                            handleShowConfirmModal(item.bids[0]);
                                        }} className='accept-longest'>Accept</button>
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                            showCounterModal(item.bids[0]);
                                        }} className='counter-longest'>Counter</button>
                                        
                                    </div>
                                 
                                    </>
                                }
                            </div>
                        </Col>
                    })}


                </Row>
            </div>
          
            <ModalCounterjsx callback={(bid, valueamount) => {
                toast(`Counter offer sended to ${bid.dealer_name}`, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }} />
        </>
    )
}

export default HighestOffers