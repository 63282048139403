import { Col } from 'react-bootstrap';
import { BsBell } from 'react-icons/bs';
import { TiArrowSortedDown } from '@react-icons/all-files/ti/TiArrowSortedDown';
import { SocketDataContext, UserDataContext } from 'App';
import { useContext, useEffect, useState } from 'react';
import { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
/*
statusNotification
{type: 'message', name: 'Erman', message: '213123', time: '11:55 AM', dateTime: '4/19/2023, 11:55:42 AM'}
*/

function notificationReducer(state, action) {
    switch (action.type) {
        case 'notification':
            let list = action.payload;
            let pointer = {};
            if (list.length > 0) {
                pointer = {
                    ...list[list.length - 1],
                    index: list.length - 1
                };
            }

            return { ...state, list: list, pointer: pointer, loading: false };

        case 'next':
            let nextPointer = {};
            if (state.list.length > 0) {
                let nextIndex = (state.pointer.index + 1) % state.list.length;
                nextPointer = {
                    ...state.list[nextIndex],
                    index: nextIndex
                };
            }
            return { ...state, pointer: nextPointer };
        case 'prev':
            let prevPointer = {};
            if (state.list.length > 0) {
                let prevIndex = (state.pointer.index - 1) < 0 ? state.list.length - 1 : (state.pointer.index - 1);
                prevPointer = {
                    ...state.list[prevIndex],
                    index: prevIndex
                };
            }
            return { ...state, pointer: prevPointer };
        default:
            return state;
    }

}

const NotificationBar = () => {
    const [userData, setUserData] = useContext(UserDataContext);
    const { socketData, setSocketData } = useContext(SocketDataContext);
    const [notification, dispatchNotification] = useReducer(notificationReducer, { loading: true, list: [], pointer: {} });
    const navigate = useNavigate();
    useEffect(() => {
        dispatchNotification({
            type: "notification",
            payload: userData?.statusNotification || []
        });
    }, [userData]);

    useEffect(() => {
        let socket = socketData?.socket;
        if(!socket) return;

        socket.on("new-bid",(msg)=>{
            let spliter = msg.split("|"); // # id|bid|name
            setUserData((p) => {
              let statusNotification = p?.statusNotification ?? [];
              if (spliter.length > 2) {
                statusNotification.push({
                    type: "bid", name: spliter[2], value: spliter[1],
                    time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                    dateTime: new Date().toLocaleString()
                });
              }
      
              return {
                ...p,
                newBid: p.newBid ? p.newBid + 1 : 1,
                statusNotification: statusNotification
              }
            });
        });

    },[socketData?.socket]);

    if(notification.list.length === 0){
        return (<></>)
    }

    return (
        <Col md="12" className="d-flex hide-mobile">
            <div className="row-notification-speed">
                <div className="text-bar">
                    <div className="bell"> <BsBell /></div>
                    <div className='text'>
                        {notification.loading ? 'Loading...' : <>
                            {notification.list.length > 0 ? <>
                                <div className="c-pointer" onClick={() => navigate("/messages", {
                                    state: {
                                        showTarget: notification.pointer.name,
                                        ...notification.pointer
                                    }
                                })}>
                                    {notification.pointer.type === "message" && <>
                                        New Message from <b>{notification.pointer.name}</b>
                                    </>}
                                    {notification.pointer.type === "bid" && <><b>{notification.pointer.name}</b> bid on your vehicle</>}
                                </div>
                                <div>
                                    <small style={{ fontSize: "12px" }}>{notification.pointer.dateTime}</small>
                                </div>
                            </> : <span>No Notification</span>}
                        </>}
                    </div>
                    <div className='arrows-container'>
                        <div onClick={() => dispatchNotification({ type: "prev" })} className="me-1 arrow">
                            <TiArrowSortedDown style={{ transform: 'rotate(90deg)' }} />
                        </div>
                        <div onClick={() => dispatchNotification({ type: "next" })} className="ms-1 arrow">
                            <TiArrowSortedDown style={{ transform: 'rotate(-90deg)' }} />
                        </div>
                    </div>
                </div>
            </div>
            <div>
            </div>
        </Col>
    )
}

export default NotificationBar