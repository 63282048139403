class anyImageModal {
    selectorArray = [];
    targetSelected = null;
    modalElement = null;
    imageModal = {};
    mainModal = {};
    preventDefault = null;
    typeModal = "";
    isOpenModal = false;
    constructor(selector, options) {
      this.imageModal["style"] = options?.image?.style
        ? options?.image?.style
        : {};
      this.typeModal = ["image","canvas"].includes(options?.type) ? options?.type : "image";
      this.preventDefault = options?.preventDefault ?? false;
      this.mainModal["style"] = options?.modal?.style
        ? options?.modal?.style
        : {};
      this.selectorArray = Array.isArray(selector) ? selector : [selector];
      this._init();
    }
    _init() {
      this._createModalTemplate();
      this._initEvent();
    }
    _createModalTemplate() {
      let modalIdGen =
        "anyIMG_" + Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
      document.body.insertAdjacentHTML(
        "beforeend",
        `<div id="${modalIdGen}" style="user-select:none;position:fixed;top:0;left:0;right:0;bottom:0px;background-color:rgb(0 0 0 / 80%);opacity:0;pointer-events:none;display:inline-flex;z-index:999;">[!Nothing Selected!]</div>`
      );
      this.modalElement = document.getElementById(modalIdGen);
      for (const [key, value] of Object.entries(this.mainModal.style)) {
        this.modalElement.style[key] = value;
      }
    }
    _initEvent() {
      // on click target
      document.addEventListener("click", this._onClickTarget.bind(this));
      // on click modal
      this.modalElement.addEventListener("click", () => {
        this._hideModal();
      });
    }
    _onClickTarget(event) {
      const target = event.target;
      //console.log("clicked on document!!", target.tagName);
      for (var i = 0; i < this.selectorArray.length; i++) {
        if (
          //[...document.querySelectorAll(this.selectorArray[i])].includes(target)
          target.closest(this.selectorArray[i])
        ) {
          if (this.preventDefault) event.preventDefault();
          this.targetSelected = target;
  
          if(this.typeModal != "image")
            this._addCanvasToModal();
          else
            this._addImageToModal();
          
          return;
        }
      }
    }
    focusTarget(target) {
      this.targetSelected = target;
      this._addImageToModal();
    }
    _addImageToModal() {
      let imagIdTarget =
        "img_any_" + Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
      this.modalElement.innerHTML = `<img id="${imagIdTarget}" style="max-height:100%;max-width:100%;margin:auto;pointer-events:none;object-fit: contain;" src="${this.targetSelected.src}"/>`;
      for (const [key, value] of Object.entries(this.imageModal.style)) {
        document.getElementById(imagIdTarget).style[key] = value;
      }
      this._showModal();
    }
    
    _addCanvasToModal(){
      let canvasIdTarget = "canvas_any_"+Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
      this.modalElement.innerHTML = '';
      this.canvasPV = document.createElement("canvas"); // create canvas preview
      this.canvasPV.width = this.targetSelected?.naturalWidth ?? this.targetSelected.videoWidth ?? this.targetSelected.width;
      this.canvasPV.height = this.targetSelected?.naturalHeight ?? this.targetSelected.videoHeight ?? this.targetSelected.height;
      this.canvasPV.id = canvasIdTarget;
      this.canvasPV.style = `max-height:100%;max-width:100%;margin:auto;pointer-events:none;object-fit: contain;`;
      this.ctxPV = this.canvasPV.getContext("2d");
      //imgo.crossOrigin = "anonymous";
      this.ctxPV.drawImage(this.targetSelected, 0, 0);
      this.modalElement.appendChild(this.canvasPV);
      for (const [key, value] of Object.entries(this.imageModal.style)) {
        document.getElementById(canvasIdTarget).style[key] = value;
      }
      this._showModal();
      if(this.targetSelected.tagName === "VIDEO"){
        this._loopDraw();
      }
    }
    _loopDraw(){
      if(this.modalElement.style.opacity == 0) return
      if(this?.targetSelected?.paused === true){
        this.targetSelected.play();
      }
      this.ctxPV.drawImage(this.targetSelected, 0, 0);
      window.requestAnimationFrame(this._loopDraw.bind(this));
    }
    
    
    _showModal() {
      this.modalElement.style.opacity = 1;
      this.modalElement.style.pointerEvents = "all";
      this.isOpenModal = true;
    }
    _hideModal() {
      this.modalElement.style.opacity = 0;
      this.modalElement.style.pointerEvents = "none";
      this.isOpenModal = false;
    }
  }

  export default anyImageModal;