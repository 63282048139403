import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useRef } from 'react';
import { isInViewportVertical } from 'utils/generalUtil';
import CardCar from './compontentSearch/CardCar';
import { FilterMainContext } from './Context/FilterContext';
import { apiFetchCarsInventory } from './request/apiFetchCarsInventory';
import SideFilter from './section/SideFilter';
import Loading from 'component/Loading';
import { useLocation, useSearchParams } from 'react-router-dom';
import ModalCar from './ModalSpeed/ModalCar';
import ModalCarContextProvider from './Context/ModalCarContext';
import { FaFilter } from 'react-icons/fa';
import InputBotBoder from 'component/componenetInput/InputBotBoder';
import SlimSelectAdapter from 'component/componenetInput/SlimSelect/SlimSelectAdapter';
// import { SlimSelect } from 'component/componenetInput/SlimSelect/slim-raw';
// import { SlimSelectAdpater } from 'component/componenetInput/SlimSelect/SlimSelectAdpater';


export default function MainCarSearchContainer() {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams(location.search);
    const { filterReducer, setFilterReducer, getChangedKeysFromDefault } = useContext(FilterMainContext);
    const loadButtonRef = useRef(null);
    const [listCars, setListCars] = useState([]);
    const [limit, setLimit] = useState(20);
    const filterRef = useRef(null);
    const [selectedCar, setSelectedCar] = useState(null);
    const [loading, setLoading] = useState(true);
    const [makeList, setMakeList] = useState([]);
    const [modelList, setModelList] = useState([{ placeholder: true, text: "Select Model" }]);


    useEffect(() => {
        apiFetchCarsInventory((error, data) => {
            if (error) throw error;
            setListCars(data);
            setLoading(false);
        });
    }, []);
    useEffect(() => {
        initEventScroll();
        return () => {
            removeEventScroll();
        }
    }, [loadButtonRef.current]);

    useEffect(() => {
        const codeParam = searchParams.get('code');
        if (!codeParam) return;
        const car = listCars.find(car => car.code === codeParam);
        if (car) {
            setSelectedCar(car);
        }
    }, [searchParams.get('code'), listCars]);


    function initEventScroll() {
        if (document && document.querySelector('.content-grid'))
            document.querySelector('.content-grid').addEventListener('scroll', onViewButtonHidden);
    }
    function removeEventScroll() {
        if (document && document.querySelector('.content-grid'))
            document.querySelector('.content-grid').removeEventListener('scroll', onViewButtonHidden);
    }
    function onViewButtonHidden() {
        if (loadButtonRef && isInViewportVertical(loadButtonRef.current)) {
            setLimit(p => p + 20)
        }
    }


    function toggleFilter() {
        filterRef.current.classList.toggle('show');
    }

    const filteredCars = listCars.filter((car) => {
        let isMatch = true;
        if (filterReducer.search_keywords) {
            isMatch = car.title.toLowerCase().includes(filterReducer.search_keywords.toLowerCase());
        }
        if (filterReducer.price_min || filterReducer.price_max) {
            isMatch = isMatch && car.price >= filterReducer.price_min && car.price <= filterReducer.price_max;
        }
        if (filterReducer.mileage_min || filterReducer.mileage_max) {
            isMatch = isMatch && car.mileage >= filterReducer.mileage_min && car.mileage <= filterReducer.mileage_max;
        }
        if (filterReducer.year_min && filterReducer.year_max) {
            isMatch = isMatch && car.year >= filterReducer.year_min && car.year <= filterReducer.year_max;
        }
        if (filterReducer.make && filterReducer.make.length > 0) {
            isMatch = isMatch && filterReducer.make.includes(car.make);
        }
        if (filterReducer.color && filterReducer.color.length > 0) {
            isMatch = isMatch && filterReducer.color.includes(car.exterior_color);
        }
        if (filterReducer.model && filterReducer.model.length > 0) {
            isMatch = isMatch && filterReducer.model.includes(car.model);
        }

        if (filterReducer.dealersNames && filterReducer.dealersNames.length > 0) {
            isMatch = isMatch && filterReducer.dealersNames.includes(car.dealer_name);
        }

        return isMatch;
    });


    var noResultHTML = "";
    if (filteredCars.length === 0) {
        const listChanges = getChangedKeysFromDefault();
        noResultHTML += "No cars found with ";
        for (var i = 0; i < listChanges.length; i++) {
            if (listChanges[i] === "search_keywords") {
                noResultHTML += `<b>${filterReducer.search_keywords}</b><br/>`;
            }
            if (listChanges[i] === "price_min" || listChanges[i] === "price_max") {
                noResultHTML += ` Price <b>$${filterReducer.price_min.toLocaleString()} - $${filterReducer.price_max.toLocaleString()}</b><br/>`
            }
            if (listChanges[i] === "mileage_min" || listChanges[i] === "mileage_max") {
                noResultHTML += ` Mileage <b>${filterReducer.mileage_min.toLocaleString()} - ${filterReducer.mileage_max.toLocaleString()}</b><br/>`
            }
            if (listChanges[i] === "year_min" || listChanges[i] === "year_max") {
                noResultHTML += ` Year <b>${filterReducer.year_min} - ${filterReducer.year_max}</b><br/>`
            }
            if (listChanges[i] === "make") {
                noResultHTML += ` Make <b>${filterReducer.make.join(', ')}</b><br/>`
            }
            if (listChanges[i] === "color") {
                noResultHTML += ` Color <b>${filterReducer.color.join(', ')}</b><br/>`
            }
            if (listChanges[i] === "model") {
                noResultHTML += ` Model <b>${filterReducer.model.join(', ')}</b><br/>`;
            }
            if (listChanges[i] === "dealersNames") {
                noResultHTML += ` Dealer <b>${filterReducer.dealersNames.join(', ')}</b><br/>`;
            }
        }

    }




    function showFilter() {
        let container = document.querySelector('.filter-left-side-car-search');
        container.classList.toggle('show');
    }

    function handleChangeModel(info) {
        const valuesArray = info.map(i => i.value);
        setFilterReducer({
            model: valuesArray
        });
        setModelList((pState) => {
            return [...pState.map((i) => {
                if (i.options) {
                    i.options = i.options.map((j) => {
                        if (info.map(i => i.value).includes(j.value)) {
                            j.selected = true;
                        } else {
                            j.selected = false;
                        }
                        return j;
                    });
                }
                return i;
            })]
        });
    }
    function handleChangeMake(info) {
        const listMakes = [...info.map(i => i.value)];
        setFilterReducer({
            make: listMakes,
        });

        setModelList((pState) => {
            var result = [{ placeholder: true, text: "Select Models" }];
            if (listMakes.length > 0) {

                for (var i = 0; listMakes.length > i; i++) {
                    const oneMake = listMakes[i];
                    var modelOfMakes = listCars.filter(i => i.make === oneMake).map(i => i.model);
                    // modelOfMakes.sort((a,b)=>a-b);
                    modelOfMakes.sort();

                    var modelOfMakesUnique = [...new Set(modelOfMakes)];
                    // modelOfMakesUnique.sort((a,b)=>b.localeCompare(a));

                    const objectMake = pState.find(i => i.label === oneMake);
                    var makeListOptions = [];
                    if (objectMake) {
                        makeListOptions = objectMake.options.filter(i => i.selected === true).map(i => i.value);
                    }

                    result.push({ label: oneMake, options: modelOfMakesUnique.map(i => ({ text: i, value: i, selected: makeListOptions.includes(i) })) });
                }

            }

            return result;
        });

    }

    if (loading) {
        return <Loading />
    }
    return <>
        <div>

            <div className='header-filter-speed-container-mobile'>

                <div className='w-100'>
                    <div  id="secondary-filters">
                        <InputBotBoder onChange={(e) => { setFilterReducer({ search_keywords: e.target.value }); }} value={filterReducer.search_keywords} className='search-icon-as-background' placeholder="Search keywords" />
                        <div>
                            <SlimSelectAdapter defaultValue={filterReducer.make} onSelectTarget={(info) => {
                                handleChangeMake(info);
                            }} placeholder="Select Make" data={makeList.map(i => ({ text: i, value: i }))} multiple={true} />
                            <div style={{ width: "10px" }}></div>
                            <SlimSelectAdapter disabled={filterReducer.make.length === 0} onSelectTarget={handleChangeModel} placeholder="Filter Model" data={modelList} multiple={true} />
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between w-100">
                    <div className='d-flex'>
                        <div className="text-muted">
                            Found: 
                            <b style={{ marginLeft:"0.2rem",marginRight:"0.2rem", color: "#0176ac" }}>{filteredCars.length}</b>
                             {filteredCars.length > 1 ? "Cars" : "Car"}
                        </div>
                        {getChangedKeysFromDefault().length > 0 &&
                            <div onClick={() => {
                                document.getElementById('clear-filers').click();
                            }} style={{ color: "#b62949" }} className='ms-2'>Clear Filter</div>
                        }
                    </div>
                    <div style={{ color: "#0176AC" }}>
                        <div onClick={showFilter} ><FaFilter />
                            <span id="more-filers-button"> More Filers</span>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row justify-content-center position-relative">
                <div className="filter-left-side-car-search">
                    <div ref={filterRef} className="side-filer-cars">
                        <SideFilter handleChangeMake={handleChangeMake} handleChangeModel={handleChangeModel} makeList={makeList} setMakeList={setMakeList} modelList={modelList} setModelList={setModelList} listCars={listCars} />
                    </div>
                </div>
                <div className='row car-list-right-side gx-xl-4 gx-0'>
                    {filteredCars.slice(0, limit).map((car, index) => {
                        return (<div key={car.code}
                            className="col-md-6 col-lg-6 col-xl-4 col-xxl-4 col-12 col-sm-6 d-flex justify-content-center align-items-center">
                            <CardCar onClick={() => setSelectedCar(car)} car={car} index={index} />
                        </div>);
                    })}
                    {noResultHTML !== "" ? <div className="col-12 text-center" dangerouslySetInnerHTML={{ __html: noResultHTML }}></div> : null}
                    <div ref={loadButtonRef} className="d-flex align-items-center justify-content-center position-relative mb-3">

                        <span style={{ color: "#90A3BF", position: "absolute", right: "0px", top: "50%", transform: "translateY(-50%)" }}>
                            {filteredCars.length > 1 ? `${filteredCars.length} Cars` : `${filteredCars.length} Car`}
                        </span>
                    </div>
                </div>

            </div>


            {selectedCar && <ModalCarContextProvider>
                <ModalCar setCarSelected={setSelectedCar} carSelected={selectedCar} />
            </ModalCarContextProvider>
            }
        </div>
    </>
}