import axios from 'axios';
import { baseURL } from 'config';

const apiUploadFileImage = ({ file, car_id, token, onProgress, onCompleted }) => {
    const fd = new FormData();
    fd.append('file', file);
    fd.append('car_id', car_id);
    axios({
        method: 'post',
        url: baseURL + '/seller/upload',
        data: fd,
        headers: {
            'Authorization': token
        },
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onProgress(percentCompleted);
        }
    }).then((response) => {
        onCompleted(null, response.data);
    }).catch((error) => {
        onCompleted(error, null);
    });
}

export default apiUploadFileImage