import { useState, useContext } from 'react'
import LongButtonModel from 'component/componenetInput/LongButtonModel';
import ModalAddCar from 'component/NoCar/parts/ModalAddCar';
import { ContextForm } from 'component/NoCar/contexts/FormContext';

const NoCarSection = () => {
    const { formRegister,editFormRegister } = useContext(ContextForm);
    const [show, setShow] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    function handlePlateSelected(){
        editFormRegister("stepType","plate");
        setShow(true);
    }

    function handleVinSelected(){
        editFormRegister("stepType","vin");
        setShow(true);
    }

    function handleManualSelected(){
        editFormRegister("stepType","manual");
        setShow(true);
    }

    return (
        <>
            <div className="d-flex flex-column justify-content-start">
                <h3 className='header-speed-negotiations'>List a Car</h3>
                <p className="text-center">The easiest way to list your car is with your license plate or a VIN number.</p>
                <div className="d-flex w-100">
                    <LongButtonModel onClick={handlePlateSelected} className="flex-grow-1 mx-2" colors={["#B62949", "#B6294926"]}>PLATE</LongButtonModel>
                    <LongButtonModel onClick={handleVinSelected} className="flex-grow-1 mx-2" colors={["#B62949", "#B6294926"]}>VIN</LongButtonModel>
                </div>
                <p className="text-center my-3">Don't have a license plate or a VIN?</p>
                <div className="d-flex justify-content-center align-items-center">
                    <LongButtonModel onClick={handleManualSelected} className="mx-2" style={{ width: "256px", transform: "translateY(50%)" }} colors={["#FFFFFF", "#B62949"]}>List car Manually</LongButtonModel>
                </div>
            </div>
            <ModalAddCar show={show} handleClose={handleClose} />
        </>
    )
}

export default NoCarSection