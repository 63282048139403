// @ts-nocheck
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserDataContext } from 'App';
import axios, { Axios } from 'axios';
import { baseURL } from 'config';
import { imageURL } from 'config';
import React from 'react'
import { useContext } from 'react';
// import { ProgressBar } from 'react-bootstrap';
import {FaTrash} from 'react-icons/fa';

const OneImageBoxRaw = (props) => {
    const [userData, setUserData] = useContext(UserDataContext);
    const { imagesList, setImagesList, item } = props;
   
    function deleteImage() {
        setImagesList([...imagesList.filter(x => x?.id !== item?.id)]);
        axios({
            method: "DELETE",
            url: baseURL + "/seller/car/photo/" + item.id,
            headers: {
                'Authorization': userData.token
            }
        }).then((response) => {
            
        }).catch((error) => {
            console.log(error);
        });
    }
    function setCoverImage(){
        let params = new URLSearchParams();
        params.append("car_id", userData.car.id);
        params.append("photo_id", item.id);
        setImagesList((plist)=>{
            let plistNew =  [...plist.map(i=> { return {...i, cover_photo: 0} })];
            plistNew[plist.findIndex(i=>i.id === item.id)].cover_photo = 1;
            return plistNew;
        });
        axios({
            method: "POST",
            url: baseURL + "/seller/car/cover-set",
            headers: {
                'Authorization': userData.token
            },
            data:  params
        }).then((response)=>{
            
        }).catch((error)=>{

        });
    }
    if(!item)
        return  <div className="image-uploaded-box"></div>


    return (
        <div className="image-uploaded-box">
            {(typeof item.name !== 'undefined' || typeof item.localURL !== 'undefined') && <>
            { ( !item.progress || item.progress === -1)  && <>
                <span onClick={deleteImage} className='remove-image-button'>
                    <FaTrash color="#E71D36" />
                </span>
                {item.cover_photo === 0 && <span onClick={setCoverImage} className='set-cover-button'>SET COVER</span>} </>}

                <img src={item.localURL ? item.localURL : (imageURL + item.name)} alt='' />
            </>}
            {item.cover_photo === 1 && <span className='cover-span'>Cover</span>}
            {/* <span className="progress-over-image" style={{['--width-progress']: "50%" }}>50%</span> */}
           {typeof item.progress !== 'undefined' && item.progress > -1 && <span className="progress-over-image" style={{['--width-progress']: (100-item.progress)+"%" }}>{item.progress}%</span>}
        </div>
    );


}

export const OneImageBox = React.memo(OneImageBoxRaw);