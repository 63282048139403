import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton'


function ModalImage(props) {
    const { setModalImageShow, imageLink, image, ...attrProps } = props;

    //
    return (
        <Modal
            {...attrProps}
            centered
            size="lg"
        >
            <div onClick={()=>{setModalImageShow((p) => { return { ...p, show: false } })}} className='hide-modal-fixed'>
                <CloseButton aria-label="Hide" />
            </div>
            <img style={{ maxWidth: "100%", maxHeight: "100%" }} src={image} alt="" />
        
        </Modal>
    );
}

export default ModalImage