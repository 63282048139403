import axios from 'axios';
import { baseURL } from 'config';


export function apiFetchCarsInventory(callback) {
    axios({
        method: 'get',
        url: baseURL + '/seller/inventory-search',
    }).then((response) => {
        callback(null, response.data);
    }).catch((error) => {
        callback(error,null);
    });
}