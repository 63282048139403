// @ts-nocheck
/* eslint-disable */
import React, { useContext, useState, useRef, useEffect } from 'react';

import { Row, Col, ButtonGroup } from 'react-bootstrap';
import axios from 'axios';
import InputBotBoder from '../../componenetInput/InputBotBoder';
import SelectBotBoder from '../../componenetInput/SelectBotBoder';
import ButtonBoder from '../../componenetInput/ButtonBoder';
import { NavLink, useNavigate } from 'react-router-dom';
import { baseURL, baseURLv2 } from '../../../config';
import Loading from '../../Loading';
import RadioBoder from '../../componenetInput/RadioBoder';
import { UserDataContext } from '../../../App';
import ImageUploader from 'component/configDetails/componentDetails/ImageUploader';
import { validateEmail, validateUSAPhoneNumber } from 'utils/validateUtil';
import { formatPhoneNumber, parseJSONWithDefault } from 'utils/generalUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import RecentlySold from 'component/homeStar/section/RecentSold';
import { FcInfo } from '@react-icons/all-files/fc/FcInfo';
import InputCalcSpeed from 'component/componenetInput/InputCalcSpeed';
import MaskedInput from 'react-text-mask';

export default function PlateRegister() {
    const setUserData = useContext(UserDataContext)[1];
    const [step, setStep] = useState(parseJSONWithDefault(window.localStorage['plate-step-v2'], { step: 0, isLoading: false }));
    //const phoneRef = useRef(null);
    let navigator = useNavigate();
    const [form, setForm] = useState({
        plate: window.localStorage.getItem('plate') || '',
        state: window.localStorage.getItem('state') || '',
        condition_id: window.localStorage.getItem('condition_id') || '',
        mileage: window.localStorage.getItem('mileage') || '',
        zip_code: window.localStorage.getItem('zip_code') || '',
        //phone: window.localStorage.getItem('phone') || '',
        email: window.localStorage.getItem('email') || '',
        password: window.localStorage.getItem('password') || '',
        asking_price: window.localStorage.getItem('asking_price') || '',
        phone: window.localStorage.getItem('phone') || '',
    }); // form data generated by user input
    //const [extraForm, setExtraForm] = useState(parseJSONWithDefault(window.localStorage['extraForm-plate'], {})); // form data generated from request
    const extraFormRef = useRef(parseJSONWithDefault(window.localStorage['extraForm-plate'], {}));
    const [hasError, setHasError] = useState("");
    const [formError,setFormError] = useState({});

    useEffect(() => {
        // if (phoneRef.current) {
        //     formatPhoneNumber(phoneRef.current);
        // }
        // save step into localStorage
        localStorage.setItem('plate-step-v2', JSON.stringify(step));
    }, [step.step]);

    // useEffect(() => {
    //     //save extraForm into localStorage json stringify
    //     window.localStorage.setItem('extraForm-plate', JSON.stringify(extraForm));
    // }, [extraForm]);

    useEffect(() => {
        window.localStorage.setItem('plate', form.plate);
        window.localStorage.setItem('state', form.state);
        window.localStorage.setItem('condition_id', form.condition_id);
        window.localStorage.setItem('mileage', form.mileage);
        window.localStorage.setItem('zip_code', form.zip_code);
        //window.localStorage.setItem('phone', form.phone);
        window.localStorage.setItem('email', form.email);
        //window.localStorage.setItem('password', form.password); 
        window.localStorage.setItem('asking_price', form.asking_price);
        window.localStorage.setItem('phone', form.phone);
    }, [form]);

    function resetFormError(){
        setFormError({});
    }
    function setError(key,value){
        setFormError((p)=>{
            return {...p,[key]:value}
        });
    }
    function hasFormError(){
        return Object.keys(formError).length>0;
    }

    async function checkZipCodeValid(zip_code) {
        // https://api.autostoday.com/v1/tax
        try{
            const response = await axios.get(`${baseURL}/tax/${zip_code}`);
            return !!response.data.success;
        }catch(error){
            return false;
        }
    }
    async function nextEventStep() {
        setHasError("");
        resetFormError();
        switch (step.step) {
            case 0:
                // plate
                let plate = form.plate;
                if (plate === "") {
                    //setHasError("Plate number is required to be filled in!");
                    setError("plate","Plate number is required to be filled in!");
                    return;
                }

                 //mileage
                 let mileage = parseInt(form.mileage);
                 if (isNaN(mileage)) {
                     //setHasError("Mileage is required to be number format!");
                     setError("mileage","Mileage is required to be number format!");
                     return
                 }

                 if(isNaN(parseInt(form.asking_price || 0))){
                    setError("asking_price","Asking Price is required to be number format!");
                    return;
                }
                let zipCode = form.zip_code;
                if (zipCode === "") {
                    //setHasError("Zip code is required to be filled in!");
                    setError("zip_code","Zip code is required to be filled in!");
                    return;
                }
                
                if(await checkZipCodeValid(zipCode) === false){
                    setError("zip_code", "Zip code is not valid!");
                    return;
                }


                //state
                let state = form.state;
                if (state === "") {
                    //setHasError("State is required to be selected!");
                    setError("state","State is required to be selected!");
                    return;
                } else {
                    plateStateSubmit(()=>{
                        finalSubmit();
                    });
                }
                break;
            case 1:
                setUserData((p) => {
                    window.localStorage.clear();
                    return { ...p, isOnline: true };
                });
                navigator("/");
                break;
            default:
                return "default"
        }
    }


    /*
        {
            "plate": "185167",
            "state": "IL",
            "condition_id": "2",
            "mileage": "123123",
            "zip_code": "60060",
            "email": "gfhfgh@asdasda.cc",
            "password": "123123123",
            "asking_price": "213123",
            "phone": "(123) 123-1231",
            "success": 1,
            "vin_id": 786,
            "vin": "SJAAC4ZV8JC018409",
            "year": 2018,
            "make": "Bentley",
            "model": "Bentayga",
            "trim": "Bentayga",
            "msrp": null,
            "type": "Multipurpose Passenger Vehicle (Mpv)",
            "engine": null
        }
        The payload will be trimmed to include only: vin_id, vin, plate, plate_state, zip_code, mileage, year, make, model, trim, and asking_price.
    */
    function finalSubmit() {
        setStep((state) => {
            return { ...state, isLoading: true }
        });
        let params = new URLSearchParams();
        let fusiedForm = { ...form, ...extraFormRef.current };
        let allowedKeys = ["plate", "state", "condition_id", "mileage", "zip_code", "asking_price","vin_id","vin","plate_state","year","make","model","trim"];
        console.log('fusiedForm', fusiedForm);
        for (var [key, value] of Object.entries(fusiedForm)) {
            if (allowedKeys.includes(key.toLowerCase())) {
                params.append(key, value);
            }               
        }
        axios({
            method: "POST",
            url: baseURLv2 + "/seller/register-plate",
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === 1) {
                setUserData(() => {
                    return {
                        ...response.data,
                        //isOnline: true
                    }
                });
                goNextStep();
                // navigator("/");
            } else {
                //setHasError("Invalid Zip code or is not supported in you country!");
                setHasError(response.data.error);
            }
        }).catch((error) => {
            setHasError("Something went wrong or check network connection!");
            console.log("response from above", error);
        }).finally(() => {
            setStep((state) => {
                return { ...state, isLoading: false }
            });
        });
    }

    function goNextStep() {
        setStep((state) => {
            return {
                ...state,
                step: state.step + 1
            }
        })
    }
    function plateStateSubmit(callbackSuccess) {
        setStep((state) => {
            return {
                ...state,
                isLoading: true
            }
        });
        let parmas = new URLSearchParams();
        parmas.append("plate", form.plate.trim());
        parmas.append("state", form.state.trim());
        axios({
            method: "POST",
            url: baseURL + "/seller/register/plate-to-vin",
            data: parmas,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            console.log("response from vin:", response.data);
            if (response.data.success === 1) {
                extraFormRef.current = { ...response.data };
                // setExtraForm((state) => {
                //     return {
                //         ...state,
                //         ...response.data
                //     }
                // });
                callbackSuccess?.();
            } else {
                setHasError(response.data.error);
                setStep((state) => {
                    return {
                        ...state,
                        step: 0,
                        isLoading: false
                    }
                })
            }
        }).catch((error) => {
            console.log("Raining Errors:", error);
            setHasError("Something went wrong!");

            setStep((state) => {
                return {
                    ...state,
                    step: 0,
                    isLoading: false
                }
            })
        });
    }
    function oneStepBack() {
        setStep((state) => {
            let result = state.step - 1;
            if (result < 0) {
                navigator("/register");
                return { ...state, step: 0 };
            }
            return { ...state, step: result }
        });
    }
    let countryCode = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY", "DC", "AS", "GU", "MP", "PR", "UM", "VI"]
    let countryNames = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming", "District of Columbia", "American Samoa", "Guam", "Northern Mariana Islands", "Puerto Rico", "United States Minor Outlying Islands", "U.S. Virgin Islands"];
    return (
        <div className='container container-d-flex'>
            <div className='d-flex justify-content-center'>
                <div className={step.step !== 1 ? 'col-12 col-md-4' : 'col-12 col-md-9 '}>
                    <div className='text-center box-register-logo' >
                        <img alt="autos today logo no transparent" className="my-2" src="/images/speed/autos-today-logo.png" />
                    </div>

                    <div className='box-register'>

                        {step.step < 1 &&
                            <>
                            <div className='navigator-container'>
                                <span onClick={oneStepBack} className='box-go-back'>
                                    <FontAwesomeIcon icon={faArrowLeftLong} />
                                </span>
                            </div>

                            <div className="textOrRegister small-text my-3">
                                Fill out this simple form and receive <b>offers in minutes</b>. We are <b>100% free</b> and anonymous platform and there is <b>no obligation</b> to sell your car.
                            </div>

                            <div className="text-center">

                                <ButtonGroup >
                                    <NavLink to="/register/plate" className={'button-choose-register btn btn-outline-danger' + (window.location.pathname === "/register/plate" ? " active" : "")}>PLATE</NavLink>
                                    <NavLink to="/register/vin" className={'button-choose-register btn btn-outline-danger' + (window.location.pathname === "/register/vin" ? " active" : "")}>VIN</NavLink>
                                    <NavLink to="/register/manual" className={'button-choose-register btn btn-outline-danger' + (window.location.pathname === "/register/manual" ? " active" : "")}>MANUAL</NavLink>
                                </ButtonGroup>
                            </div>
                            </>
                        }
                        {hasError !== "" && <div className="warning-error">{hasError}</div>}

                        {step.isLoading && <Loading />}

                        {/* Step 0 - Plate */}
                        {step.step === 0 && !step.isLoading &&
                            <>
                                <div className="my-3">
                                    {/* <InputBotBoder invalid={formError.plate} value={form.plate} onChange={(e) => { setForm(i => { return { ...i, plate: e.target.value } }) }} label="License plate" placeholder="Plate number" /> */}
                                    <InputCalcSpeed invalid={formError.plate} value={form.plate} onChange={(e) => { setForm(i => { return { ...i, plate: e.target.value } }) }} label="License Plate*" placeholder="Plate number" />
                                </div>
                                <div className="my-3">
                                    <SelectBotBoder  invalid={formError.state} defaultValue={form.state} onChange={(e) => { setForm(i => { return { ...i, state: e.target.value } }) }} label="Plate State*" placeholder="Select state" labelOptions={countryNames} valueOptions={countryCode} />
                                </div>


                                <div className="my-3">
                                    {/* <InputBotBoder  invalid={formError.mileage} type="number" value={form.mileage} onChange={(e) => { setForm(i => { return { ...i, mileage: e.target.value } }) }} placeholder="Current Mileage" label="Mileage*" /> */}
                                    <InputCalcSpeed invalid={formError.mileage} type="number" value={form.mileage} onChange={(e) => { setForm(i => { return { ...i, mileage: e.target.value } }) }} placeholder="Current mileage" label="Mileage*" />
                                </div>

                                <div className="my-3">
                                    <InputCalcSpeed  invalid={formError.zip_code} type="number" value={form.zip_code} onChange={(e) => { setForm(i => { return { ...i, zip_code: e.target.value } }) }} placeholder="Zip code" label="Zip Code*" />
                                </div>

                                <div className="my-3">
                                    <InputCalcSpeed invalid={formError.asking_price} value={form.asking_price} type="number" onChange={(e) => { setForm((i) => { return { ...i, asking_price: e.target.value } }) }} placeholder="What do you think your car is worth? (optional)" label="Asking Price" />
                                </div>
                            </>
                        }
                        
                        {step.step === 1 && !step.isLoading && <>
                            <div className="my-3">
                                <ImageUploader type="register" />
                            </div>
                        </>}

                        <Row className={step.step === 1 && 'fixed-position-bottom'}>
                            <Col>
                                {!step.isLoading && <ButtonBoder onClick={nextEventStep} label={step.step === 1 ? 'Finish' : 'GET MY OFFER'} />}
                            </Col>
                            {step.step === 1 && !step.isLoading && <Col>
                                <ButtonBoder onClick={nextEventStep} label="SKIP" />
                            </Col>}
                        </Row>
                        {step.step === 1 && <div className='accept-terms-text'>
                        By signing up with our platform you agree to our <a target="_blank" href='https://autostoday.com/terms-of-use/'>Terms of Use</a>
                    </div>}
                    </div>
                </div>

            </div>
            <div className='flex-grow-1'></div>
            <div>
                <RecentlySold />
            </div>

        </div>
    )
}
