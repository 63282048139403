import React from 'react'

const ProgressCircle = ({progressData,setModalVin,navigate,lookingCarRef,CircularProgressbar,buildStyles,LookingCar,getProgress}) => {
  return (
    <div className='progress-info-details'>
    {progressData && progressData.map((item, index) => {
        // vin photos profile looking
        // let clickNavigaotr = ['/car-details', '/car-details', '/profile',''];
        let runFunction = [()=>{
            setModalVin(true);// vin
        },()=>{
            navigate("/car-details"); // photos
        },()=>{
            navigate("/profile"); // profile
        },()=>{
            lookingCarRef.current.click(); // looking
        }];
        let zeroPrometer = ["ADD", "ADD","PROFILE","SETUP"];
        return <div key={index} className='progress-container-circulars'>
            <div onClick={() => { runFunction[index](); }} key={index} className='Circular-Progress'>
                {item.completed === 1 && <img className='hill-checked' src='./images/icons/check-hill.svg' alt='' />}
                <CircularProgressbar
                    styles={buildStyles({
                        pathColor: "#B72949",
                        textColor: "#B72949",
                    })}
                    value={(item.completed * 100)}
                    text={item.completed === 0 ? zeroPrometer[index] : `${Math.floor(item.completed * 100)}%`}
                />
            </div>
            <div style={{ cursor: "pointer",fontSize:"14px" }} className='fw-bold mt-2 mb-4'>
                {item.title} 
            </div>
            {progressData.length - 1 === index && <LookingCar ref={lookingCarRef} getProgress={getProgress}/>}
        </div>
    })}
</div>                       

  )
}

export default ProgressCircle