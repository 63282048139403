// @ts-nocheck
import { UserDataContext } from 'App';
import axios from 'axios';
import ButtonBoder from 'component/componenetInput/ButtonBoder';
import InputBotBoder from 'component/componenetInput/InputBotBoder';
import { ToastContainer, toast } from 'react-toastify';
import Loading from 'component/Loading';
import { baseURL } from 'config';
import React, { useState, useRef, useEffect, useContext } from 'react'
import { Modal, Col, Row } from 'react-bootstrap';
import { formatPhoneNumber } from 'utils/generalUtil';
import { baseURLv2 } from 'config';
import { validateEmail } from 'utils/validateUtil';




var shadowSetBid = null;
var tempbid = null;
var callbackOnRequestDone = null;


const ModalAproveBid = () => {
    const [userData, setUserData] = useContext(UserDataContext);
    const isProfileCompleteBaseEmail = !(userData.first_name === "" || userData.first_name === null || (typeof userData.first_name === 'undefined') || userData.first_name === "Anonymous")
    const [isProfileCompleted, setIsProfileCompleted] = useState(isProfileCompleteBaseEmail); // false show modal, true hide modal
    const [isLoading, setIsLoading] = useState(false);
    const [confirmModalShow, setConfirmModalShow] = useState({
        show: false,
        bid: {
            dealer_name: "",
            bid_amount: -1
        },
    });
    const handleCloseConfirmModal = () => setConfirmModalShow((b) => {
      
        return { ...b, show: false }
    });
    const handleShowConfirmModal = (bid) => setConfirmModalShow((b) => {
        return { bid: bid, show: true }
    });

    function acceptBid(bid) {
       
        let params = new URLSearchParams();
        params.append("bid_id", bid.id);
        axios({
            method: "POST",
            url: baseURL + "/seller/bid/accept",
            data: params,
            headers: {
                'Authorization': userData.token
            }
        }).then((response) => {
            if (shadowSetBid) {
                shadowSetBid(prevBid => {
                    // find bid in prevBid id from argument bid
                    let findIndex = prevBid.findIndex((prevBidItem) => {
                        return prevBidItem.id === bid.id;
                    });
                    if (findIndex !== -1) {
                        prevBid[findIndex].winner = 1;
                    }
                    return [...prevBid];
                });
            }
            if (callbackOnRequestDone) {
                callbackOnRequestDone();
            }
            handleCloseConfirmModal();
        }).catch((error) => {
            console.log("accept bid error:", error)
        });
    }

    function ModalProfileComplete({callback,bid}) {
        tempbid = bid;
        const [isError,setIsError] = useState("");
        const refFirst_name = useRef(null);
        const refLast_name = useRef(null);
        const refEmail = useRef(null);
        const refPhone = useRef(null);

     

        function submitComplete() {
            if(refFirst_name.current.value === ""){
                setIsError("First name is required");
                return;
            }
            if(refLast_name.current.value === ""){
                setIsError("Last name is required");
                return;
            }
            if(refEmail.current.value === ""){
                setIsError("Email is required");
                return;
            }
            if(refPhone.current.value === ""){
                setIsError("Phone is required");
                return;
            }
            if(validateEmail(refEmail.current.value) === false){
                setIsError("Email is invalid");
                return;
            }

            let params = new URLSearchParams();
            params.append("first_name", refFirst_name.current.value);
            params.append("last_name", refLast_name.current.value);
            params.append("email", refEmail.current.value);
            params.append("phone", refPhone.current.value);
            params.append("bid_id", tempbid.id);
            
            axios({
                method: "POST",
                url: baseURLv2 + "/seller/bid/accept",
                data: params,
                headers: {
                    'Authorization': userData.token,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === 0) {
                    toast.error(response.data.error, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setIsProfileCompleted(false);
                } else {
                    setIsProfileCompleted(true);
                    if(callback){
                        callback();
                    }
                    handleCloseConfirmModal();
                    setDataForUser({
                        first_name: refFirst_name.current.value,
                        last_name: refLast_name.current.value,
                        email: refEmail.current.value,
                        phone: refPhone.current.value,
                    });
                }
            }).catch((error) => {
                toast.error("Profile fail to update, check network!", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        }


        useEffect(() => {
            if (refPhone.current) {
                formatPhoneNumber(refPhone.current);
                fetchProfile();
            }
           
        }, []);
        
        function fetchProfile() {
            if (refFirst_name.current) {
                axios({
                    method: "GET",
                    url: baseURL + "/seller/profile",
                    headers: {
                        'Authorization': userData.token
                    }
                }).then((response) => {
                    refFirst_name.current.value = response.data.first_name ? response.data.first_name : "";
                    refLast_name.current.value = response.data.last_name ? response.data.last_name : "";
                    refEmail.current.value = response.data.email ? response.data.email : "";
                    refPhone.current.value = response.data.phone ? response.data.phone : "";
                });
            }
        }

         
            


        

        function setDataForUser(data) {
            setUserData((p)=>{
                return {...p, ...data}
            });
        }

        return <>
            <Modal show={confirmModalShow.show} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Accept Offer</Modal.Title>
                </Modal.Header>

                {tempbid ? <Modal.Body>
                    <div style={{fontSize:"13px"}}>
                        You are accepting an offer in the amount of <b>${(tempbid.bid_amount ? tempbid.bid_amount.toLocaleString() : -1)}</b> from <b>{tempbid.dealer_name}</b>
                        <br/>
                        <br/>
                        Please fill out your information below in order to receive personalized confirmation quote from the dealership delivered to your email. This will also release your contact information to this dealer
                    </div>
                    {isError !== "" && <div className='error-message'>
                            {isError}
                    </div> }

                    <InputBotBoder placeholder="First Name" label="First Name" ref={refFirst_name} />
                    <InputBotBoder placeholder="Last Name" label="Last Name" ref={refLast_name} />
                    <InputBotBoder placeholder='Email' label="Email" ref={refEmail} />
                    <InputBotBoder data-pattern="***-***-****" placeholder="Phone" label="Mobile Phone" ref={refPhone} />
                    <div className="accept-terms-text">
                    By accepting the offer , I agree that I
                        was accurate on the condition and mileage of my vehicle.
                        Failure to disclose any issues with your vehicle may result
                        in a deduction of the accepted bid price and/or no deal at
                        all.
                    </div>
                </Modal.Body> : 
                <Modal.Body> <Loading /></Modal.Body>
                }

                <Modal.Footer>
                    <ButtonBoder onClick={() => {
                        submitComplete();
                    }} label="Update & Accept" />
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </>;
    }

    function ModalAprovejsx({bid,setBids, callback }) {
        shadowSetBid = setBids;
        callbackOnRequestDone = callback;
        tempbid = bid;
        if (true) {
            return <ModalProfileComplete bid={bid} callback={callback} />
        }
        return (
            <>
                <Modal show={confirmModalShow.show} onHide={handleCloseConfirmModal} centered>
                    <Modal.Header closeButton>
                    </Modal.Header>
                        <>
                            <Modal.Body id="moBody1">Are you sure you wish to accept the bid of <b style={{ color: "rgb(97, 156, 111)" }}>${confirmModalShow.bid.bid_amount}</b> from  <b>{confirmModalShow.bid.dealer_name}</b>?<br />This will end this auction!</Modal.Body>
                            <Modal.Body id="moBody2" className="accept-terms-text">
                            By accepting the offer , I agree that I
                        was accurate on the condition and mileage of my vehicle.
                        Failure to disclose any issues with your vehicle may result
                        in a deduction of the accepted bid price and/or no deal at
                        all.
                            </Modal.Body>
                            <Modal.Footer>
                                <Row className='w-100'>
                                    <Col>
                                        <ButtonBoder onClick={handleCloseConfirmModal} style={{ background: "#6c757d" }} label="Cancel" />
                                    </Col>
                                    <Col>
                                        <ButtonBoder id="moAccept" onClick={() => {
                                            acceptBid(confirmModalShow.bid);
                                        }} label="Accept" /></Col>
                                </Row>
                            </Modal.Footer>
                        </>
                </Modal>
                <ToastContainer />
            </>
        )
    }
    return { ModalAprovejsx, handleShowConfirmModal, handleCloseConfirmModal, setConfirmModalShow }
}

export default ModalAproveBid